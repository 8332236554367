import React, { Component } from 'react'
import { Icon } from 'semantic-ui-react';

export default class InfoCard extends Component {
  render() {
    return (
        <div style={{
            width: 320,
            height: 90,
            border: '2px solid white',
            marginLeft: 15,
            marginRight: 10,
            padding: 10,
            borderRadius: 10,
            backgroundColor: this.props.color,
            display: 'flex',
            flexDirection: 'row'
        }} >
            <div style={{
                display: 'flex',
                flex: 1,
            }}>
            
                <Icon name={this.props.icon} inverted color="grey"  size={"huge"}/>

            </div>
            <div style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                width: 90,
            }}>
            <span style={{
                fontWeight: 'bold',
                color: 'white',
                fontSize: 15,
                marginBottom: 15
            }} >{this.props.label}</span>
            <span style={{
                fontSize: 45,
                color: 'white',
            }} >{this.props.value}</span>
            </div>
        </div>
    )
  }
}

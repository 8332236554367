import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import {
	Button,
	Header,
	Modal,
	Pagination,
	Segment,
	Grid,
	Divider,
	Table,
} from 'semantic-ui-react';
import moment from 'moment';
import { ROWS_PER_PAGE } from './CitiesData';
import feathersClient from '../../redux/FeathersRedux';

class CitiesContainer extends Component {
	state = {
		currentQuiz: null,
		isResultsModalOpened: false,
		isDetailsModalOpened: false,
		quizResults: null,
	};

	componentDidMount() {
		if (!this.props.cities.store) {
			this.getCities(1);
		}
	}

	getCities = page => {
		this.props.store({ page });

		setTimeout(() => {
			this.props.find({
				query: {
					$skip: (this.props.cities.store.page - 1) * ROWS_PER_PAGE,
					$limit: ROWS_PER_PAGE,
					$sort: {
						createdAt: -1,
					},
				},
			});
		}, 60);
	};

	renderTableBody = () => {
		if (this.props.cities.queryResult == null) return;

		return this.props.cities.queryResult.data.map(city => (
			<Table.Row key={city._id}>
				<Table.Cell>{city.name}</Table.Cell>
				<Table.Cell>{city.code}</Table.Cell>
				<Table.Cell>
					<Button
						color="black"
						size="mini"
						icon="pencil"
						content="Editar"
						onClick={() => this.props.push('/city/' + city._id)}
					/>
				</Table.Cell>
			</Table.Row>
		));
	};

	renderPagination = () => {
		if (this.props.cities.queryResult == null) return;

		if (
			!this.props.cities.store ||
			this.props.cities.queryResult.total === 0
		)
			return null;

		return (
			<Pagination
				activePage={this.props.cities.store.page}
				totalPages={Math.ceil(
					this.props.cities.queryResult.total / ROWS_PER_PAGE,
				)}
				onPageChange={(e, { activePage }) => this.getCities(activePage)}
			/>
		);
	};

	render() {
		return (
			<div>
				<Grid>
					<Grid.Row columns={2}>
						<Grid.Column>
							<Header as="h2" icon="building" content="Cities" />
						</Grid.Column>
						<Grid.Column>
							<Button
								color="red"
								icon="plus"
								floated="right"
								content="Nuevo"
								onClick={() => this.props.push('/city/create')}
							/>
							<Button
								icon="refresh"
								color="grey"
								floated="right"
								onClick={() =>
									this.getCities(this.props.cities.store.page)
								}
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid>

				<Divider />

				<Grid>
					<Grid.Row columns={1}>
						<Grid.Column>
							<Segment
								raised
								loading={this.props.cities.isLoading}
							>
								<Table striped>
									<Table.Header>
										<Table.Row>
											<Table.HeaderCell>
												Nombre
											</Table.HeaderCell>
											<Table.HeaderCell>
												Código
											</Table.HeaderCell>
											<Table.HeaderCell></Table.HeaderCell>
										</Table.Row>
									</Table.Header>
									<Table.Body>
										{this.renderTableBody()}
									</Table.Body>
								</Table>
							</Segment>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row columns={1}>
						<Grid.Column textAlign="center">
							{this.renderPagination()}
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	cities: state.services.cities,
	router: state.router,
});

const mapDispatchToProps = (dispatch, { services }) => ({
	find: (query = {}) => dispatch(services.cities.find(query)),
	patch: (id, data) => dispatch(services.cities.patch(id, data)),
	store: data => dispatch(services.cities.store(data)),
	push: location => dispatch(push(location)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CitiesContainer);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import {
	Button,
	Header,
	Modal,
	Pagination,
	Segment,
	Grid,
	Divider,
	Label,
	Table,
	List,
	Checkbox,
} from 'semantic-ui-react';
import moment from 'moment';
import { ROWS_PER_PAGE } from './EventData';

class EventContainer extends Component {
	state = {
		assistantsModal: false,
		currentEvent: {},
		showPast: false,
	};

	componentDidMount() {
		if (!this.props.events.store) {
			this.getEvents(1);
		}
	}

	getEvents = (page) => {
		this.props.store({ page });

		setTimeout(() => {
			let query = {
				$skip: (this.props.events.store.page - 1) * ROWS_PER_PAGE,
				$limit: ROWS_PER_PAGE,
				$sort: {
					date: -1,
				},
				active: true,
			};

			if (!this.state.showPast) {
				query.date = {
					$gte: moment().hour(0).minute(0).second(0).subtract(1, 'm').toDate()
				};
				query.$sort = {
					createdAt: -1,
				};
			}

			this.props.onFind({
				query,
			});
		}, 50);
	};

	showAssistants = (event) => {
		this.props
			.findEventUsers({
				query: {
					event: event._id,
					$populate: {
						path: 'user',
						select: ['name', 'lastName', 'email'],
					},
					$limit: -1,
				},
			})
			.then((res) => {
				this.setState({
					currentEvent: event,
					assistantsModal: true,
				});
			});
	};

	renderAssistants = () => {
		if (
			Array.isArray(this.props.eventUsers.queryResult) &&
			this.props.eventUsers.queryResult.length
		) {
			const users = this.props.eventUsers.queryResult.map((eu) => {
				return (
					<Table.Row key={eu._id}>
						<Table.Cell>{`${eu.user.name} ${eu.user.lastName}`}</Table.Cell>
						<Table.Cell>{eu.user.email}</Table.Cell>
					</Table.Row>
				);
			});

			return (
				<Table striped>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>Nombre</Table.HeaderCell>
							<Table.HeaderCell>Email</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>{users}</Table.Body>
				</Table>
			);
		} else {
			return <span>Todavia no hay asistentes</span>;
		}
	};

	renderTime = (event) => {
		if (event.isUTCTime) {
			const time = event.time.split(':');

			const newTime = moment
				.utc(
					moment()
						.hour(time[0])
						.minute(time[1])
						.second(0)
						.format('YYYY-MM-DD HH:mm:ss'),
				)
				.local()
				.format('HH:mm');

			return `${newTime} (hora local)`;
		}

		return event.time;
	};

	renderTableBody = () => {
		return this.props.events.queryResult.data.map((event) => (
			<Table.Row key={event._id}>
				<Table.Cell>
					{event.visibility === 'staff' ? 'Staff' : 'Público'}
				</Table.Cell>
				<Table.Cell>{event.location}</Table.Cell>
				<Table.Cell>{event.title}</Table.Cell>
				<Table.Cell>
					{moment(event.date).format('DD-MMMM-YYYY')}
				</Table.Cell>
				<Table.Cell>{this.renderTime(event)}</Table.Cell>
				<Table.Cell>{event.url || ''}</Table.Cell>
				<Table.Cell>
					<Label
						content={event.status}
						color={event.status === 'Abierto' ? 'green' : 'red'}
						icon={event.status === 'Abierto' ? 'unlock' : 'lock'}
					/>
				</Table.Cell>
				<Table.Cell>
					<Button
						size="mini"
						icon="users"
						content="Asistentes"
						onClick={() => this.showAssistants(event)}
					/>
				</Table.Cell>
				<Table.Cell>
					<Button
						color="black"
						size="mini"
						icon="pencil"
						content="Editar"
						onClick={() => this.props.push('/event/' + event._id)}
					/>
				</Table.Cell>
			</Table.Row>
		));
	};

	renderPagination = () => {
		if (
			!this.props.events.store ||
			this.props.events.queryResult.total === 0
		)
			return null;

		return (
			<Pagination
				activePage={this.props.events.store.page}
				totalPages={Math.ceil(
					this.props.events.queryResult.total / ROWS_PER_PAGE,
				)}
				onPageChange={(e, { activePage }) => this.getEvents(activePage)}
			/>
		);
	};

	render() {
		return (
			<div>
				<Grid>
					<Grid.Row columns={2}>
						<Grid.Column>
							<Header as="h2" icon="calendar" content="Eventos" />
						</Grid.Column>
						<Grid.Column>
							<Button
								color="red"
								icon="plus"
								floated="right"
								content="Nuevo"
								onClick={() => this.props.push('/event/create')}
							/>
							<Button
								icon="refresh"
								color="grey"
								floated="right"
								onClick={() =>
									this.getEvents(this.props.events.store.page)
								}
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<Divider />

				<Grid>
					<Grid.Row columns={1}>
						<Grid.Column>
							<Segment
								raised
								loading={this.props.events.isLoading}
							>
								<Checkbox
									label="Incluir eventos pasados"
									toggle
									onChange={() => {
										this.setState(
											{
												showPast: !this.state.showPast,
											},
											() => {
												this.getEvents(
													this.props.events.store
														.page,
												);
											},
										);
									}}
									checked={this.state.showPast}
								/>
								<Table striped>
									<Table.Header>
										<Table.Row>
											<Table.HeaderCell>
												Visibilidad
											</Table.HeaderCell>
											<Table.HeaderCell>
												Ubicación
											</Table.HeaderCell>
											<Table.HeaderCell>
												Título
											</Table.HeaderCell>
											<Table.HeaderCell>
												Fecha
											</Table.HeaderCell>
											<Table.HeaderCell>
												Hora
											</Table.HeaderCell>
											<Table.HeaderCell>
												Link
											</Table.HeaderCell>
											<Table.HeaderCell>
												Estatus
											</Table.HeaderCell>
											<Table.HeaderCell></Table.HeaderCell>
											<Table.HeaderCell></Table.HeaderCell>
										</Table.Row>
									</Table.Header>
									<Table.Body>
										{this.renderTableBody()}
									</Table.Body>
								</Table>
							</Segment>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row columns={1}>
						<Grid.Column textAlign="center">
							{this.renderPagination()}
						</Grid.Column>
					</Grid.Row>
				</Grid>

				<Modal
					size="tiny"
					closeIcon={true}
					open={this.state.assistantsModal}
					onClose={() => this.setState({ assistantsModal: false })}
				>
					<Modal.Header>
						Asistentes del evento{' '}
						{this.state.currentEvent
							? this.state.currentEvent.title
							: ''}
					</Modal.Header>
					<Modal.Content>{this.renderAssistants()}</Modal.Content>
				</Modal>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	events: state.services.events,
	router: state.router,
	eventUsers: state.services.eventusers,
});

const mapDispatchToProps = (dispatch, { services }) => ({
	onFind: (query = {}) => dispatch(services.events.find(query)),
	onPatch: (id, data) => dispatch(services.events.patch(id, data)),
	store: (data) => dispatch(services.events.store(data)),
	push: (location) => dispatch(push(location)),
	findEventUsers: (query = {}) => dispatch(services.eventusers.find(query)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventContainer);

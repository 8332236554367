import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Grid,
	Header,
	Divider,
	Button,
	Segment,
	Form,
	Icon,
	Label,
	Dropdown,
	// TextArea,
	// Image,
} from 'semantic-ui-react';
import feathersClient from '../../redux/FeathersRedux';
import { ROWS_PER_PAGE } from './CitiesData';

class CitiesEdit extends Component {
	state = {
		loading: false,
		currentEmail: '',
		city: {
			name: '',
			code: '',
            emails: [],
            state: null,
        },
        states: [],
	};

	componentDidMount() {
        this.fetchStates();
		this.fetchCity(this.props.match.params.id);
    }

    fetchStates = async () => {
		const states = await feathersClient.service('states').find({
            query: {
                $limit: '-1',
            },
        });
        if (states && states.length > 0) {
            this.setState({
                // eslint-disable-next-line react/no-unused-state
                states: states.map(g => ({
                    key: g._id,
                    value: g._id,
                    text: g.name,
                })),
            });
        }
	};

	fetchCity = async (_id) => {
		const city = await feathersClient.service('cities').get(_id);
		this.preloadCityInfo(city);
	};

	preloadCityInfo = (city) => {
		this.setState({
			city: {
				...this.state.city,
				name: city.name,
				code: city.code,
                emails: city.emails,
                state: city.state,
			},
		});
	};

	resetState = () => {
		this.setState({
			loading: false,
			currentEmail: '',
			city: {
				name: '',
				code: '',
                emails: [],
                state: null,
			},
		});
	};

	editCity = async () => {
		if (
			this.state.city.name === '' ||
			this.state.city.code === '' ||
            this.state.city.emails.length === 0 ||
            this.state.city.state === '' ||
            this.state.city.state === null
		) { return; }

		await this.setState({
			loading: true,
		});

		await this.props.patch(this.props.match.params.id, this.state.city);

		this.props.find(this.props.cities.store ? this.props.cities.store.page : 1);

		this.setState({
			loading: false,
		});
		// this.resetState()
	};

	addEmail = async () => {
		let emails = [];

		if (this.state.city.emails) {
			emails = [...this.state.city.emails];
		}

		if (emails.indexOf(this.state.currentEmail) === -1) {
			emails.push(this.state.currentEmail);
		}

		this.setState({
			currentEmail: '',
			city: {
				...this.state.city,
				emails,
			},
		});
	};

	deleteEmail = (email) => {
		// eslint-disable-next-line prefer-const
		let emails = [...this.state.city.emails];

		const pos = emails.indexOf(email);

		emails.splice(pos, 1);

		this.setState({
			city: {
				...this.state.city,
				emails,
			},
		});
	};

	// #region statichtml
	renderHeader = () => {
		return (
			<Grid>
				<Grid.Row columns={1}>
					<Grid.Column>
						<Header
							as="h2"
							icon="building"
							content="Ciudades > Editar"
						/>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		);
	};

	// #endregion statichtml

	renderEmails = () => {
		if (!this.state.city.emails) return <div></div>;
		return this.state.city.emails.map((v, i) => {
			return (
				<Label>
					{v}
					<Icon name="delete" onClick={_ => this.deleteEmail(v)} />
				</Label>
			);
		});
	};

	render() {
		return (
			<div>
				<this.renderHeader />
				<Divider />
				<Segment>
					<Form>
						<Form.Group widths="equal">
							<Form.Field required>
								<Form.Input
									label="Nombre"
									value={this.state.city.name}
									onChange={(e, { value }) => {
										this.setState({
											city: {
												...this.state.city,
												name: value,
											},
										});
									}}
								/>
							</Form.Field>
							<Form.Field required>
								<Form.Input
									label="Código"
									placeholder="NL/MX"
									value={this.state.city.code}
									onChange={(e, { value }) => {
										this.setState({
											city: {
												...this.state.city,
												code: value,
											},
										});
									}}
								/>
							</Form.Field>
                            <Form.Field required>
                                <Dropdown
                                    placeholder="Estado"
                                    search
                                    selection
                                    options={this.state.states}
                                    value={this.state.city.state}
                                    style={{
                                        height: '50%',
                                        width: '100%',
                                        top: 24,
                                    }}
                                    onChange={(e, { value }) => {
                                        this.setState({
											city: {
												...this.state.city,
												state: value,
											},
										});
                                    }}
                                />
							</Form.Field>
						</Form.Group>
						<Form.Group>
							<Form.Field
								style={{
									display: 'flex',
								}}
							>
								<Form.Input
									label="Emails"
									placeholder="xxxx@cccc.com"
									onChange={(e, { value }) => {
										this.setState({
											currentEmail: value,
										});
									}}
									value={this.state.currentEmail}
								/>
								<Form.Button
									style={{
										marginLeft: 10,
									}}
									onClick={this.addEmail}
									label="Agregar"
									circular
									icon="add"
									color="blue"
								/>
							</Form.Field>
						</Form.Group>
						<Form.Group>
							<Form.Field>
								<div
									style={{
										display: 'flex',
										flex: 1,
										flexWrap: 'wrap',
									}}
								>
									<this.renderEmails />
								</div>
							</Form.Field>
						</Form.Group>
						<Divider />
						<Grid>
							<Grid.Row>
								<Grid.Column
									floated="right"
									computer={16}
									largeScreen={3}
									widescreen={3}
									tablet={16}
									mobile={16}
								>
									<Button
										loading={this.state.loading}
										disabled={this.state.loading}
										onClick={this.editCity}
										positive
										fluid
										icon="pencil"
										content="EDITAR"
										labelPosition="left"
									/>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Form>
				</Segment>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	cities: state.services.cities,
});

const mapDispatchToProps = (dispatch, { services }) => ({
	patch: (id, data) => dispatch(services.cities.patch(id, data)),
	find: page =>
		dispatch(
			services.cities.find({
				query: {
					$skip: (page - 1) * ROWS_PER_PAGE,
					$limit: ROWS_PER_PAGE,
					$sort: {
						createdAt: -1,
					},
				},
			}),
        ),
    findStates: () =>
		dispatch(
			services.states.find({
				query: {
					$limit: -1,
				},
			}),
		),
});

export default connect(mapStateToProps, mapDispatchToProps)(CitiesEdit);

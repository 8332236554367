export const ROWS_PER_PAGE = 5;

export const PROCESSES = [
    { value: 'SMAW', text: 'SMAW'},
    { value: 'MIGMAG', text: 'MIGMAG'},
    { value: 'TIG', text: 'TIG'},
]

export const DECISION = [
    { value: true, text: 'Si'},
    { value: false, text: 'No' }
]
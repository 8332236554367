import React, { Component } from 'react'
import { Grid, Button, Modal, Input, Icon } from 'semantic-ui-react'

class QuestionFormModal extends Component {
    state = {
        currentAnswer: '',
        correctAnswer: null,
        answers: [],
        title: '',
        // isEditing: 
    }

    // static getDerivedStateFromProps(prevProps) {
    //     if (prevProps.currentQuestion !== this.props.currentQuestion) {
    //         return {
    //             ...this.props.currentQuestion,
    //         }
    //     }

    //     return null
    // }

    componentDidUpdate(prevProps) {
        if (prevProps.currentQuestion !== this.props.currentQuestion) {
            this.setState({
                ...this.props.currentQuestion,
            })
        }
    }

    onChange = (e, { value }) => {
        this.setState({
            currentAnswer: value,
        })
    }

    onEnter = (e) => {
        if (e.keyCode === 13) {
            if (this.state.answers.length < 4 && this.state.currentAnswer) {
                this.setState({
                    answers: this.state.answers.concat([e.target.value]),
                    currentAnswer: '',
                })
            } else {
                this.setState({
                    currentAnswer: '',
                })
            }
        }
    }

    removeAnswer = (index) => {
        const answers = [...this.state.answers]

        let { correctAnswer } = this.state

        if (correctAnswer !== null) {
            if (correctAnswer === index) correctAnswer = null
            else if (correctAnswer > index) correctAnswer = this.state.correctAnswer - 1
        }

        answers.splice(index, 1)

        this.setState({
            answers,
            correctAnswer,
        })
    }

    setAsCorrectAnswer = (index) => {
        const { correctAnswer } = this.state

        if (correctAnswer === index) {
            this.setState({
                correctAnswer: null,
            })
        } else {
            this.setState({
                correctAnswer: index,
            })
        }
    }

    addQuestion = () => {
        const { title, answers, correctAnswer } = this.state
        
        this.props.onQuestionAdd({
            title, answers, correctAnswer,
        })

        this.setState({
            title: '',
            answers: [],
            correctAnswer: null,
        })
    }

    addAnswer = () => {
        if (this.state.currentAnswer) {
            this.setState({
                answers: this.state.answers.concat([this.state.currentAnswer]),
                currentAnswer: '',
            })
        }
    }

    close = () => {
        this.setState({
            title: '',
            answers: [],
            correctAnswer: null,
        })

        this.props.onClose && this.props.onClose()
    }

    render() {
        return (
            <div>
                <Modal size="small" open={this.props.open} onClose={() => { this.props.onClose && this.props.onClose() }} >
                    <Modal.Header>
                        {this.props.currentQuestion ? 'Editar pregunta' : 'Nueva pregunta'}
                    </Modal.Header>
                    <Modal.Content>
                        <Input label="Titulo" fluid style={{ marginBottom: 10 }} onChange={(e, { value }) => this.setState({ title: value })} value={this.state.title} />
                        <Input 
                            label="Respuesta" 
                            style={{ marginBottom: 10 }} 
                            value={this.state.currentAnswer} 
                            placeholder="Escribe una respuesta..." 
                            fluid 
                            action={{ icon: 'plus', content: 'Agregar', onClick: this.addAnswer }} 
                            onKeyDown={this.onEnter} 
                            onChange={this.onChange} 
                            disabled={this.state.answers.length === 4} 
                        />

                        {this.state.answers.length > 0 && (
                            <div>
                                <div style={{ margin: '25px 0 10px' }}>
                                    <span>
                                        Selecciona una respuesta para elegirla como correcta
                                    </span>
                                </div>
                                <Grid>
                                    <Grid.Row columns={this.state.answers.length}>
                                        {this.state.answers.map((answer, index) => (
                                            <Grid.Column key={index}>
                                                <div>
                                                    <div style={{
                                                        backgroundColor: '#e8e8e8',
                                                        borderRadius: 3,
                                                        padding: '5px 10px',
                                                        fontFamily: 'Lato',
                                                        fontSize: 12,
                                                        fontWeight: 700,
                                                        color: 'rgba(0,0,0,.6)',
                                                        display: 'flex',
                                                        border: this.state.correctAnswer === index ? '3px solid #08cb08' : 0,
                                                        margin: this.state.correctAnswer !== index ? '3px 0 0' : 0,
                                                    }}
                                                    >
                                                        <div style={{ flex: 1, marginRight: 5 }} onClick={() => this.setAsCorrectAnswer(index)}>
                                                            <span>{answer}</span>
                                                        </div>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <Icon style={{ cursor: 'pointer', margin: 0 }} onClick={() => this.removeAnswer(index)} name="delete" />
                                                        </div>

                                                    </div>
                                                    {this.state.correctAnswer === index && (
                                                        <div style={{ textAlign: 'center' }}>
                                                            <span style={{ color: '#08cb08', fontWeight: 700 }}>Correcta</span>
                                                        </div>
                                                    )}
                                                </div>
                                            </Grid.Column>
                                        ))}
                                    </Grid.Row>
                                </Grid>
                            </div>
                        )}
                        
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={this.close} content="Cancelar" />
                        <Button
                            positive
                            icon="checkmark"
                            labelPosition="right"
                            content={this.props.currentQuestion ? 'Editar pregunta' : 'Agregar pregunta'}
                            onClick={this.addQuestion}
                            disabled={!this.state.title || this.state.correctAnswer === null || this.state.answers.length !== 4}
                        />
                    </Modal.Actions>
                </Modal>

            </div>
        )
    }
}

export default QuestionFormModal

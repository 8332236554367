import React, { Component } from 'react'
import { Grid, Segment, Header, Divider, TransitionablePortal, Icon } from 'semantic-ui-react'
import feathersClient from '../redux/FeathersRedux'
import moment from 'moment'
import { InfoCard } from '../components/InfoCard'
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import {connect} from 'react-redux' 

class HomeContainer extends Component {

  /*#region state */

  state = {
    loading_cards: false,
    loading_events_chart: false,
    loading_users_chart: false,
    total_of_users: 0,
    total_of_users_per_mont: 0,
    total_of_events_per_mont: 0,

    events_of_last_3_months_chart: [],
    users_of_last_3_months_chart: []
  }

  /*#endregion state */


  /* #region cycle */

  componentDidMount(){

    this.fetchTotalOfUser()
    this.fetchTotalOfEvents()
    this.fetchingEventsOfLastThreeMonts()
    this.fetchingUsersOfLastThreeMonts()

  }

  /* #endregion cycle */


  /* #region fetchingData */
  fetchTotalOfUser = async _ => {

    await this.setState({
        loading_cards: true
    })

    // first ge are going to get all the users
    let total_of_users = await feathersClient.service('users').find()

    // then the users of the last month


    // here we are gettin' the date of one month ago.
    let lastmonthdate = moment().date(1).format('YYYY-MM-DD')


    let total_of_users_per_mont = await feathersClient.service('users').find({
      query: {
        createdAt: {
          $gte: lastmonthdate
        }
      }
    })


    this.setState({
      loading_cards: false,
      total_of_users: total_of_users.total,
      total_of_users_per_mont: total_of_users_per_mont.total
    })

  }


  fetchTotalOfEvents = async _ => {
    let lastmonthdate = moment().date(1).format('YYYY-MM-DD')

    await this.setState({
        loading_cards: true
    })

    let total_of_events_per_mont = await feathersClient.service('events').find({
      query: {
        date: {
          $gte: lastmonthdate
        }
      }
    })

    this.setState({
      loading_cards: false,
      total_of_events_per_mont: total_of_events_per_mont.total
    })
  }


  fetchingEventsOfLastThreeMonts = async _ => {
    await this.setState({
        loading_events_chart: true
    })
    let _3monthsago = moment().subtract(2, 'months').date(1).format('YYYY-MM-DD')
    let events_of_last_3_months_chart = JSON.parse(JSON.stringify(this.state.events_of_last_3_months_chart))

    let events_last_3_months = await feathersClient.service('events').find({
      query: {
        date: {
          $gte: _3monthsago
        },
        $limit: '-1'
      }
    })

    let dates_of_events = events_last_3_months.map( v => v.date)


    // we wanna get the names of the last 3 months

    let names_of_last_3_months = [moment().subtract(2, 'months').date(1).format('MMMM'), moment().subtract(1, 'months').date(1).format('MMMM'), moment().date(1).format('MMMM')]

    // from all the events, we get the month.
    let months_of_events_string = dates_of_events.map( v => moment(v).format("MMMM"))



    // we are gooing to count how many times is a month in this array months_of_events_string in order to know
    // ho many events did occur in that month



    for( let month of names_of_last_3_months ){
      let dataObjects = {
        name: month,
        value: 0
      }
      let counter = 0;

      for( let unwraped of months_of_events_string  ){
        if(unwraped === month){
          counter += 1
        }
      }

      dataObjects.value = counter



      events_of_last_3_months_chart.push(dataObjects)

    }


    this.setState({
      loading_events_chart: false,
      events_of_last_3_months_chart
    })




  }

  fetchingUsersOfLastThreeMonts  = async _ => {
    let _3monthsago = moment().subtract(2, 'months').date(1).format('YYYY-MM-DD')
    let users_of_last_3_months_chart = JSON.parse(JSON.stringify(this.state.users_of_last_3_months_chart))

    await this.setState({
        loading_users_chart: true
    })


    let users_last_3_months = await feathersClient.service('users').find({
      query: {
        $limit: '-1',
        createdAt: {
          $gte: _3monthsago
        },
      }
    })


    let dates_of_users = users_last_3_months.map( v => v.createdAt)


    // we wanna get the names of the last 3 months

    let names_of_last_3_months = [moment().subtract(2, 'months').date(1).format('MMMM'), moment().subtract(1, 'months').date(1).format('MMMM'), moment().date(1).format('MMMM')]

    // from all the events, we get the month.
    let months_of_users_string = dates_of_users.map( v => moment(v).format("MMMM"))



    // we are gooing to count how many times is a month in this array months_of_events_string in order to know
    // ho many events did occur in that month



    for( let month of names_of_last_3_months ){
      let dataObjects = {
        name: month,
        value: 0
      }
      let counter = 0;

      for( let unwraped of months_of_users_string  ){
        if(unwraped === month){
          counter += 1
        }
      }

      dataObjects.value = counter



      users_of_last_3_months_chart.push(dataObjects)

    }


    this.setState({
      loading_users_chart: false,
      users_of_last_3_months_chart
    })

  }

  /* #endregion fetchingData */


  /* #region renders */
  render_total_cards = _ => {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center'
      }}>
        <InfoCard label={"Usuarios Totales"} icon="users" value={this.state.total_of_users} color={"#439889"}/>
        <InfoCard label={"Usuarios en este mes"} icon="user" value={this.state.total_of_users_per_mont} color={"#63a4ff"}/>
        <InfoCard label={"Eventos en este mes"} icon="calendar" value={this.state.total_of_events_per_mont} color={"#c4001d"}/>


    </div>
    )
  }


  render_events_chart = _ => {
    return (
      <div style={{
        width: "100%",
        height: 350
      }}>
          <h3>Eventos en últimos 3 meses</h3>
          <ResponsiveContainer width="100%" height={300}>
          <LineChart  data={this.state.events_of_last_3_months_chart}>
              <Tooltip />
              <Line type="monotone" dataKey="value" stroke="#8884d8" />
              <CartesianGrid stroke="#ccc" />
              <XAxis dataKey="name" scaleToFit="true" textAnchor="middle"/>
              <YAxis />
          </LineChart>
          </ResponsiveContainer>
      </div>
    )
  }

  render_users_chart = _ => {
    return (
      <div style={{
        width: "100%",
        height: 350
      }}>
          <h3>Usuarios registrados en últimos 3 meses</h3>
          <ResponsiveContainer width="100%" height={300}>
          <LineChart  data={this.state.users_of_last_3_months_chart}>
              <Tooltip />
              <Line type="monotone" dataKey="value" stroke="#8884d8" />
              <CartesianGrid stroke="#ccc" />
              <XAxis dataKey="name" scaleToFit="true" textAnchor="middle"/>
              <YAxis />
          </LineChart>
          </ResponsiveContainer>
      </div>
    )
  }

  render() {
    return (
      <div>
        <Grid>
            <Grid.Row columns={1}>
                <Grid.Column>
                <Header as='h1'>
                    <Icon name='mobile' />
                    <Header.Content>
                        ¡Bienvenido!
                        <Header.Subheader>Este es el panel administrativo de la app FroniusMX.</Header.Subheader>
                    </Header.Content>
                </Header>   
                </Grid.Column>
            </Grid.Row>
        </Grid>
        <Divider />
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Segment loading={this.state.loading_cards}>
                <this.render_total_cards />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}> 
            <Grid.Column>
              <Segment loading={this.state.loading_events_chart}>
                  <this.render_events_chart />
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment loading={this.state.loading_users_chart}>
                  <this.render_users_chart />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    )
  }

  /* #endregion renders */

}


const mapStateToProps = (state) => ({
    auth: state.auth
})

export default connect(mapStateToProps, null)(HomeContainer)
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Grid, Header, Divider, Button, Segment, Form, Dropdown, TextArea} from 'semantic-ui-react'
import AlertModal from '../../components/AlertModal'
import { PROCESSES, ROWS_PER_PAGE, DECISION } from './ProductData'
import CurrencyInput from 'react-currency-masked-input'
import { Dropzone } from '../../components'

class ProductCreate extends Component {

    state = {
        sku: '',
        name: '',
        serialNumber: '',
        version: '',
        multivoltage: null,
        amperage: '',
        processes: [],
        price: '',
        description: '',
        accessories: '',
        modalVisible: false,
        error: false
    }

    onDropChange = (image) => {
        this.setState({ image })
    }

    save = () => {
        if (this.props.products.isSaving) return;

        if (!this.state.price) {
            this.setState({ error: true })
            return;
        }

        let data = {...this.state};

        if (data.image) data.image = data.image.base64;
        delete data.modalVisible;
        delete data.error;

        this.props.create(data)
            .then(res => {
                this.setState({ modalVisible: true })
                this.props.find(this.props.products.store ? this.props.products.store.page : 1)
            })
    }

    render() {
        return (
            <div>
                <Grid>
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <Header as='h2' icon='cubes' content='Productos usados > Nuevo' />      
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Divider/>

                <Segment>
                    <Form onSubmit={this.save}>
                        <Form.Group widths="equal">
                            <Form.Input required label="SKU" type="text" placeholder="SKU" value={this.state.sku} name="sku" onChange={(e, {name, value}) => this.setState({[name]: value})} />
                            <Form.Input required label="Nombre" type="text" placeholder="Nombre" value={this.state.name} name="name"  onChange={(e, {name, value}) => this.setState({[name]: value})} />
                            <Form.Input required label="No. Serie" type="text" placeholder="No. Serie" value={this.state.serialNumber} name="serialNumber" onChange={(e, {name, value}) => this.setState({[name]: value})} />
                            <Form.Input label="Versión" type="text" placeholder="Versión" value={this.state.version} name="version"  onChange={(e, {name, value}) => this.setState({[name]: value})} />
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Field required label="Multivoltaje" control={Dropdown} placeholder="Multivoltaje" value={this.state.multivoltage} name="multivoltage" selection options={DECISION} onChange={(e, {name, value}) => this.setState({[name]: value})} />
                            <Form.Input required label="Amperaje" type="text" placeholder="Amperaje" value={this.state.amperage} name="amperage" onChange={(e, {name, value}) => this.setState({[name]: value})} />
                            <Form.Input required label="Procesos" control={Dropdown} placeholder="Procesos" value={this.state.processes} multiple name="processes" selection options={PROCESSES} onChange={(e, {name, value}) => this.setState({[name]: value})} />
                            <Form.Field required error={this.state.error}>
                                <label>Precio</label>
                                <CurrencyInput placeholder="Precio" value={this.state.price} name="price" onChange={(e, price) => this.setState({ price, error: false })} />
                            </Form.Field>
                        </Form.Group>
                        <Form.Input required value={this.state.description} label="Descripción" control={TextArea} placeholder="Descripción" name="description" rows={3} maxlength="500" onChange={(e, {name, value}) => this.setState({ [name]: value})} />
                        <Form.Input value={this.state.accessories} label="Accesorios" control={TextArea} placeholder="Accesorios" name="accessories" rows={3} maxlength="500" onChange={(e, {name, value}) => this.setState({ [name]: value})} />
                        <Form.Field>
                            <label>Imágen</label>
                            <Dropzone onChange={this.onDropChange} image={this.state.image} />
                        </Form.Field>
                        
                        <Divider/>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column floated="right" computer={16} largeScreen={3}  widescreen={3} tablet={16} mobile={16}>
                                    <Button type="submit" positive fluid icon="plus" content="CREAR" labelPosition='left'/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </Segment>

                <AlertModal 
                    open={this.state.modalVisible} 
                    title="Éxito" 
                    message="El producto fue creado satisfactoriamente" 
                    onClose={() => this.setState({ modalVisible: false, sku: '', name: '', serialNumber: '', version: '', multivoltage: null, amperage: '', processes: [], price: '', description: '', accessories: '', image: null })} 
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    products: state.services.products
})

const mapDispatchToProps = (dispatch, {services}) => ({
    create: (data) => dispatch(services.products.create(data)),
    find: (page) => dispatch(services.products.find({
        query: {
            $skip: (page - 1) * ROWS_PER_PAGE,
            $limit: ROWS_PER_PAGE,
            type: 'used'
        }
    })),  
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductCreate)
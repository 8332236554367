import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import configureStore, { history } from './redux'
import { feathersAuthentication, feathersServices } from './redux/FeathersRedux'
import Config from './config'
import 'semantic-ui-css/semantic.min.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const store = configureStore();

if (localStorage[Config.jwtKey]) {
	store.dispatch(feathersAuthentication.authenticate()).catch(() => {})
}

const render = (Component) => {
	ReactDOM.render(
		<Provider store={store}>
			<ConnectedRouter history={history}>
				<Component services={feathersServices} />
			</ConnectedRouter>
		</Provider>,
		document.getElementById('root'),
	);
}

render(App)

// if (module.hot) {
// 	module.hot.accept('./App', () => {
// 		const NextApp = require('./App').default
// 		render(NextApp)
// 	});
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

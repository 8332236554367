import React, { Component } from 'react'
import { Modal, Header, Button} from 'semantic-ui-react'

class AlertModal extends Component {

    render() {
        return (
            <Modal open={this.props.open} basic size='small'>
                <Header size="huge" icon='checkmark' content={this.props.title} />
                <Modal.Content>
                    <p style={{fontSize: 20}}>{this.props.message}</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='green' inverted content="OK" onClick={this.props.onClose} />
                </Modal.Actions>
            </Modal>
        )
    }
}

export default AlertModal
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Grid,
	Header,
	Divider,
	Button,
	Segment,
	Form,
	Dropdown,
	TextArea,
} from 'semantic-ui-react';
import { TYPES } from './NotificationData';
import feathers from '../../redux/FeathersRedux';
import moment from 'moment';
import Config from '../../config';
import AlertModal from '../../components/AlertModal';

const REGEX = /^.*(?:youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#\&\?]*).*/;

class NotificationContainer extends Component {
	state = {
		title: '',
		content: '',
		type: '',
		id: '',
		dropdownOptions: [],
		news: [],
		events: [],
		quizes: [],
		usedProducts: [],
		modalVisible: false,
	};

	componentDidMount() {
		Promise.all([
			this.getEvents(),
			this.getNews(),
			this.getQuizes(),
			this.getUsedProducts(),
		]).then((res) => {
			this.setState({
				events: res[0].map((e) => ({
					value: e._id,
					text: `${e.type} - ${e.title} - ${moment(e.date).format(
						'YYYY-MM-DD',
					)}`,
				})),
				news: res[1].map((e) => ({
					value: e._id,
					text: e.title,
					image: { size: 'mini', src: this.getNewsImage(e) },
				})),
				quizes: res[2].map((e) => ({
					value: e._id,
					text: `${e.name}`,
				})),
				usedProducts: res[3].map((e) => ({
					value: e._id,
					text: `${e.sku} ${e.name} ${e.amperage} - ${e.version}`,
				})),
			});
		});
	}

	getNewsImage = (news) => {
		if (news.type === 'normal') return Config.apiUrl + news.url;

		if (news.type === 'video') {
			let groups = news.url.match(REGEX);

			if (groups && groups[1]) {
				return `https://img.youtube.com/vi/${groups[1]}/0.jpg`;
			}

			return null;
		}
	};

	getEvents = () => {
		return feathers.service('events').find({
			query: {
				active: true,
				date: {
					$gte: new Date(),
				},
				$limit: '-1',
			},
		});
	};

	getNews = () => {
		return feathers.service('news').find({
			query: {
				visible: true,
				date: {
					$lte: new Date(),
					$gt: moment().subtract(6, 'months').toDate(),
				},
				$sort: {
					createdAt: -1,
					date: -1,
				},
				$limit: '-1',
			},
		});
	};

	getQuizes = (_) => {
		return feathers.service('quizes').find({
			query: {
				active: true,
				startDate: {
					$lte: moment()
						.hour(23)
						.minute(58)
						.second(58)
						.format('YYYY-MM-DD HH:mm:ss'),
				},
				finishDate: {
					$gte: moment()
						.hour(23)
						.minute(58)
						.second(58)
						.format('YYYY-MM-DD HH:mm:ss'),
				},
				$limit: '-1',
			},
		});
	};

	getUsedProducts = () => {
		return feathers.service('products').find({
			query: {
				active: true,
				type: 'used',
				$limit: '-1',
			},
		});
	};

	onTypeChanged = (e, { value }) => {
		let dropdownOptions = [];

		if (value === 'news') {
			dropdownOptions = [...this.state.news];
		} else if (value === 'event') {
			dropdownOptions = [...this.state.events];
		} else if (value === 'quiz') {
			dropdownOptions = [...this.state.quizes];
		} else if (value === 'usedProduct') {
			dropdownOptions = [...this.state.usedProducts];
		} else {
		}

		this.setState({
			type: value,
			dropdownOptions,
		});
	};

	save = () => {
		const { title, content, type, id } = this.state;

		this.props.create({ title, content, type, id }).then((res) => {
			this.setState({ modalVisible: true });
		});
	};

	renderQuizNewsOrEventSelect = (_) => {
		if (this.state.type === 'news') {
			return (
				<Form.Field
					search
					required
					label={'Noticia'}
					control={Dropdown}
					placeholder="Elige una opción"
					value={this.state.id}
					name="type"
					selection
					options={this.state.dropdownOptions}
					onChange={(e, { value }) => this.setState({ id: value })}
				/>
			);
		} else if (this.state.type === 'event') {
			return (
				<Form.Field
					search
					required
					label={'Evento'}
					control={Dropdown}
					placeholder="Elige una opción"
					value={this.state.id}
					name="type"
					selection
					options={this.state.dropdownOptions}
					onChange={(e, { value }) => this.setState({ id: value })}
				/>
			);
		} else if (this.state.type === 'quiz') {
			return (
				<Form.Field
					search
					required
					label={'Quiz'}
					control={Dropdown}
					placeholder="Elige una opción"
					value={this.state.id}
					name="type"
					selection
					options={this.state.dropdownOptions}
					onChange={(e, { value }) => this.setState({ id: value })}
				/>
			);
		} else if (this.state.type === 'product') {
            return (
                <Form.Input
                    required
                    label={'Producto'}
                    placeholder="Escribe un SKU usando sólo números ej. 4075108"
                    type="text"
                    onChange={(e, { value }) => this.setState({
                        id: value.replace(/,/g, '')
                    })}
                    value={this.state.id}
                />
            )
		} else if (this.state.type === 'usedProduct') {
			return (
				<Form.Field
					search
					required
					label={'Producto usado'}
					control={Dropdown}
					placeholder="Elige una opción"
					value={this.state.id}
					name="type"
					selection
					options={this.state.dropdownOptions}
					onChange={(e, { value }) => this.setState({ id: value })}
				/>
			);
		}

		return (
			<Form.Field
				search
				required
				label={'Elemento'}
				control={Dropdown}
				placeholder="Elige una opción"
				value={this.state.id}
				name="type"
				selection
				options={this.state.dropdownOptions}
				onChange={(e, { value }) => this.setState({ id: value })}
			/>
		);
	};

	render() {
		return (
			<div>
				<Grid>
					<Grid.Row columns={1}>
						<Grid.Column>
							<Header
								as="h2"
								icon="bell"
								content="Notificaciones"
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<Divider />

				<Segment>
					<Form
						onSubmit={this.save}
						loading={this.props.notifications.isSaving}
					>
						<Form.Input
							required
							value={this.state.title}
							label="Título"
							type="text"
							placeholder="Título de la notificacion"
							onChange={(e, { value }) =>
								this.setState({ title: value })
							}
						/>
						<Form.Input
							required
							value={this.state.content}
							label={`Contenido (${this.state.content.length}/150)`}
							control={TextArea}
							placeholder="Contenido de la notificacion"
							rows={2}
							maxlength="150"
							onChange={(e, { value }) =>
								this.setState({ content: value })
							}
						/>
						<Form.Group widths="equal">
							<Form.Field
								required
								label="Tipo de notificacion"
								control={Dropdown}
								placeholder="Tipo de notificacion"
								value={this.state.type}
								name="type"
								selection
								options={TYPES}
								onChange={this.onTypeChanged}
							/>
							{this.renderQuizNewsOrEventSelect()}
						</Form.Group>
						<Divider />
						<Grid>
							<Grid.Row>
								<Grid.Column
									floated="right"
									computer={16}
									largeScreen={3}
									widescreen={3}
									tablet={16}
									mobile={16}
								>
									<Button
										type="submit"
										positive
										fluid
										icon="plus"
										content="CREAR"
										labelPosition="left"
									/>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Form>
				</Segment>

				<AlertModal
					open={this.state.modalVisible}
					title="Éxito"
					message="La notificación fue creada satisfactoriamente"
					onClose={() =>
						this.setState({
							title: '',
							content: '',
							type: '',
							id: '',
							dropdownOptions: [],
							modalVisible: false,
						})
					}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	notifications: state.services.notifications,
});

const mapDispatchToProps = (dispatch, { services }) => ({
	create: (data) => dispatch(services.notifications.create(data)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(NotificationContainer);

import React, { Component } from 'react'
import { Button, Sidebar, Menu, Icon, Segment, Responsive, Image } from 'semantic-ui-react'
import { Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { links, routes } from '../routes/AppLayout'
import { feathersAuthentication } from '../redux/FeathersRedux'

import './Styles/MainContainerStyles.css'

const logoImage = require('../resources/images/logo.png');

class MainContainer extends Component {
    constructor(props) {
        super(props)

        this.state = {
            visible: true,
            minVisible: false,
        }

        this.toggleVisibility = this.toggleVisibility.bind(this)
        this.renderLinks = this.renderLinks.bind(this)
        this.handleClick = this.handleClick.bind(this)
    }

    toggleVisibility(size) {
        if (size === 'min') {
            this.setState({ minVisible: !this.state.minVisible })
        } else {
            this.setState({ visible: !this.state.visible })
        }
    }

    renderLinks(mobile) {
        return links.map((r, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Menu.Item name={r.name} onClick={() => this.handleClick(r.path, mobile)} key={i}>
                    {r.icon && (
                        <Icon name={r.icon} />
                    )}
                    {r.displayName}
                </Menu.Item>))
    }

    handleClick(path, mobile) {
        this.props.history.push(path)

        if (mobile) {
            this.setState({ minVisible: false })
        }
    }

    logout = () => {
        this.props.logout()
    }

    render() {
        const { visible, minVisible } = this.state
        return (
            <div>

                <Responsive maxWidth={Responsive.onlyTablet.maxWidth}>
                    <Sidebar.Pushable>
                        <Segment size="mini" color="red" inverted textAlign="center" style={{ borderRadius: 0, marginBottom: 0 }}>
                            <Button icon="content" onClick={() => this.setState({ minVisible: true })} style={{ position: 'absolute', left: 10 }} />
                            <Image
                                // src={require('../resources/images/logo.png')}
                                src={logoImage}
                                style={{
                                    display: 'inline', height: 30, width: 'auto', alignSelf: 'center',
                                }}
                            />
                        </Segment>

                        <Sidebar as={Menu} animation="overlay" width="thin" visible={minVisible} icon="labeled" vertical inverted>
                            <p style={{ color: 'white', margin: '10px 5px' }}>Bienvenido, {this.props.user.name} </p>
                            {this.renderLinks(true)}
                            <Menu.Item name="camera" onClick={this.logout}>
                                <Icon name="power" />
                                Cerrar sesión
                            </Menu.Item>
                        </Sidebar>
                        <Sidebar.Pusher onClick={() => this.setState({ minVisible: false })} style={{ minHeight: 'calc(100vh - 57.3333px)' }}>
                            <Segment basic>
                                <Switch>
                                    {routes}
                                </Switch>
                            </Segment>
                        </Sidebar.Pusher>
                    </Sidebar.Pushable>
                </Responsive>

                <Responsive minWidth={Responsive.onlyComputer.minWidth}>
                    <div>
                        <Segment size="mini" color="red" inverted style={{ borderRadius: 0, marginBottom: 0 }}>
                            <Button icon="content" onClick={() => this.toggleVisibility('max')} style={{ display: 'inline' }} />
                            <Image
                                src={logoImage}
                                style={{ display: 'inline', height: 30, width: 'auto' }}
                                // src={require('../resources/images/logo.png')}
                            />
                        </Segment>
                        <Sidebar.Pushable style={{ height: 'calc(100vh - 57.89px)' }}>
                            <Sidebar style={{ width: 260 }} as={Menu} animation="push" visible={visible} vertical inverted>
                                <p style={{ color: 'white', marginTop: 10, marginLeft: 15 }}>Bienvenido, {this.props.user.name} </p>
                                {this.renderLinks()}
                                <Menu.Item name="camera" onClick={this.logout}>
                                    <Icon name="power" />
                                    Cerrar sesión
                                </Menu.Item>
                            </Sidebar>
                            <Sidebar.Pusher
                                style={{
                                    padding: 10,
                                    width: visible ? 'calc(100vw - 260px)' : '100%',
                                    height: 'calc(100vh - 57.89px)',
                                    overflowY: 'visible',
                                }}
                            >
                                <Segment basic>
                                    {routes}
                                </Segment>
                            </Sidebar.Pusher>
                        </Sidebar.Pushable>
                    </div>
                </Responsive>
            </div>
        )
    }
}

const mapStateToProps = state => ({
        user: state.auth.user,
    })

const mapDispatchToProps = dispatch => ({
        logout: () => dispatch(feathersAuthentication.logout()),
    })

export default connect(mapStateToProps, mapDispatchToProps)(MainContainer)

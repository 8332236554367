import React, {Component} from 'react'
import {Item} from 'semantic-ui-react'
import Config from '../config'

const REGEX = /^.*(?:youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#\&\?]*).*/;

export default class NewsImage extends Component {

    render() {
        if (this.props.item.type === 'normal') {
            return <Item.Image size="small" rounded src={Config.apiUrl + this.props.item.url} />
        }

        if (this.props.item.type === 'video') {
            let groups = this.props.item.url.match(REGEX);

            if (groups && groups[1]) {
                return <Item.Image size="small" rounded src={`https://img.youtube.com/vi/${groups[1]}/0.jpg`} />
            }
            return null
        }
    }
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import {
	Button,
	Header,
	Pagination,
	Segment,
	Grid,
	Divider,
	Table,
	Checkbox,
	Image,
	// Modal,
} from 'semantic-ui-react';
import { ROWS_PER_PAGE } from './BanksData';
import Config from '../../config';

class BanksContainer extends Component {
	componentDidMount() {
		if (!this.props.banks.store) {
			this.getBanks(1);
		}
	}

	getBanks = (page) => {
		this.props.store({ page });

		setTimeout(() => {
			this.props.find({
				query: {
					$skip: (this.props.banks.store.page - 1) * ROWS_PER_PAGE,
					$limit: ROWS_PER_PAGE,
					$sort: {
						createdAt: -1,
					},
				},
			});
		}, 60);
	};

	handleActiveChange = (_id, checked) => {
		this.props
			.patch(_id, { active: checked ? 1 : 0 })
			.then((response) => {
				this.getBanks(this.props.banks.store.page);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	renderTableBody = () => {
		if (this.props.banks.queryResult == null) return;
		// eslint-disable-next-line consistent-return
		return this.props.banks.queryResult.data.map((bank) => (
			<Table.Row key={bank._id}>
				<Table.Cell>{bank.name}</Table.Cell>
				<Table.Cell>{`${bank.installments.join(
					', ',
				)} meses`}</Table.Cell>
				<Table.Cell>
					{bank.logo ? (
						<Image
							src={`${Config.apiUrl}/${bank.logo}`}
							style={{ width: 50, height: 50 }}
							rounded
						/>
					) : null}
				</Table.Cell>
				<Table.Cell>
					<Checkbox
						toggle
						checked={bank.active}
						onChange={(e, { checked }) =>
							this.handleActiveChange(bank._id, checked)
						}
					/>
				</Table.Cell>
				<Table.Cell>
					<Button
						color="black"
						size="mini"
						icon="pencil"
						content="Editar"
						onClick={() => this.props.push(`/banks/${bank._id}`)}
					/>
				</Table.Cell>
			</Table.Row>
		));
	};

	renderPagination = () => {
		if (this.props.banks.queryResult == null) return null;
		if (!this.props.banks.store || this.props.banks.queryResult.total === 0)
			return null;
		return (
			<Pagination
				activePage={this.props.banks.store.page}
				totalPages={Math.ceil(
					this.props.banks.queryResult.total / ROWS_PER_PAGE,
				)}
				onPageChange={(e, { activePage }) => this.getBanks(activePage)}
			/>
		);
	};

	render() {
		return (
			<div>
				<Grid>
					<Grid.Row columns={2}>
						<Grid.Column>
							<Header as="h2" icon="sitemap" content="Bancos" />
						</Grid.Column>
						<Grid.Column>
							<Button
								color="red"
								icon="plus"
								floated="right"
								content="Nuevo"
								onClick={() => this.props.push('/banks/create')}
							/>
							<Button
								icon="refresh"
								color="grey"
								floated="right"
								onClick={() =>
									this.getBanks(this.props.banks.store.page)
								}
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<Divider />

				<Grid>
					<Grid.Row columns={1}>
						<Grid.Column>
							<Segment
								raised
								loading={this.props.banks.isLoading}
							>
								<Table striped>
									<Table.Header>
										<Table.Row>
											<Table.HeaderCell>
												Nombre
											</Table.HeaderCell>
											<Table.HeaderCell>
												Parcialidades
											</Table.HeaderCell>
											<Table.HeaderCell>
												Imágen
											</Table.HeaderCell>
											<Table.HeaderCell>
												Active
											</Table.HeaderCell>
											<Table.HeaderCell></Table.HeaderCell>
										</Table.Row>
									</Table.Header>
									<Table.Body>
										{this.renderTableBody()}
									</Table.Body>
								</Table>
							</Segment>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row columns={1}>
						<Grid.Column textAlign="center">
							{this.renderPagination()}
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	banks: state.services.banks,
	router: state.router,
});

const mapDispatchToProps = (dispatch, { services }) => ({
	find: (query = {}) => dispatch(services.banks.find(query)),
	patch: (id, data) => dispatch(services.banks.patch(id, data)),
	store: (data) => dispatch(services.banks.store(data)),
	push: (location) => dispatch(push(location)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BanksContainer);

import React from 'react';
import { Header, Modal, Grid, Image } from 'semantic-ui-react';
import 'react-day-picker/lib/style.css';
import moment from 'moment';
import 'moment/locale/es';

import config from '../config';

const DemoInfoModal = (props) => {
	const { demonstration } = props.demo;
	return (
		<Modal
			open={props.isOpen}
			onClose={props.onClose}
			closeIcon
			size="large"
		>
			<Modal.Header>{`Información de la demo ${props.demo.code}`}</Modal.Header>
			<Modal.Content scrolling>
				<Grid columns={6}>
					<Grid.Row>
						<Grid.Column>
							<p style={styles.bold}>Cliente</p>
							<span>
								{props.demo.user
									? `${props.demo.user.name} ${props.demo.user.lastName}`
									: '---'}
							</span>
						</Grid.Column>
						<Grid.Column>
							<p style={styles.bold}>Teléfono</p>
							<span>{props.demo.telephone}</span>
						</Grid.Column>
						<Grid.Column>
							<p style={styles.bold}>Material</p>
							<span>{props.demo.material}</span>
						</Grid.Column>
						<Grid.Column>
							<p style={styles.bold}>Ciudad</p>
							<span>
								{props.demo.city ? props.demo.city.name : '---'}
							</span>
						</Grid.Column>
						<Grid.Column>
							<p style={styles.bold}>Espesor</p>
							<span>{props.demo.thickness}</span>
						</Grid.Column>
						<Grid.Column>
							<p style={styles.bold}>Procesos</p>
							<span>
								{props.demo.process
									? props.demo.process.join(', ')
									: '---'}
							</span>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				{props.demo.status === 'Realizada' && demonstration && (
					<React.Fragment>
						<Header as="h2">
							Registro de demo
							<Header.Subheader>
								{moment(demonstration.date).format(
									'YYYY-MM-DD HH:mm:ss',
								)}
							</Header.Subheader>
						</Header>

						<Grid>
							<Grid.Row>
								<Grid.Column width={8}>
									<p>
										<span style={styles.bold}>
											{'Tipo de actividad: '}
										</span>
										<span>{demonstration.activityType || '--'}</span>
									</p>
									<p>
										<span style={styles.bold}>
											{'Maquina usada: '}
										</span>
										<span>{demonstration.machineUsed}</span>
									</p>
									<p>
										<span style={styles.bold}>
											{'Nombre distribuidor: '}
										</span>
										<span>
											{demonstration.distributorName}
										</span>
									</p>
									<p>
										<span style={styles.bold}>
											{'Nombre vendedor: '}
										</span>
										<span>
											{demonstration.salesmanName}
										</span>
									</p>
									<p>
										<span style={styles.bold}>
											{'Tel. de vendedor: '}
										</span>
										<span>
											{demonstration.salesmanPhone}
										</span>
									</p>
									<p>
										<span style={styles.bold}>
											{'Empresa: '}
										</span>
										<span>{demonstration.companyName}</span>
									</p>
									<p>
										<span style={styles.bold}>
											{'Sector empresarial: '}
										</span>
										<span>
											{demonstration.companySector}
										</span>
									</p>
									<p>
										<span style={styles.bold}>
											{'Dirección empresarial: '}
										</span>
										<span>
											{demonstration.companyAddress}
										</span>
									</p>
									<p>
										<span style={styles.bold}>
											{'C.P. empresa: '}
										</span>
										<span>
											{demonstration.companyZipcode}
										</span>
									</p>
									<p>
										<span style={styles.bold}>
											{'Ciudad de empresa: '}
										</span>
										<span>
											{demonstration.companyState}
										</span>
									</p>
									<p>
										<span style={styles.bold}>
											{'Nombre de contacto: '}
										</span>
										<span>{demonstration.contactName}</span>
									</p>
									<p>
										<span style={styles.bold}>
											{'Tel. de contacto: '}
										</span>
										<span>
											{demonstration.contactPhone}
										</span>
									</p>
									<p>
										<span style={styles.bold}>
											{'Email contacto: '}
										</span>
										<span>
											{demonstration.contactEmail}
										</span>
									</p>
									<p>
										<span style={styles.bold}>
											{'Puesto de trabajo: '}
										</span>
										<span>
											{demonstration.contactJobPosition}
										</span>
									</p>
								</Grid.Column>
								<Grid.Column width={8}>
									<Header as="h3">Situación actual</Header>
									<p>
										<span style={styles.bold}>
											{'Máquina actual: '}
										</span>
										<span>
											{demonstration.currentMachine}
										</span>
									</p>
									<p>
										<span style={styles.bold}>
											{'Máquinas de la competencia: '}
										</span>
										<span>
											{demonstration.competitionMachines}
										</span>
									</p>
									<p>
										<span style={styles.bold}>
											{'Cantidad de equipos requeridos: '}
										</span>
										<span>
											{
												demonstration.requiredEquipmentAmount
											}
										</span>
									</p>
									<p>
										<span style={styles.bold}>
											{
												'Fecha estimada de compra de proyecto: '
											}
										</span>
										<span>
											{demonstration.estimatedProyectPurchaseDate
												? moment(
														demonstration.estimatedProyectPurchaseDate,
												  ).format('YYYY-MM-DD')
												: '---'}
										</span>
									</p>

									<Header as="h3">Imágenes</Header>
									{demonstration.photos.map((photo) => (
										<Image
											src={`${config.apiUrl}/${photo}`}
											size="small"
											style={{
												borderRadius: 10,
												cursor: 'pointer',
											}}
											onClick={() => {
												window.open(
													`${config.apiUrl}/${photo}`,
												);
											}}
										/>
									))}
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</React.Fragment>
				)}
			</Modal.Content>
		</Modal>
	);
};

const styles = {
	bold: {
		fontWeight: 'bold',
	},
};

export default DemoInfoModal;

import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import reducers from './Reducers';
import middleware, { history } from './Middleware';

export default function configureStore(initialState = {}) {
	const store = createStore(
		reducers,
		initialState,
		compose(applyMiddleware(...middleware),
			// Redux DevTools Extension.
			// It watches reducers and logs their invocations, actions and changing state.
			// It caches activity so you can 'time travel' through state changes.
			// It runs in an extension reducing the size of your app bundle.
			// This interface can be left in prod bundles and the extension activated as needed.
			// For installation see: https://github.com/zalmoxisus/redux-devtools-extension#installation
			// window.devToolsExtension ? window.devToolsExtension() : f => f,
		),
	)

	if (process.env.NODE_ENV !== 'production') {
		if (module.hot) {
			module.hot.accept('./Reducers', () => {
				store.replaceReducer(reducers);
			});
		}
	}
	
	return store;
}

export { history };


import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import {
	Button,
	Header,
	Modal,
	Pagination,
	Segment,
	Grid,
	Divider,
	Item,
	Label,
	Table,
	Search,
	Form,
	Loader,
	Dimmer,
} from 'semantic-ui-react';
import moment from 'moment';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import { ROWS_PER_PAGE } from './CustomerData';
import feathersClient from '../../redux/FeathersRedux';

// const renderClientSearchResults = ({ _id, name, lastName, lastName2 }) => <Label key={_id} content={`${name} ${lastName}`} />
const renderClientSearchResults = ({ _id, name, lastName }) => (
	<Label key={_id} content={`${name} ${lastName}`} />
);

class CustomerContainer extends Component {
	state = {
		currentUser: null,
		eventsModal: false,
		rolModal: false,
		// search bar objects
		client: null,
		// search bar results
		clientResults: null,
		// search bar loading
		clientLoading: null,
		// filters
		filter: {
			client_id: null,
		},
		isLoading: false,
	};

	timeout = null

	componentDidMount() {
		if (!this.props.users.store) {
			this.getUsers(1);
		}
	}

	/* #region searchBarFunctions */

	handleClientResultSelect = async (e, { result }) => {
		this.setState({ client: `${result.name} ${result.lastName}` });

		await this.setState({
			filter: {
				...this.state.filter,
				client_id: result._id,
			},
		});
	};

	handleClientSearchChange = async (e, { value }) => {
		await this.setState({ clientLoading: true, client: value });

		if (this.timeout) {
			clearTimeout(this.timeout)
		}

		this.timeout = setTimeout(() => {
			if (this.state.client.length < 1)
				return this.resetClientComponent();

			feathersClient
				.service('users')
				.find({
					query: {
						fullName: value
					}
					// query: {
					// 	$search: value,
					// 	email: {
					// 		$search: value,
					// 	},
					// 		},
					// 		{
					// 			email: {
					// 				$search: value,
					// 			},
					// 		},
					// 	],
					// },
				})
				.then((res) => {
					console.log(res)
					
					this.setState({
						clientLoading: false,
						clientResults: res,
					});
				});
		}, 500);
	};

	resetClientComponent = () =>
		this.setState({ clientLoading: false, clientResults: [], client: '' });

	/* #endregion searchBarFunctions */

	getUsers = (page) => {
		this.props.store({ page });

		// eslint-disable-next-line camelcase
		let custom_query = {};

		if (this.state.filter.client_id) {
			// eslint-disable-next-line camelcase
			custom_query = {
				// eslint-disable-next-line camelcase
				...custom_query,
				_id: this.state.filter.client_id,
			};
		}

		setTimeout(() => {
			this.props.find({
				query: {
					$skip: (this.props.users.store.page - 1) * ROWS_PER_PAGE,
					$limit: ROWS_PER_PAGE,
					// rol: 'customer',
					$sort: {
						createdAt: -1,
					},
					$populate: {
						path: 'staff',
						select: ['name', 'lastName', 'lastName2'],
					},
					// eslint-disable-next-line camelcase
					...custom_query,
				},
			});
		}, 50);
	};

	getUserEvents = (user) => {
		this.props
			.findEventUsers({
				query: {
					user: user._id,
					$populate: {
						path: 'event',
						select: ['type', 'date', 'title', 'location'],
					},
					$limit: -1,
				},
			})
			.then((res) => {
				this.setState({
					currentUser: user,
					eventsModal: true,
				});
			});
	};

	_handleSearchButton = (_) => {
		this.getUsers(this.props.users.store.page);
	};

	_handleCleanButton = (_) => {
		this.resetClientComponent();
		this.setState(
			{
				filter: {
					...this.state.filter,
					client_id: null,
				},
			},
			(_) => this.getUsers(this.props.users.store.page),
		);
	};

	openRolModal = (user) => {
		this.setState({
			currentUser: user,
			rolModal: true,
		});
	};

	doUserSupervisor = async (user) => {
		this.setState({
			isLoading: true,
		});
		// eslint-disable-next-line prefer-const
		let patchedUser = await feathersClient
			.service('users')
			.patch(user._id, {
				rol: 'supervisor',
			});
		this.setState({
			currentUser: patchedUser,
			isLoading: false,
		});
		this.getUsers(this.props.users.store.page);
	};

	removeSupervisor = async (user) => {
		// eslint-disable-next-line prefer-const
		let patchedUser = await feathersClient
			.service('users')
			.patch(user._id, {
				rol: 'customer',
			});
		this.setState({
			currentUser: patchedUser,
		});
		this.getUsers(this.props.users.store.page);
	};

	doUserStaff = async (user) => {
		this.setState({
			isLoading: true,
		});
		// eslint-disable-next-line prefer-const
		let patchedUser = await feathersClient
			.service('users')
			.patch(user._id, {
				rol: 'staff',
			});
		this.setState({
			currentUser: patchedUser,
			isLoading: false,
		});
		this.getUsers(this.props.users.store.page);
	};

	removeStaff = async (user) => {
		// eslint-disable-next-line prefer-const
		let patchedUser = await feathersClient
			.service('users')
			.patch(user._id, {
				rol: 'customer',
			});
		this.setState({
			currentUser: patchedUser,
		});
		this.getUsers(this.props.users.store.page);
	};

	renderTableBody = () => {
		return this.props.users.queryResult.data.map((user) => {
			return (
				<Table.Row key={user._id}>
					<Table.Cell>{user.name}</Table.Cell>
					<Table.Cell>{user.lastName}</Table.Cell>
					<Table.Cell>{user.lastName2}</Table.Cell>
					<Table.Cell>{user.email}</Table.Cell>
					<Table.Cell>{user.zipCode}</Table.Cell>
					<Table.Cell>{user.enterprise}</Table.Cell>
					<Table.Cell>
						{moment(user.createdAt).format('DD-MMMM-YYYY')}
					</Table.Cell>
					<Table.Cell>{user.lastIp}</Table.Cell>
					<Table.Cell>
						{user.rol === 'admin' ? (
							<Label tag color="blue">
								Admin
							</Label>
						) : (
							<span></span>
						)}
						{user.rol === 'staff' ? (
							<Label tag color="green">
								Staff
							</Label>
						) : (
							<span></span>
						)}
						{user.rol === 'supervisor' ? (
							<Label tag color="green">
								Supervisor
							</Label>
						) : (
							<span></span>
						)}
					</Table.Cell>

					{/* eslint-disable-next-line prefer-template */}
					<Table.Cell>
						{user.staff
							? `${user.staff.name} ${user.staff.lastName}`
							: '---'}
					</Table.Cell>

					<Table.Cell>
						<Button
							icon="calendar"
							style={{ marginTop: 1 }}
							color="red"
							content="Eventos"
							size="mini"
							onClick={() => this.getUserEvents(user)}
						/>
						{user.rol !== 'admin' ? (
							<Button
								style={{ marginTop: 1 }}
								icon="exchange"
								color="blue"
								content="Cambiar Rol"
								size="mini"
								onClick={(_) => this.openRolModal(user)}
							/>
						) : (
							<span></span>
						)}
					</Table.Cell>
				</Table.Row>
			);
		});
	};

	renderEvents = () => {
		if (
			Array.isArray(this.props.eventUsers.queryResult) &&
			this.props.eventUsers.queryResult.length
		) {
			const events = this.props.eventUsers.queryResult.map((eu) => {
				return (
					<Item>
						<Item.Content>
							<Item.Header>{eu.event.title}</Item.Header>
							<Item.Description>
								<Label
									color={
										eu.event.type === 'Fronius Friday'
											? 'red'
											: 'grey'
									}
									content={eu.event.type}
								/>
								<span
									style={{ marginLeft: 15, marginRight: 15 }}
								>
									{moment(eu.event.date).format(
										'DD-MMMM-YYYY',
									)}
								</span>
								Programado a las{' '}
								<span style={{ fontWeight: 'bold' }}>
									{eu.assignedTime}
								</span>
							</Item.Description>
						</Item.Content>
					</Item>
				);
			});

			return <Item.Group divided>{events}</Item.Group>;
		} else {
			return <span>Todavia no se ha inscrito a algún evento</span>;
		}
	};

	renderPagination = () => {
		if (!this.props.users.store || this.props.users.queryResult.total === 0)
			return null;

		return (
			<Pagination
				activePage={this.props.users.store.page}
				totalPages={Math.ceil(
					this.props.users.queryResult.total / ROWS_PER_PAGE,
				)}
				onPageChange={(e, { activePage }) => this.getUsers(activePage)}
			/>
		);
	};

	renderFormCustomerTypes = () => {
		if (this.state.isLoading) {
			return (
				<Dimmer active>
					<Loader />
				</Dimmer>
			);
		}
		return (
			<Form>
				<Form.Group>
					<Form.Field>
						<label>Ascender a Staff: </label>
						<Toggle
							defaultChecked={
								this.state.currentUser.rol === 'staff'
							}
							icons={{ unchecked: null }}
							onChange={(e) => {
								if (e.target.checked) {
									this.doUserStaff(this.state.currentUser);
								} else {
									this.removeStaff(this.state.currentUser);
								}
							}}
						/>
					</Form.Field>

					<Form.Field>
						<label>Ascender a Supervisor: </label>
						<Toggle
							defaultChecked={
								this.state.currentUser.rol === 'supervisor'
							}
							icons={{ unchecked: null }}
							onChange={(e) => {
								if (e.target.checked) {
									this.doUserSupervisor(
										this.state.currentUser,
									);
								} else {
									this.removeSupervisor(
										this.state.currentUser,
									);
								}
							}}
						/>
					</Form.Field>
				</Form.Group>
			</Form>
		);
	};

	renderChangeModalBody = () => {
		if (this.state.currentUser) {
			return (
				<div>
					<p>
						<b>Email:</b> {this.state.currentUser.email}
					</p>
					<p>
						<b>Código postal:</b> {this.state.currentUser.zipCode}
					</p>
					<p>
						<b>Empresa:</b> {this.state.currentUser.email}
					</p>
					<p>
						<b>Fecha de registro:</b>{' '}
						{moment(this.state.currentUser.createdAt).format(
							'DD-MMMM-YYYY',
						)}
					</p>
					<p>
						<b>Rol:</b> {this.state.currentUser.rol}
					</p>
					{/* <p>{JSON.stringify(this.state.currentUser)}</p> */}
					<Divider />
					{this.renderFormCustomerTypes()}
				</div>
			);
		}
	};

	render() {
		return (
			<div>
				<Grid>
					<Grid.Row columns={2}>
						<Grid.Column>
							<Header as="h2" icon="users" content="Clientes" />
						</Grid.Column>
						<Grid.Column>
							<Button
								icon="refresh"
								color="grey"
								floated="right"
								onClick={() =>
									this.getUsers(this.props.users.store.page)
								}
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<Divider />
				<Grid>
					<Grid.Row>
						<Grid.Column>
							<Grid.Column>
								<Form>
									<Form.Group>
										<Form.Field>
											<label>Buscar por Nombres</label>
											<Search
												icon=""
												loading={
													this.state.clientLoading
												}
												value={this.state.client}
												results={
													this.state.clientResults
												}
												onResultSelect={
													this
														.handleClientResultSelect
												}
												onSearchChange={
													this
														.handleClientSearchChange
												}
												resultRenderer={
													renderClientSearchResults
												}
												placeholder="Cliente"
												className="full-width"
											/>
										</Form.Field>
										<Form.Field>
											<label>Buscar</label>
											<Button
												icon="search"
												color="red"
												onClick={
													this._handleSearchButton
												}
											/>
										</Form.Field>
										<Form.Field>
											<label>Limpiar</label>
											<Button
												icon="leaf"
												color="red"
												onClick={
													this._handleCleanButton
												}
											/>
										</Form.Field>
									</Form.Group>
								</Form>
							</Grid.Column>
						</Grid.Column>
					</Grid.Row>
				</Grid>

				<Grid>
					<Grid.Row>
						<Grid.Column>
							<Segment
								raised
								loading={this.props.users.isLoading}
							>
								<Table striped>
									<Table.Header>
										<Table.Row>
											<Table.HeaderCell>
												Nombre
											</Table.HeaderCell>
											<Table.HeaderCell>
												Apellido Paterno
											</Table.HeaderCell>
											<Table.HeaderCell>
												Apellido Materno
											</Table.HeaderCell>
											<Table.HeaderCell>
												Email
											</Table.HeaderCell>
											<Table.HeaderCell>
												Código postal
											</Table.HeaderCell>
											<Table.HeaderCell>
												Empresa
											</Table.HeaderCell>
											<Table.HeaderCell>
												Fecha de registro
											</Table.HeaderCell>
											<Table.HeaderCell>
												Última IP
											</Table.HeaderCell>
											<Table.HeaderCell>
												Rol
											</Table.HeaderCell>
											<Table.HeaderCell>
												Registrado por
											</Table.HeaderCell>

											<Table.HeaderCell></Table.HeaderCell>
										</Table.Row>
									</Table.Header>
									<Table.Body>
										{this.renderTableBody()}
									</Table.Body>
								</Table>
							</Segment>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row columns={1}>
						<Grid.Column textAlign="center">
							{this.renderPagination()}
						</Grid.Column>
					</Grid.Row>
				</Grid>

				<Modal
					size="tiny"
					closeIcon={true}
					open={this.state.eventsModal}
					onClose={() => this.setState({ eventsModal: false })}
				>
					<Modal.Header>
						Eventos de{' '}
						{this.state.currentUser
							? `${this.state.currentUser.name}  ${this.state.currentUser.lastName}`
							: ''}
					</Modal.Header>
					<Modal.Content scrolling>
						{this.renderEvents()}
					</Modal.Content>
				</Modal>

				<Modal
					size="mini"
					closeIcon={true}
					open={this.state.rolModal}
					onClose={() => this.setState({ rolModal: false })}
				>
					<Modal.Header>
						{this.state.currentUser
							? `${this.state.currentUser.name}  ${this.state.currentUser.lastName}`
							: ''}
					</Modal.Header>
					<Modal.Content scrolling>
						{this.renderChangeModalBody()}
					</Modal.Content>
				</Modal>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	users: state.services.users,
	router: state.router,
	eventUsers: state.services.eventusers,
});

const mapDispatchToProps = (dispatch, { services }) => ({
	find: (query = {}) => dispatch(services.users.find(query)),
	store: (data) => dispatch(services.users.store(data)),
	push: (location) => dispatch(push(location)),
	findEventUsers: (query = {}) => dispatch(services.eventusers.find(query)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerContainer);

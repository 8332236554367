import React from 'react'
import { Grid, Divider, Button } from 'semantic-ui-react'
import { SortableElement, SortableContainer } from 'react-sortable-hoc';

const QuestionItem = SortableElement(({
    position, value, onItemRemove, onItemEdit, 
}) => (
    <div>
        <div>
            <Grid>
                <Grid.Row columns={2}>
                    <Grid.Column>
                        {`${position + 1} - ${value.title}`}
                    </Grid.Column>
                    <Grid.Column>
                        <Button size="mini" compact floated="right" negative type="button" icon="delete" onClick={() => onItemRemove(position)} />
                        <Button size="mini" compact floated="right" primary type="button" icon="pencil" onClick={() => onItemEdit(position)} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <div>
                <Grid>
                    <Grid.Row columns={value.answers.length}>
                        {value.answers.map((answer, index) => (
                            <Grid.Column key={index}>
                                <div>
                                    <div style={{
                                        backgroundColor: '#e8e8e8',
                                        borderRadius: 3,
                                        padding: '5px 10px',
                                        fontFamily: 'Lato',
                                        fontSize: 12,
                                        fontWeight: 700,
                                        color: 'rgba(0,0,0,.6)',
                                        display: 'flex',
                                        border: value.correctAnswer === index ? '3px solid #08cb08' : 0,
                                        margin: value.correctAnswer !== index ? '3px 0 0' : 0,
                                    }}
                                    >
                                        <div style={{ flex: 1, marginRight: 5 }}>
                                            <span>{answer}</span>
                                        </div>
                                    </div>
                                    {value.correctAnswer === index && (
                                        <div style={{ textAlign: 'center' }}>
                                            <span style={{ color: '#08cb08', fontWeight: 700 }}>Correcta</span>
                                        </div>
                                    )}
                                </div>
                            </Grid.Column>
                        ))}
                    </Grid.Row>
                </Grid>
            </div>

            <Divider />
        </div>
    </div>
    
))

const QuestionsContainer = SortableContainer(({ items, onItemRemove, onItemEdit }) => (
    <div>
        {items.map((value, index) => (
            <QuestionItem 
                onItemRemove={onItemRemove} 
                key={`item-${index}`} 
                index={index} 
                position={index} 
                value={value} 
                onItemEdit={onItemEdit}
            />
        ))}
    </div>
));
  
export { QuestionItem, QuestionsContainer }

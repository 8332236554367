import React, { Component } from 'react'
import { Modal, Button, Form, Label, Icon} from 'semantic-ui-react'
import feathersClient from '../redux/FeathersRedux'


const receiver = [
    { key: 'praxair1', text: 'olivia_alvarez@praxair.com - Praxair', value: 'olivia_alvarez@praxair.com'},
    { key: 'praxair2', text: 'ignacio_valdez@praxair.com - Praxair', value: 'ignacio_valdez@praxair.com'},
    { key: 'praxair3', text: 'erika_acosta_rivera@praxair.com - Praxair', value: 'erika_acosta_rivera@praxair.com'},
    { key: 'innovatelia', text: 'contacto@innovatelia.com - Innovatelia', value: 'contacto@innovatelia.com'},
    { key: 'infra', text: 'raulruiz@infra.com.mx - Infra', value: 'raulruiz@infra.com.mx'},
    { key: 'diaemex', text: 'luz.herrera06@hotmail.com - Diaemex', value: 'luz.herrera06@hotmail.com'},
    { key: 'lagacero1', text: 'g.proyectos@lagacerogroup.com - Lagacero', value: 'g.proyectos@lagacerogroup.com'},
    { key: 'lagacero2', text: 'direccion@lagacerogroup.com - Lagacero', value: 'direccion@lagacerogroup.com'},
]




export default class QuotationMailModal extends Component {
    
    state = {
        receivers: [
            'contacto@innovatelia.com',
            'olivia_alvarez@praxair.com',
            'ignacio_valdez@praxair.com',
            'erika_acosta_rivera@praxair.com',
            'raulruiz@infra.com.mx',
            'luz.herrera06@hotmail.com',
            'g.proyectos@lagacerogroup.com',
            'direccion@lagacerogroup.com'
        ],
        cc: [],
        currentcc: '',
        message: ``,
    }



    componentDidUpdate(prevProps, prevState){
         if(this.state.message === '' || prevProps.message !== this.props.message){
             this.setState({
               message: this.props.message  
             })
         }
    }

    shouldComponentUpdate(nextProps, nextState){
        if(this.props.open !== nextProps.open) return true
        if(this.state.message !== nextState.message) return true
        if(this.state.cc.length !== nextState.cc.length) return true
        if(this.state.currentcc !== nextState.currentcc) return true
        if(this.state.receivers !== nextState.receivers) return true
        return false
    }

    addCc = _ => {
        let cc = [...this.state.cc]
        if(cc.indexOf(this.state.currentcc) === -1){
            cc.push(this.state.currentcc)
        }
        this.setState({
            cc,
            currentcc: ''
        })
    }

    deleteCc = email => {
        let cc = [...this.state.cc]

        let position = cc.indexOf(email)

        cc.splice(position, 1)

        this.setState({
            cc
        })

    }

    send = async _ => {

        let { message } = this.state;
        let default_mails = [...this.state.receivers]
        default_mails = default_mails.join(';')

        let cc = [...this.state.cc]

        let mail_list = default_mails

        if(cc.length !== 0){
            cc = cc.join(';')
    
            mail_list = default_mails.concat( default_mails.length > 0 ? ";" : "",cc)
        }

        let mail_params = {
            mail_list,
            message
        }

        if(mail_list.length > 10){
            let mail_sender = await feathersClient.service('mail_sender').create(mail_params)

        }


        


        await feathersClient.service('quotations').patch(this.props.quotation._id, {
            sended: 'Enviada'
        })


        this.props.onMailSended && this.props.onMailSended()

        await this.setState(
            {
                receivers: [
                    'contacto@innovatelia.com',
                    'olivia_alvarez@praxair.com',
                    'ignacio_valdez@praxair.com',
                    'erika_acosta_rivera@praxair.com',
                    'raulruiz@infra.com.mx',
                    'luz.herrera06@hotmail.com',
                    'g.proyectos@lagacerogroup.com',
                    'direccion@lagacerogroup.com'
                ],
                cc: [],
                currentcc: '',
                message: '',
            }
        )

        this.props.onClose && this.props.onClose()


    }


    closeModal = async _ => {
        this.setState(
            {
                receivers: [
                    'contacto@innovatelia.com',
                    'olivia_alvarez@praxair.com',
                    'ignacio_valdez@praxair.com',
                    'erika_acosta_rivera@praxair.com',
                    'raulruiz@infra.com.mx',
                    'luz.herrera06@hotmail.com',
                    'g.proyectos@lagacerogroup.com',
                    'direccion@lagacerogroup.com'
                ],
                cc: [],
                currentcc: '',
                message: '',
            }, _ => {
                this.props.onClose()
            }
        )
    }

    renderCCEmails = _ => {
        return this.state.cc.map( (v, i) => {
            return (
                <Label>
                    {v}
                    <Icon name='delete' onClick={_ => this.deleteCc(v)} />
                </Label>
            )
        })
    }
    
    render() {
        return (
            <Modal open={this.props.open} size="large" onClose={this.closeModal}>
                <Modal.Header>{'Envio de Cotización'}</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Group widths={'equal'}>
                            <Form.Field>
                                <label>Selecciona los destinatarios</label>
                                <Form.Dropdown placeholder={"Emails"} fluid multiple value={this.state.receivers} selection options={receiver} onChange={(e, d) => {
                                    this.setState({
                                        receivers: d.value
                                    })
                                }} />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths={'equal'}>
                            <Form.Field>
                                <label>C.c.</label>
                                <Form.Input placeholder={"C.c."} value={this.state.currentcc} fluid onChange={ (e, d) => {
                                    this.setState({
                                        currentcc: d.value
                                    })
                                }}/>
                            </Form.Field>
                            <Form.Field>
                                <label>Añadir</label>
                                <Form.Button color={"green"} icon={"add"} circular onClick={this.addCc} />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group>
                            <Form.Field>
                                <div style={{
                                    display: 'flex',
                                    flex: 1,
                                    flexWrap: 'wrap'
                                }}>
                                    <this.renderCCEmails />
                                </div>
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths={'equal'}>
                                <Form.Field>
                                    <label>Mensaje</label>
                                    <Form.TextArea value={this.state.message} style={{
                                        minHeight: 200
                                    }}  onChange={(e, d) => {
                                        this.setState({
                                            message: d.value
                                        })
                                    }} />
                                </Form.Field>
                        </Form.Group>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.closeModal} negative inverted >Cerrar</Button>
                    <Button onClick={this.send} positive inverted >Enviar</Button>
                </Modal.Actions>
            </Modal>
        )
    }
}

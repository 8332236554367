import React, { Component } from 'react';
import { Grid, Header, Divider, Button, Segment, Form, Dropdown, TextArea, Image, Container, Modal } from 'semantic-ui-react'
import { connect } from 'react-redux'
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import moment from 'moment'
import 'moment/locale/es';
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment';
import Dropzone from 'react-dropzone'
import DropzoneStyles from '../../components/Dropzone/DropzoneStyles'
import { ROWS_PER_PAGE } from './NewsData';
import NewsCategories from './NewsCategories';
  
const TYPES = [
	{ text: 'Imágen', value: 'normal' },
	{ text: 'Video', value: 'video' },
]

const REGEX = /^.*(?:youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#\&\?]*).*/;

class NewsCreate extends Component {
	constructor(props) {
		super(props)

		this.state = {
			title: '',
			content: '',
			type: null,
			date: null,
			image: null,
			categories: [],
			modalVisible: false,
			error: false,
		}
	}

	onImageDrop = (files, rejectedFiles) => {
		if (files.length > 0) {
			const file = files[0];
			const reader = new FileReader();
			reader.readAsDataURL(file);
	
			reader.onload = () => {
				// Make a fileInfo Object
				const image = {
					name: file.name,
					type: file.type,
					size: file.size,
					base64: reader.result,
					preview: file.preview,
				};
	
				this.setState({ image })
			}
		}
	}

	removeImage = (e, data) => {
		e.stopPropagation();
		this.setState({
			image: null,
		})
	}

	renderDropContent = () => {
		if (!this.state.image) {
			return <p>Arrastra o haz clic para subir una imágen.</p>
		}

		return (
			<div style={{ position: 'relative' }}>
				<Image src={this.state.image.preview} style={{ height: 130, width: 'auto' }} />
				<Button negative circular icon="close" onClick={this.removeImage} style={{ position: 'absolute', top: 0, right: 0 }} />
			</div>
		)
	}

	renderVideoPreview = () => {
		if (this.state.url && this.state.url.length) {
			const groups = this.state.url.match(REGEX);

			if (groups && groups[1]) {
				return (
					<Container fluid textAlign="center">
						<h4>Vista previa</h4>
						<Image centered size="small" rounded src={`https://img.youtube.com/vi/${groups[1]}/0.jpg`} />
					</Container>
				)
			}
		}
	}

	updateUrl = (e, { value }) => {
		this.setState({
			url: value, 
			error: value && !REGEX.test(value),
		})
	}

	renderUpload = () => {
		if (this.state.type === 'normal') {
			return (
				<Form.Field>
					<label>Imágen</label>
					<Dropzone
						multiple={false}
						accept="image/*"
						onDrop={this.onImageDrop}
						style={DropzoneStyles.dropZoneStyle}
						acceptStyle={DropzoneStyles.dropZoneAcceptStyle}
						rejectStyle={DropzoneStyles.dropZoneRejectStyle}
					>
						{this.renderDropContent()}
					</Dropzone>
				</Form.Field>
			)
		} else if (this.state.type === 'video') {
			return (
				<div>
				<Form.Input error={this.state.error} required value={this.state.url} label="URL de video de YouTube" type="text" placeholder="Link al video de YouTube" onChange={this.updateUrl} />
					{this.renderVideoPreview()}
				</div>
			)
		}

		return null;
	}

	save = () => {
		if (this.state.type === 'video' && this.state.error) return;
		if (this.props.news.isSaving) return;

		const data = { ...this.state };

		data.date = moment(data.date).hour(0).minute(0).second(0)
			.toDate()

		if (data.type === 'normal' && data.image) {
			data.url = data.image.base64;
		}

		this.props.onCreate(data)
			.then(() => {
				this.setState({ modalVisible: true })
				this.props.find(this.props.news.store ? this.props.news.store.page : 1)
			})
	}

	closeModal = () => {
		this.setState({
			modalVisible: false, title: '', content: '', type: null, image: null, url: null, date: '', categories: [],
		})
	}

	onCategoriesChange = (categories) => {
		this.setState({
			categories,
		})
	}

	render() {
		return (
			<div>
				<Grid>
					<Grid.Row columns={1}>
						<Grid.Column>
							<Header as="h2" icon="newspaper" content="Noticias > Nueva" />      
						</Grid.Column>
						{/* <Grid.Column>
							<Button icon='plus' primary floated="right" content="Nueva" onClick={() => this.props.push('/news/create')} />
						</Grid.Column> */}
					</Grid.Row>
				</Grid>
				<Divider />

				<Segment loading={this.props.news.isSaving}>
					<Form>
						<Grid>
							<Grid.Row columns={2}>
								<Grid.Column>

									<Form.Field required label="Tipo de noticia" value={this.state.type} control={Dropdown} placeholder="Tipo de noticia" selection options={TYPES} onChange={(e, { value }) => this.setState({ type: value })} />
									<Form.Input required value={this.state.title} label="Título" type="text" placeholder="Título de la noticia" onChange={(e, { value }) => this.setState({ title: value })} />
									<Form.Input required value={this.state.content} label={`Contenido (${this.state.content.length}/1400)`} control={TextArea} placeholder="Contenido de la noticia" rows={3} maxLength="1400" onChange={(e, { value }) => this.setState({ content: value })} />
									<Form.Field required>
										<label>Fecha</label>
										<DayPickerInput
											format="DD-MM-YYYY"
											value={this.state.date}
											formatDate={formatDate}
											parseDate={parseDate}
											placeholder="Fecha a partir de la cual la noticia estará visible"
											onDayChange={date => this.setState({ date })}
											dayPickerProps={{
												locale: 'es',
												localeUtils: MomentLocaleUtils,
												disabledDays: { before: new Date() },
											}}
										/>
									</Form.Field>
									{this.renderUpload()}
								</Grid.Column>
								<Grid.Column>

									<NewsCategories categories={this.state.categories} onChange={this.onCategoriesChange} />

								</Grid.Column>
							</Grid.Row>

						</Grid>

						<Divider />
						<Grid>
							<Grid.Row>
								<Grid.Column floated="right" computer={16} largeScreen={3} widescreen={3} tablet={16} mobile={16}>
									<Button onClick={this.save} type="button" positive fluid icon="plus" content="CREAR" labelPosition="left" />
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Form>
				</Segment>

				<Modal open={this.state.modalVisible} basic size="small">
					<Header size="huge" icon="checkmark" content="Éxito" />
					<Modal.Content>
						<p style={{ fontSize: 20 }}>La noticia fue creada satisfactoriamente</p>
					</Modal.Content>
					<Modal.Actions>
						<Button color="green" inverted content="OK" onClick={this.closeModal} />
					</Modal.Actions>
				</Modal>
			</div>
		)
	}
}

const mapStateToProps = state => ({
	news: state.services.news,
});

const mapDispatchToProps = (dispatch, { services }) => ({
	onCreate: data => dispatch(services.news.create(data)),
	find: page => dispatch(services.news.find({
		query: {
			$skip: (page - 1) * ROWS_PER_PAGE,
			$limit: ROWS_PER_PAGE,
			$sort: {
				createdAt: -1,
			},
		},
	})),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewsCreate)

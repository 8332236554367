export const TYPES = [
	{ value: 'Fronius Friday', text: 'Fronius Friday' },
	// { value: 'Experience Day', text: 'Experience Day'},
];

export const VISIBILITIES = [
	{
		value: 'public',
		text: 'Público',
	},
	{
		value: 'staff',
		text: 'Staff',
	},
];

export const LOCATIONS = [
	{ value: 'Puebla', text: 'Puebla' },
	{ value: 'MTY', text: 'Monterrey' },
	{ value: 'SLP', text: 'San Luis Potosí' },
	{ value: 'CDMX', text: 'CDMX' },
];

export const ROWS_PER_PAGE = 10;

export const TYPES = [
    {
        value: 'news',
        text: 'Noticia'
    }, {
        value: 'event',
        text: 'Evento'
    }, {
        value: 'quiz',
        text : 'Quiz'
    }, {
        value: 'product',
        text: 'Productos'
    }, {
        value: 'usedProduct',
        text: 'Productos Usados'
    }
]
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Grid, Header, Divider, Button, Segment, Form, Dropdown, TextArea, Responsive, Container } from 'semantic-ui-react'
import CurrencyInput from 'react-currency-masked-input'
import { replace } from 'react-router-redux'
import AlertModal from '../../components/AlertModal'
import ConfirmModal from '../../components/ConfirmModal'
import { PROCESSES, ROWS_PER_PAGE, DECISION } from './ProductData'
import { Dropzone } from '../../components'
import Config from '../../config'

class ProductEdit extends Component {
    state = {
        sku: '',
        name: '',
        serialNumber: '',
        version: '',
        multivoltage: null,
        amperage: '',
        processes: [],
        price: '',
        description: '',
        accessories: '',
        modalVisible: false,
        error: false,
    }

    componentDidMount() {
        const { id } = this.props.match.params;

        this.props.get(id)
            .then(() => {
                const state = {
                    ...this.state,
                    ...this.props.products.data,
                }

                state.image = {
                    preview: Config.apiUrl + state.image
                }

                this.setState(state)
            })
    }

    onDropChange = (image) => {
        this.setState({ image })
    }

    save = () => {
        if (this.props.products.isSaving) return;

        if (!this.state.price) {
            this.setState({ error: true })
            return;
        }

        const data = { ...this.state }

        if (data.image && data.image.base64) {
            data.image = data.image.base64;
        } else {
            delete data.image
        }

        delete data.modalVisible;
        delete data.error;

        this.props.patch(this.props.products.data._id, data)
        .then(() => {
            this.setState({ modalVisible: true })
            this.props.find(this.props.products.store ? this.props.products.store.page : 1)
        })
    }

    remove = () => {
        this.props.patch(this.props.products.data._id, {
            active: false,
        })
            .then(() => {
                this.props.find(this.props.products.store ? this.props.products.store.page : 1)
                this.props.replace('/product')
            })
    }

    render() {
        return (
            <div>
                <Grid>
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <Header as="h2" icon="cubes" content={`Productos usados > ${this.props.products.data ? this.props.products.data.name : ''}` } />      
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Divider />

                <Segment>
                    <Form onSubmit={this.save} loading={this.props.products.isLoading}>
                        <Form.Group widths="equal">
                            <Form.Input required label="SKU" type="text" placeholder="SKU" value={this.state.sku} name="sku" onChange={(e, {name, value}) => this.setState({[name]: value})} />
                            <Form.Input required label="Nombre" type="text" placeholder="Nombre" value={this.state.name} name="name"  onChange={(e, {name, value}) => this.setState({[name]: value})} />
                            <Form.Input required label="No. Serie" type="text" placeholder="No. Serie" value={this.state.serialNumber} name="serialNumber" onChange={(e, {name, value}) => this.setState({[name]: value})} />
                            <Form.Input label="Versión" type="text" placeholder="Versión" value={this.state.version} name="version"  onChange={(e, {name, value}) => this.setState({[name]: value})} />
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Field required label="Multivoltaje" control={Dropdown} placeholder="Multivoltaje" value={this.state.multivoltage} name="multivoltage" selection options={DECISION} onChange={(e, {name, value}) => this.setState({[name]: value})} />
                            <Form.Input required label="Amperaje" type="text" placeholder="Amperaje" value={this.state.amperage} name="amperage" onChange={(e, {name, value}) => this.setState({[name]: value})} />
                            <Form.Input required label="Procesos" control={Dropdown} placeholder="Procesos" value={this.state.processes} multiple name="processes" selection options={PROCESSES} onChange={(e, {name, value}) => this.setState({[name]: value})} />
                            <Form.Field required error={this.state.error}>
                                <label>Precio</label>
                                <CurrencyInput placeholder="Precio" value={this.state.price} name="price" onChange={(e, price) => this.setState({ price, error: false })} />
                            </Form.Field>
                        </Form.Group>
                        <Form.Input required value={this.state.description} label="Descripción" control={TextArea} placeholder="Descripción" name="description" rows={3} maxlength="500" onChange={(e, {name, value}) => this.setState({ [name]: value})} />
                        <Form.Input value={this.state.accessories} label="Accesorios" control={TextArea} placeholder="Accesorios" name="accessories" rows={3} maxlength="500" onChange={(e, {name, value}) => this.setState({ [name]: value})} />
                        <Form.Field>
                            <label>Imágen</label>
                            <Dropzone onChange={this.onDropChange} image={this.state.image} />
                        </Form.Field>
                        
                        <Divider />
                        <Responsive minWidth={Responsive.onlyComputer.minWidth}>
                            <Container fluid textAlign="right">
                                <Button type="button" inverted icon="delete" color="red" content="ELIMINAR" style={{marginRight: 20}} onClick={() => this.setState({confirmModal: true})} />
                                <Button type="submit" positive icon="pencil" content="EDITAR" labelPosition="left"/>
                            </Container>
                        </Responsive>

                        <Responsive maxWidth={Responsive.onlyTablet.maxWidth}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column tablet={16} mobile={16} style={{ marginBottom: 10 }}>
                                        <Button fluid type="button" inverted icon="delete" color="red" content="ELIMINAR" onClick={() => this.setState({confirmModal: true})} />
                                    </Grid.Column>
                                    <Grid.Column tablet={16} mobile={16}>
                                        <Button type="submit" positive fluid icon="pencil" content="EDITAR" labelPosition='left'/>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Responsive>
                    </Form>
                </Segment>

                <AlertModal 
                    open={this.state.modalVisible} 
                    title="Éxito" 
                    message="El producto fue editado satisfactoriamente" 
                    onClose={() => this.setState({ modalVisible: false })} 
                />
                
                <ConfirmModal
                    open={this.state.confirmModal}
                    title="¿Estás seguro?"
                    message="El producto no podrá verse nunca más"
                    onClose={() => this.setState({ confirmModal: false })}
                    onAccepted={this.remove}
                    onRejected={() => this.setState({ confirmModal: false })}
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    products: state.services.products,
})

const mapDispatchToProps = (dispatch, { services }) => ({
    get: id => dispatch(services.products.get(id)),
    patch: (id, data) => dispatch(services.products.patch(id, data)),
    replace: path => dispatch(replace(path)),
    find: page => dispatch(services.products.find({
        query: {
            $skip: (page - 1) * ROWS_PER_PAGE,
            $limit: ROWS_PER_PAGE,
            type: 'used',
        },
    })),  
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductEdit)
import React, {Component} from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import {Button, Header, Modal, Pagination, Segment, Grid, Divider, Table, Form, Checkbox, Label} from 'semantic-ui-react'
import DayPicker from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import moment from 'moment'
import 'moment/locale/es';
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment';
import { ROWS_PER_PAGE } from './QuotationData'
import QuotationMailModal from '../../components/QuotationMailModal'

class QuotationContainer extends Component {

    state = {
        currentQuotation: null,
        filter: {
            initDay: '',
            lastDay: ''
        },
        isVisibleQuotationMailModal: false,
        quotation: null, // pongo esta porque no quiero destruir la estructura que ya se tiene con currentQuotation.
        message: ``
    }

    componentDidMount() {
        if (!this.props.quotations.store) {
            this.getQuotations(1)
        }
    }

    QuotationMailModalOnOpen = _ => {
        this.setState({
            isVisibleQuotationMailModal: true
        })
    }

    QuotationMailModalOnClose = _ => {
        this.setState({
            message: '',
            isVisibleQuotationMailModal: false
        })
    }

    getQuotations = (page) => {
        this.props.store({page})

        

        setTimeout(() => {
            
            let query = {
                $skip: (this.props.quotations.store.page - 1) * ROWS_PER_PAGE,
                $limit: ROWS_PER_PAGE,
                $sort: {
                    createdAt: -1
                },
                $populate: [{ path: 'user', select: ['name', 'lastName'] }, { path: 'product', select: ['sku', 'name', 'version']}, { path: 'staff', select: ['name', 'lastName'] }]
            }

            if(this.state.filter.initDay !== ''){

                query['createdAt'] = {
                    $gte: moment(this.state.filter.initDay).hour(0).minute(1).second(1).format("YYYY-MM-DD HH:mm:ss")
                }
            }

            if(this.state.filter.lastDay !== ''){
                if(this.state.filter.initDay !== ''){
                    query['createdAt'] = {
                        ...query.createdAt,
                        $lte: moment(this.state.filter.lastDay).hour(23).minute(57).second(59).format("YYYY-MM-DD HH:mm:ss")
                    }
                }else{
                    query['createdAt'] = {
                        $lte: moment(this.state.filter.lastDay).hour(23).minute(57).second(59).format("YYYY-MM-DD HH:mm:ss")
                    }
                }
            }

            this.props.find({
                query
            })
        }, 50)
    }

    _handleFilterButton = () => {
        this.getQuotations(this.props.quotations.store.page)
    }

    onMailSended = _ => {
        this.getQuotations(this.props.quotations.store.page)
    }

    _handleCleanButton = async () => {
        await this.setState({
            filter: {
                ...this.state.filter,
                initDay: '',
                lastDay: '',
            }
        })
        
        this.getQuotations(this.props.quotations.store.page)
    }

    handleCheckboxOnChange = async quotation => {
        if(quotation.sended !== 'Enviada'){

    
            let message = `
            Información del Cliente: 
            Nombre: ${quotation.user.name + " " + quotation.user.lastName}  
            Email: ${quotation.email ? quotation.email : '---'}
            Teléfono: ${quotation.telephone ? quotation.telephone : ''}
            Comentarios: ${quotation.comments ? quotation.comments : ''}
            Fecha de Creación: ${moment(quotation.createdAt).format('YYYY-MM-DD')}
            SKU: ${quotation.product ? quotation.product.sku : quotation.sku}
            Producto: ${quotation.product ? quotation.product.name : quotation.productName}
            `

            await this.setState({
                quotation,
                message
            })

            this.QuotationMailModalOnOpen()

            // await this.props.patch(quotation._id, {
            //     sended: quotation.sended === 'Enviada' ? 'Pendiente' : 'Enviada'
            // })
            // this.getQuotations(this.props.quotations.store.page)
        }
    }

    renderTableBody = () => {
        
        return this.props.quotations.queryResult.data.map(quotation => {
            /**
             * Las quotation que tienen el atributo productNAme son productos nuevos, las
             * quotation que tienen un atributo product  y este es un objeto, son productos usados
             */

            return (
                <Table.Row key={quotation._id}>
                    <Table.Cell>{quotation.user ?`${quotation.user.name} ${quotation.user.lastName}` : '---'}</Table.Cell>
                    <Table.Cell>{quotation.product ? quotation.product.name : quotation.productName}</Table.Cell>
                    <Table.Cell>{quotation.product ? quotation.product.sku : quotation.sku}</Table.Cell>
                    <Table.Cell>{quotation.email}</Table.Cell>
                    <Table.Cell>{quotation.telephone}</Table.Cell>
                    <Table.Cell>{moment(quotation.createdAt).format('DD-MMMM-YYYY')}</Table.Cell>
                    <Table.Cell>
                        {quotation.comments && (
                            <Button content="Ver comentarios" size="mini" onClick={() => this.setState({ currentQuotation: quotation})}/>
                        )}
                    </Table.Cell>
                    <Table.Cell>
                        {
                            quotation.promo  ? quotation.promo : "---"
                        }
                    </Table.Cell>
                    <Table.Cell>
                        {quotation.staff ? quotation.staff.name + " " + quotation.staff.lastName : "---"}
                    </Table.Cell>
                    <Table.Cell>
                            <Checkbox label={quotation.sended === 'Enviada' ? 'Email Enviado' : 'Enviar Email'} checked={quotation.sended === 'Enviada'} onChange={_ => this.handleCheckboxOnChange(quotation)} />
                    </Table.Cell>
                    <Table.Cell>
                        {quotation.productName ? (
                            <Label ribbon={"right"} content={"P"} color={'green'} />
                        ) : (
                            <Label ribbon={"right"} content={"PU"} color={'blue'} />
                        )}
                    </Table.Cell>
                </Table.Row>
            )
        }
        )
    }

    renderPagination = () => {
        if (!this.props.quotations.store || this.props.quotations.queryResult.total === 0) return null

        return (
            <Pagination 
                activePage={this.props.quotations.store.page} 
                totalPages={Math.ceil(this.props.quotations.queryResult.total / ROWS_PER_PAGE ) } 
                onPageChange={(e, {activePage}) => this.getQuotations(activePage)}
            />
        )
    }

    render() {
        return (
            <div>
                <Grid>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <Header as='h2' icon='list' content='Cotizaciones' />      
                        </Grid.Column>
                        <Grid.Column>
                            <Button icon='refresh' color="grey" floated="right" onClick={() => this.getQuotations(this.props.quotations.store.page)} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Divider/>

                <Grid>
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <Form>
                                <Form.Group>
                                    <Form.Field>
                                        <label>Fecha Inicio</label>
                                        <DayPickerInput value={this.state.filter.initDay} format="YYYY-MM-DD" formatDate={formatDate} parseDate={parseDate}  onDayChange={date => {
                                            this.setState({
                                                filter: {
                                                    ...this.state.filter,
                                                    initDay: date
                                                }
                                            })
                                        }} />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Fecha Fin</label>
                                    
                                        <DayPickerInput value={this.state.filter.lastDay} format="YYYY-MM-DD" formatDate={formatDate} parseDate={parseDate}  onDayChange={date => {
                                            this.setState({
                                                filter: {
                                                    ...this.state.filter,
                                                    lastDay: date
                                                }
                                            })
                                        }} />
                                    </Form.Field>
                                    <Form.Field>
                                            <label>Filtrar</label>
                                            <Button circular icon="filter" onClick={this._handleFilterButton} />
                                    </Form.Field>
                                    <Form.Field>
                                            <label>Limpiar</label>
                                            <Button circular icon="x" onClick={this._handleCleanButton}  />
                                    </Form.Field>
                                </Form.Group>
                            </Form>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                <Grid>
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <Segment raised loading={this.props.quotations.isLoading}>
                                <Table striped>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Usuario</Table.HeaderCell>
                                            <Table.HeaderCell>Producto</Table.HeaderCell>
                                            <Table.HeaderCell>SKU</Table.HeaderCell>
                                            <Table.HeaderCell>Email</Table.HeaderCell>
                                            <Table.HeaderCell>Teléfono</Table.HeaderCell>
                                            <Table.HeaderCell>Fecha</Table.HeaderCell>
                                            <Table.HeaderCell>Comentarios</Table.HeaderCell>
                                            <Table.HeaderCell>Promo</Table.HeaderCell>
                                            <Table.HeaderCell>Staff</Table.HeaderCell>
                                            <Table.HeaderCell></Table.HeaderCell>
                                            <Table.HeaderCell></Table.HeaderCell>

                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {this.renderTableBody()}
                                    </Table.Body>
                                </Table>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                        <Grid.Column textAlign="center">
                            {this.renderPagination()}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                <Modal size="small" open={!!this.state.currentQuotation} onClose={() => this.setState({ currentQuotation: null})}>
                    <Modal.Header>
                        Comentarios de la cotización
                    </Modal.Header>
                    <Modal.Content>
                        {this.state.currentQuotation && (
                            <p>{this.state.currentQuotation.comments}</p>
                        )}
                    </Modal.Content>
                    <Modal.Actions>
                        <Button primary content='OK' onClick={() => this.setState({ currentQuotation: null})} />
                    </Modal.Actions>
                </Modal>

                <QuotationMailModal open={this.state.isVisibleQuotationMailModal} onClose={this.QuotationMailModalOnClose} onMailSended={this.onMailSended} quotation={this.state.quotation} message={this.state.message} />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    quotations: state.services.quotations,
    router: state.router,
});

const mapDispatchToProps = (dispatch, { services }) => ({
    find: (query = {}) => dispatch(services.quotations.find(query)),
    patch: (id, data) => dispatch(services.quotations.patch(id, data)),
    store: (data) => dispatch(services.quotations.store(data)),
    push: (location) => dispatch(push(location)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuotationContainer)
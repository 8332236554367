import React, { Component } from 'react'
import { Button, Header, Modal, Grid, Divider, Table } from 'semantic-ui-react'
import moment from 'moment'

export default class QuizResultsModal extends Component {

    close = () => {
        this.props.onClose && this.props.onClose()
    }


    renderQuizResults = _ => {
        return (
            <Table basic='very' size={'large'} celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Posición</Table.HeaderCell>
                        <Table.HeaderCell>Nombre</Table.HeaderCell>
                        <Table.HeaderCell>Duración</Table.HeaderCell>
                        <Table.HeaderCell>Inicio</Table.HeaderCell>
                        <Table.HeaderCell>Fin</Table.HeaderCell>
                        <Table.HeaderCell>Calificación</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {this.props.quizResults.data.map( (v, i) => {
                        return (
                            <Table.Row>
                                <Table.Cell>{i + 1}.</Table.Cell>
                                <Table.Cell>{v.user.name + " " + v.user.lastName}.</Table.Cell>
                                <Table.Cell>{v.duration} s.</Table.Cell>
                                <Table.Cell>{moment(v.initTime).format('YYYY-MM-DD HH:mm:ss')}</Table.Cell>
                                <Table.Cell>{moment(v.finishTime).format('YYYY-MM-DD HH:mm:ss')}</Table.Cell>
                                <Table.Cell>{v.grade}</Table.Cell>
                            </Table.Row>
                        )
                    })}
                </Table.Body>
            </Table>
        )
    }

    render() {
        return (
        <Modal open={this.props.open} onClose={this.close}>
            <Modal.Header>
                {this.props.currentQuiz ? this.props.currentQuiz.name : ''}
            </Modal.Header>
            <Modal.Content scrolling>
            <Grid>
                <Grid.Row columns={2}>
                    <Grid.Column>
                        <span style={{ fontWeight: 'bold', display: 'block' }}>
                            Descripción
                        </span>
                        <span>{this.props.currentQuiz && this.props.currentQuiz.description}</span>
                    </Grid.Column>
                    <Grid.Column>
                        <span style={{ fontWeight: 'bold', display: 'block' }}>
                            Total de Participantes
                        </span>
                        <span>{this.props.totalofusers && this.props.totalofusers}</span>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <span style={{ fontWeight: 'bold', display: 'block' }}>
                            Resultados
                        </span>
                            {this.props.quizResults && <this.renderQuizResults />}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            </Modal.Content>
        </Modal>
        )
    }
}

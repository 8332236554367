import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom'
import MainContainer from './containers/MainContainer'
import LoginContainer from './containers/LoginContainer'
import { userIsAuthenticatedRedir, userIsNotAuthenticatedRedir } from './Auth'
import { hot } from 'react-hot-loader'

const Login = userIsNotAuthenticatedRedir(LoginContainer);
const Main = userIsAuthenticatedRedir(MainContainer);

class App extends Component {
    render() {
        return (
            <div>
                <Switch>
                    <Route exact path="/login" component={Login} />
                    <Route path="/" component={Main} />
                </Switch>
            </div>
        )
    }
}


export default hot(module)(App)

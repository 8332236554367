import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import {
    Button,
    Header,
    Pagination,
    Segment,
    Grid,
    Divider,
    Table,
    Checkbox,
    // Modal,
} from 'semantic-ui-react'
// import moment from 'moment'
import { ROWS_PER_PAGE } from './CitiesData'
// import feathersClient from '../../redux/FeathersRedux'

class DistributorContainer extends Component {
    state = {
        // currentQuiz: null,
        // isResultsModalOpened: false,
        // isDetailsModalOpened: false,
        // quizResults: null,
    }

    componentDidMount() {
        if (!this.props.distributors.store) {
            this.getDistributors(1)
        }
    }

    getDistributors = (page) => {
        this.props.store({ page })

        setTimeout(() => {
            this.props.find({
                query: {
                    $skip: (this.props.distributors.store.page - 1) * ROWS_PER_PAGE,
                    $limit: ROWS_PER_PAGE,
                    $sort: {
                        createdAt: -1,
                    },
                },
            })
        }, 60)
    }

    handleActiveChange = (_id, checked) => {
        this.props.patch(_id, { active: checked ? 1 : 0 })
            .then((response) => {
                console.log('success');
                console.log(response);
                this.getDistributors(this.props.distributors.store.page);
            })
            .catch((err) => {
                console.log('err');
                console.log(err);
            });
    }

    renderTableBody = () => {
        if (this.props.distributors.queryResult == null) return
        // eslint-disable-next-line consistent-return
        return this.props.distributors.queryResult.data.map(distributor => (
            <Table.Row key={distributor._id}>
                <Table.Cell>{distributor.name}</Table.Cell>
                <Table.Cell>{distributor.sellerName}</Table.Cell>
                <Table.Cell>{distributor.phone}</Table.Cell>
                <Table.Cell><Checkbox toggle checked={distributor.active} onChange={(e, { checked }) => this.handleActiveChange(distributor._id, checked)} /></Table.Cell>
                <Table.Cell>
                    <Button color="black" size="mini" icon="pencil" content="Editar" onClick={() => this.props.push(`/distributor/${distributor._id}`)} />
                </Table.Cell>
            </Table.Row>
        ))
    }

    renderPagination = () => {
        if (this.props.distributors.queryResult == null) return null
        if (!this.props.distributors.store || this.props.distributors.queryResult.total === 0) return null
        return (
            <Pagination
                activePage={this.props.distributors.store.page}
                totalPages={Math.ceil(this.props.distributors.queryResult.total / ROWS_PER_PAGE)}
                onPageChange={(e, { activePage }) => this.getCities(activePage)}
            />
        )
    }

    render() {
        return (
            <div>
                <Grid>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <Header as="h2" icon="sitemap" content="Distribuidores" />
                        </Grid.Column>
                        <Grid.Column>
                            <Button color="red" icon="plus" floated="right" content="Nuevo" onClick={() => this.props.push('/distributor/create')} />
                            <Button icon="refresh" color="grey" floated="right" onClick={() => this.getDistributors(this.props.distributors.store.page)} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Divider />

                <Grid>
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <Segment raised loading={this.props.distributors.isLoading}>
                                <Table striped>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Nombre</Table.HeaderCell>
                                            <Table.HeaderCell>Vendedor/Técnico</Table.HeaderCell>
                                            <Table.HeaderCell>Telefono</Table.HeaderCell>
                                            <Table.HeaderCell>Active</Table.HeaderCell>
                                            <Table.HeaderCell></Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {this.renderTableBody()}
                                    </Table.Body>
                                </Table>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                        <Grid.Column textAlign="center">
                            {this.renderPagination()}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    cities: state.services.cities,
    distributors: state.services.distributors,
    router: state.router,
});

const mapDispatchToProps = (dispatch, { services }) => ({
    find: (query = {}) => dispatch(services.distributors.find(query)),
    patch: (id, data) => dispatch(services.distributors.patch(id, data)),
    store: data => dispatch(services.distributors.store(data)),
    push: location => dispatch(push(location)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DistributorContainer)

import feathers from '@feathersjs/client';
import io from 'socket.io-client';
import reduxifyAuthentication from 'feathers-reduxify-authentication';
import reduxifyServices from 'feathers-redux';
import { combineReducers } from 'redux';
import Config from '../config'

const socket = io(Config.apiUrl, { transports: ['websocket'] });
const app = feathers();

app.configure(feathers.socketio(socket));
app.configure(feathers.authentication({
    storage: window.localStorage,
    storageKey: Config.jwtKey,
}));

export const services = reduxifyServices(app, [
    'news',
    'events',
    'eventusers',
    'quotations',
    'products',
    'users',
    'notifications',
    'quizes',
    'demos',
    'cities',
    'distributors',
    'states',
    'banks'
]);

export default app;
export const feathersAuthentication = reduxifyAuthentication(app);

export const feathersServices = combineReducers({
    news: services.news.reducer,
    events: services.events.reducer,
    eventusers: services.eventusers.reducer,
    quotations: services.quotations.reducer,
    products: services.products.reducer,
    users: services.users.reducer,
    distributors: services.distributors.reducer,
    notifications: services.notifications.reducer,
    quizes: services.quizes.reducer,
    demos: services.demos.reducer,
    cities: services.cities.reducer,
    states: services.states.reducer,
    banks: services.banks.reducer
});


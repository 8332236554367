import React, { Component } from 'react'
import {
    Header, Divider, Input, Label, Icon, Form,
} from 'semantic-ui-react'

class NewsCategories extends Component {
    state = {
        value: '',
    }

    deleteCategory = (index) => {
        const categories = JSON.parse(JSON.stringify(this.props.categories))

        categories.splice(index, 1);

        this.props.onChange(categories)
    }

    onSubmit = () => {
        let category = this.state.value

        if (!category) return

        category = category.toLowerCase()

        const categories = [...this.props.categories]

		const find = categories.find(c => c === category)

        this.setState({ value: '' })

		if (find) return

        this.props.onChange && this.props.onChange([...this.props.categories, category])
    }

    onChange = (e, { value }) => {
        this.setState({
            value,
        })
    }

    render() {
        return (
            <div>
                <Header as="h3" content="Etiquetas" />
                <Divider />

                <Form onSubmit={this.onSubmit}>
                    <Form.Input value={this.state.value} onChange={this.onChange} fluid placeholder="Escribe una etiqueta..." />
                </Form>
                
                <div style={{ marginTop: 10, marginBottom: 10 }}>
                    {this.props.categories.map((category, i) => (
                        <Label key={category} size="large" style={{ marginBottom: 5 }}>
                            {category}
                            <Icon onClick={() => this.deleteCategory(i)} name="delete" />
                        </Label>
                    ))}
                </div>
                
                
            </div>
        )
    }
}

export default NewsCategories

import React, { Component } from 'react'
import { Button, Header, Modal, Grid, Divider, Table, Pagination } from 'semantic-ui-react'
import moment from 'moment'
import feathersClient from '../../redux/FeathersRedux'

class QuizesDetailsModal extends Component {

    state = {
        firstLoaded: true,
        users: [],
        total: 0,
        page: 1,
        pageSize: 5,
    }

    close = () => {
        this.props.onClose && this.props.onClose()
    }

    shouldComponentUpdate(nextProps, nextState){
        if (JSON.stringify(this.props) !== JSON.stringify(nextProps)) return true
        if (this.state.users.length !== nextState.users.length) return true
        if (JSON.stringify(this.state.users) !== JSON.stringify(nextState.users)) return true
        if (this.state.page !== nextState.page) return true
        return false
    }

    componentDidUpdate() {
        if(this.state.firstLoaded === true){
            this.fetchUsers(1)
        }
    }

    render_participants_table = () => {
        if (this.state.users.length > 0){
            return (
                <Table striped >
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Nombre</Table.HeaderCell>
                            <Table.HeaderCell>Email</Table.HeaderCell>
                            <Table.HeaderCell>Respuestas correctas</Table.HeaderCell>
                            <Table.HeaderCell>Duración</Table.HeaderCell>
                            <Table.HeaderCell>Calificación</Table.HeaderCell>


                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        { this.state.users.map((v, i) => {
                            return (
                                <Table.Row key={i}>
                                    <Table.Cell>{v.user.name + " " + v.user.lastName}</Table.Cell>
                                    <Table.Cell>{v.user.email}</Table.Cell>
                                    <Table.Cell>{v.correctAnswers}</Table.Cell>
                                    <Table.Cell>{v.duration}</Table.Cell>
                                    <Table.Cell>{v.grade}</Table.Cell>



                                </Table.Row>
                            )
                        })}
                    </Table.Body>
                </Table>
            )
        }
        else{
            return "No hay concursantes"
        }
    } 

    render_participants_pagination = () => {
        if (!this.props.currentQuiz || this.state.total === 0) return null

        return (
            <Pagination 
                activePage={this.state.page} 
                totalPages={Math.ceil(this.state.total / this.state.pageSize)} 
                onPageChange={(e, { activePage }) => this.fetchUsers(activePage)}
            />
        )
    }

    fetchUsers = async (page) => {

        if (this.props.currentQuiz._id !== null) {
                let pageSize = this.state.pageSize || 5;
                
                await this.setState({
                    page
                })
    
                let query = {
                    $limit: pageSize,
                    $skip: pageSize * (this.state.page - 1),
                    quiz: this.props.currentQuiz._id,
                    $populate: 'user',
                }
        
        
                    const users = await feathersClient.service('quizusers').find({
                        query,
                    })
        
                    this.setState({
                        users: users.data,
                        total: users.total,
                        firstLoaded: false,
                    })
        }


    }

    render() {
        return (
            <Modal size="small" open={this.props.open} onClose={this.close}>
                <Modal.Header>
                    {this.props.currentQuiz ? this.props.currentQuiz.name : ''}
                </Modal.Header>
                <Modal.Content scrolling>

                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <span style={{ fontWeight: 'bold', display: 'block' }}>
                                    Descripción
                                </span>
                                <span>{this.props.currentQuiz && this.props.currentQuiz.description}</span>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <span style={{ fontWeight: 'bold', display: 'block' }}>
                                    Fecha de inicio
                                </span>
                                {this.props.currentQuiz && moment(this.props.currentQuiz.startDate).format('DD-MMMM-YYYY')}
                            </Grid.Column>
                            <Grid.Column>
                                <span style={{ fontWeight: 'bold', display: 'block' }}>
                                    Fecha de fin
                                </span>
                                {this.props.currentQuiz && moment(this.props.currentQuiz.finishDate).format('DD-MMMM-YYYY')}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                    <Divider />
                    <Header as="h3" icon="list" content="Preguntas" />      
                    {this.props.currentQuiz && (
                        <div>
                            {this.props.currentQuiz.questions.map((question, position) => (
                                    <div key={position}>
                                        <p>{`${position + 1} - ${question.title}`}</p>
                                        <div>
                                            <Grid>
                                                <Grid.Row columns={question.answers.length}>
                                                    {question.answers.map((answer, index) => (
                                                        <Grid.Column key={index}>
                                                            <div>
                                                                <div style={{
                                                                    backgroundColor: '#e8e8e8',
                                                                    borderRadius: 3,
                                                                    padding: '5px 10px',
                                                                    fontFamily: 'Lato',
                                                                    fontSize: 12,
                                                                    fontWeight: 700,
                                                                    color: 'rgba(0,0,0,.6)',
                                                                    display: 'flex',
                                                                    border: question.correctAnswer === index ? '3px solid #08cb08' : 0,
                                                                    margin: question.correctAnswer !== index ? '3px 0 0' : 0,
                                                                }}
                                                                >
                                                                    <div style={{ flex: 1, marginRight: 5 }}>
                                                                        <span>{answer}</span>
                                                                    </div>
                                                                </div>
                                                                {question.correctAnswer === index && (
                                                                    <div style={{ textAlign: 'center' }}>
                                                                        <span style={{ color: '#08cb08', fontWeight: 700 }}>Correcta</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </Grid.Column>
                                                    ))}
                                                </Grid.Row>
                                            </Grid>
                                        </div>
                            
                                        <Divider />
                                    </div>
                                ))}
                        </div>
                    )}
                    <Divider />
                    <Header as="h3" icon="users" content="Participantes" />  
                    <Grid>
                        <Grid.Row columns={1}>
                            <Grid.Column textAlign="center">
                                <this.render_participants_table />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={1}>
                            <Grid.Column textAlign="center">
                                {this.render_participants_pagination()}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Modal.Content>
                <Modal.Actions>
                    <Button primary content="OK" onClick={this.close} />
                </Modal.Actions>
            </Modal>
        )
    }
}


export default QuizesDetailsModal

import reduxThunk from 'redux-thunk';
import reduxPromiseMiddleware from 'redux-promise-middleware';
import reduxMulti from 'redux-multi';
import { routerMiddleware } from 'react-router-redux';
import { createBrowserHistory } from 'history'

export const history = process.env.NODE_ENV === 'production' ? createBrowserHistory() : createBrowserHistory( /*{basename: "/fronius/web" } */);

export default [
    reduxThunk, // Thunk middleware for Redux
    reduxMulti, // Dispatch multiple actions
    reduxPromiseMiddleware(), // Resolve, reject promises with conditional optimistic updates
    routerMiddleware(history), // !! IMPORTANT for location.href changes
];

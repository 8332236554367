import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import {
	Button,
	Header,
	Image,
	Modal,
	Pagination,
	Segment,
	Grid,
	Divider,
	Table,
    Label,
    Form,
    Dropdown,
} from 'semantic-ui-react';
import Currency from 'react-currency-formatter';
import { ROWS_PER_PAGE } from './ProductData';
import Config from '../../config';

class ProductContainer extends Component {
	state = {
        currentProduct: null,
        filters: {
            active: 'todos',
        },
	};

	componentDidMount() {
		if (!this.props.products.store) {
			this.getProducts(1);
		}
	}

	getProducts = (page) => {
		this.props.store({ page });

        if (this.state.filters.active === 'todos') {
            setTimeout(() => {
                this.props.find({
                    query: {
                        $skip: (this.props.products.store.page - 1) * ROWS_PER_PAGE,
                        $limit: ROWS_PER_PAGE,
                        type: 'used',
                        // active: true,
                    },
                });
            }, 50);
        } else {
            setTimeout(() => {
                this.props.find({
                    query: {
                        $skip: (this.props.products.store.page - 1) * ROWS_PER_PAGE,
                        $limit: ROWS_PER_PAGE,
                        type: 'used',
                        active: this.state.filters.active ? 1 : 0,
                    },
                });
            }, 50);
        }
	};

	renderTableBody = () => this.props.products.queryResult.data.map(product => (
				<Table.Row key={product._id}>
					<Table.Cell>{product.sku}</Table.Cell>
					<Table.Cell>{product.name}</Table.Cell>
					<Table.Cell>{product.version}</Table.Cell>
					<Table.Cell>{product.amperage}</Table.Cell>
					<Table.Cell>
						<Label
							color={product.multivoltage ? 'red' : 'grey'}
							content={product.multivoltage ? 'Si' : 'No'}
						/>
					</Table.Cell>
					<Table.Cell>
						<Currency quantity={product.price} currency="USD" />
					</Table.Cell>
					<Table.Cell>
						{product.processes.map((p, i) => (
							<Label
								// eslint-disable-next-line react/no-array-index-key
								key={i}
								color="grey"
								size="mini"
								content={p}
							/>
						))}
					</Table.Cell>
					<Table.Cell>
						<Image avatar src={Config.apiUrl + product.image} />
					</Table.Cell>
					<Table.Cell>
						{product.active === true ? (
							<Label tag color="teal">
								Activo
							</Label>
						) : (
							<Label tag color="red">
								Eliminado
							</Label>
						)}
					</Table.Cell>
					<Table.Cell>
						<Button
							content="Ver detalles"
							size="mini"
							onClick={() =>
								this.setState({ currentProduct: product })
							}
						/>
					</Table.Cell>
					<Table.Cell>
						<Button
							color="black"
							size="mini"
							icon="pencil"
							content="Editar"
							onClick={() =>
								this.props.push(`/product/${product._id}`)
							}
						/>
					</Table.Cell>
				</Table.Row>
			));

	renderPagination = () => {
		if (
			!this.props.products.store ||
			this.props.products.queryResult.total === 0
		) { return null; }

		return (
			<Pagination
				activePage={this.props.products.store.page}
				totalPages={Math.ceil(this.props.products.queryResult.total / ROWS_PER_PAGE)}
				onPageChange={(e, { activePage }) =>
					this.getProducts(activePage)
				}
			/>
		);
	};

	render() {
		return (
			<div>
				<Grid>
					<Grid.Row columns={2}>
						<Grid.Column>
							<Header
								as="h2"
								icon="cubes"
								content="Productos usados"
							/>
						</Grid.Column>
						<Grid.Column>
							<Button
								icon="plus"
								color="red"
								floated="right"
								content="Nuevo"
								onClick={() =>
									this.props.push('/product/create')
								}
							/>
							<Button
								icon="refresh"
								color="grey"
								floated="right"
								onClick={() =>
									this.getProducts(this.props.products.store.page)
								}
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<Divider />

                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <Grid.Column>
                                <Form>
                                    <Form.Group>
                                        <Form.Field>
                                            <Dropdown
                                                placeholder="Activo"
                                                search
                                                selection
                                                options={[
                                                    {
                                                        text: 'Todos',
                                                        key: 1,
                                                        value: 'todos',
                                                    },
                                                    {
                                                        text: 'Activos',
                                                        key: 1,
                                                        value: true,
                                                    },
                                                    {
                                                        text: 'Inactivos',
                                                        key: 1,
                                                        value: false,
                                                    },
                                                ]}
                                                value={this.state.filters.active}
                                                style={{
                                                    height: '50%',
                                                    width: '100%',
                                                    top: 24,
                                                }}
                                                onChange={(e, { value }) => {
                                                    this.setState({
                                                        filters: {
                                                            ...this.state.filters,
                                                            active: value,
                                                        },
                                                    }, () => this.getProducts(this.props.products.store.page))
                                                }}
                                            />
                                        </Form.Field>
                                        {/* <Form.Field>
                                            <label>Buscar</label>
                                            <Button icon="search" color="red" onClick={this._handleSearchButton} />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Limpiar</label>
                                            <Button icon="leaf" color="red" onClick={this._handleCleanButton} />
                                        </Form.Field> */}
                                    </Form.Group>
                                </Form>
                            </Grid.Column>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

				<Grid>
					<Grid.Row columns={1}>
						<Grid.Column>
							<Segment
								raised
								loading={this.props.products.isLoading}
							>
								<Table striped>
									<Table.Header>
										<Table.Row>
											<Table.HeaderCell>
												SKU
											</Table.HeaderCell>
											<Table.HeaderCell>
												Nombre
											</Table.HeaderCell>
											<Table.HeaderCell>
												Versión
											</Table.HeaderCell>
											<Table.HeaderCell>
												Amperaje
											</Table.HeaderCell>
											<Table.HeaderCell>
												Multivoltaje
											</Table.HeaderCell>
											<Table.HeaderCell>
												Precio
											</Table.HeaderCell>
											<Table.HeaderCell>
												Procesos
											</Table.HeaderCell>
											<Table.HeaderCell>
												Imágen
											</Table.HeaderCell>
											<Table.HeaderCell>
												Estatus
											</Table.HeaderCell>

											<Table.HeaderCell>
												Detalles
											</Table.HeaderCell>
											<Table.HeaderCell></Table.HeaderCell>
										</Table.Row>
									</Table.Header>
									<Table.Body>
										{this.renderTableBody()}
									</Table.Body>
								</Table>
							</Segment>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row columns={1}>
						<Grid.Column textAlign="center">
							{this.renderPagination()}
						</Grid.Column>
					</Grid.Row>
				</Grid>

				<Modal
					size="medium"
					open={!!this.state.currentProduct}
					onClose={() => this.setState({ currentProduct: null })}
				>
					<Modal.Header>Detalle de producto</Modal.Header>

					{this.state.currentProduct && (
						<Modal.Content image>
							<Image
								wrapped
								size="large"
								src={
									Config.apiUrl +
									this.state.currentProduct.image
								}
							/>
							<Modal.Description>
								<Header>
									SKU {this.state.currentProduct.sku} -{' '}
									{this.state.currentProduct.name}
								</Header>
								<p>
									<Header sub>Descripción</Header>
									<span>
										{this.state.currentProduct.description}
									</span>
								</p>
								<p>
									<Header sub>Accesorios</Header>
									<span>
										{this.state.currentProduct.accessories}
									</span>
								</p>
							</Modal.Description>
						</Modal.Content>
					)}

					<Modal.Actions>
						<Button
							primary
							content="OK"
							onClick={() =>
								this.setState({ currentProduct: null })
							}
						/>
					</Modal.Actions>
				</Modal>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	products: state.services.products,
	router: state.router,
});

const mapDispatchToProps = (dispatch, { services }) => ({
	find: (query = {}) => dispatch(services.products.find(query)),
	patch: (id, data) => dispatch(services.products.patch(id, data)),
	store: data => dispatch(services.products.store(data)),
	push: location => dispatch(push(location)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductContainer);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Grid,
	Header,
	Divider,
	Button,
	Segment,
	Form,
	Dropdown,
	Message,
} from 'semantic-ui-react';
import feathersRestClient from '@feathersjs/rest-client';
import feathers from '@feathersjs/feathers';
import axios from 'axios';
import { ROWS_PER_PAGE } from './BanksData';
import Config from '../../config';
import AlertModal from '../../components/AlertModal';

const INSTALLMENTS = [
	{
		key: '6',
		text: '6',
		value: 6,
	},
	{
		key: '12',
		text: '12',
		value: 12,
	},
	{
		key: '18',
		text: '18',
		value: 18,
	},
	{
		key: '24',
		text: '24',
		value: 24,
	},
	{
		key: '36',
		text: '36',
		value: 36,
	},
];

class BanksCreate extends Component {
	state = {
		loading: false,
		bank: {
			name: '',
			installments: [],
		},
		alertModalVisible: false,
		errors: [],
		logo: '',
	};

	resetState = () => {
		this.setState({
			loading: false,
			logo: '',
			bank: {
				name: '',
				installments: [],
			},
			alertModalVisible: false,
			errors: [],
		});
	};

	createDistributor = async () => {
		// eslint-disable-next-line prefer-const
		let errorsToReturn = [];
		if (this.state.bank.name === '') {
			errorsToReturn.push('El nombre esta vacio');
		}
		if (this.state.bank.installments.length === 0) {
			errorsToReturn.push('Las parcialidades estan vacias');
		}

		await this.setState({
			loading: errorsToReturn.length === 0,
			errors: errorsToReturn,
		});

		if (errorsToReturn.length > 0) {
			return;
		}

		// eslint-disable-next-line prefer-const
		let newDataToSend = new FormData();
		if (
			document.getElementById('logoFile') &&
			document.getElementById('logoFile').files.length > 0 &&
			document.getElementById('logoFile').files[0]
		) {
			newDataToSend.set(
				'logoFile',
				document.getElementById('logoFile').files[0],
			);
		}
		newDataToSend.set('name', this.state.bank.name);
		this.state.bank.installments.forEach((element, index) => {
			newDataToSend.append(`installments[${index}]`, element);
		});

		const token = localStorage.getItem('fronius_jwt');
		const app = feathers();
		// Connect to the same as the browser URL (only in the browser)
		const restClient = feathersRestClient(Config.apiUrl);

		app.configure(restClient.axios(axios));

		app.service('banks')
			.create(newDataToSend, {
				headers: {
					Authorization: token,
					'Content-Type': 'multipart/form-data',
				},
			})
			.then(() => {
				if (document.getElementById('logoFile')) {
					document.getElementById('logoFile').value = null;
				}

				this.setState({
					alertModalVisible: true,
				});

				this.props.find(
					this.props.banks.store ? this.props.banks.store.page : 1,
				);
			})
			.catch((err) => {
				alert('Se produjo un error.');

				this.setState({
					loading: false,
				});
			});
	};

	renderHeader = () => (
		<Grid>
			<Grid.Row columns={1}>
				<Grid.Column>
					<Header as="h2" icon="money" content="Bancos > Nuevo" />
				</Grid.Column>
			</Grid.Row>
		</Grid>
	);

	renderInputImage = () => {
		return (
			<Form.Group widths="equal">
				<Form.Field required>
					<input
						type="file"
						id="logoFile"
						onChange={(event) => {
							if (
								event.target.files &&
								event.target.files.length > 0
							) {
								this.setState({
									// eslint-disable-next-line react/no-unused-state
									logo: event.target.files[0],
								});
							}
						}}
					/>
				</Form.Field>
			</Form.Group>
		);
	};

	render() {
		return (
			<div>
				<this.renderHeader />

				{this.state.errors.length > 0 && (
					<Message negative>
						<Message.Header>
							Hay varios errores de validación
						</Message.Header>
						{this.state.errors.map((error) => (
							<Message.Item>{error}</Message.Item>
						))}
					</Message>
				)}
				<Divider />
				<Segment>
					<Form>
						<Form.Group widths="equal">
							<Form.Field required>
								<Form.Input
									label="Nombre"
									value={this.state.bank.name}
									onChange={(e, { value }) => {
										this.setState({
											bank: {
												...this.state.bank,
												name: value,
											},
										});
									}}
								/>
							</Form.Field>

							<Form.Field
								required
								control={Dropdown}
								label="Parcialidades"
								placeholder="Parcialidades"
								search
								selection
								options={INSTALLMENTS}
								multiple
								onChange={(e, { value }) => {
									this.setState({
										bank: {
											...this.state.bank,
											installments: value,
										},
									});
								}}
								value={this.state.bank.installments}
							/>
						</Form.Group>

						<Form.Group>
							<Form.Field
								style={{
									display: 'flex',
									flex: 1,
									flexWrap: 'wrap',
								}}
							>
								<div
									style={{
										width: '100%',
									}}
								>
									{this.renderInputImage()}
								</div>
							</Form.Field>
						</Form.Group>
						<Divider />
						<Grid>
							<Grid.Row>
								<Grid.Column
									floated="right"
									computer={16}
									largeScreen={3}
									widescreen={3}
									tablet={16}
									mobile={16}
								>
									<Button
										loading={this.state.loading}
										disabled={this.state.loading}
										onClick={this.createDistributor}
										positive
										fluid
										icon="plus"
										content="CREAR"
										labelPosition="left"
									/>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Form>
				</Segment>

				<AlertModal
					open={this.state.alertModalVisible}
					title="Éxito"
					message="El banco fue registrado satisfactoriamente"
					onClose={this.resetState}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	banks: state.services.banks,
});

const mapDispatchToProps = (dispatch, { services }) => ({
	create: (data) => dispatch(services.banks.create(data)),
	find: (page) =>
		dispatch(
			services.banks.find({
				query: {
					$skip: (page - 1) * ROWS_PER_PAGE,
					$limit: ROWS_PER_PAGE,
					$sort: {
						createdAt: -1,
					},
				},
			}),
		),
});

export default connect(mapStateToProps, mapDispatchToProps)(BanksCreate);

import React, { Component } from "react";
import { Modal, Button, Form, Label, Icon } from "semantic-ui-react";
import feathersClient from "../redux/FeathersRedux";

export default class MailModal extends Component {
  state = {
    posibleReceivers: [],
    receivers: [],
    cc: [],
    currentcc: "",
    message: ""
  };

  componentDidMount() {
    this.preloadData();
  }

  preloadData = () => {
    let posibleReceivers = this.props.demo.city
      ? this.props.demo.city.emails
      : [];

    posibleReceivers = posibleReceivers.map(v => ({
      key: v,
      text: v,
      value: v
    }));

    this.setState({
      posibleReceivers,
      message: `${
        this.props.demo.telephone
          ? `Teléfono: ${this.props.demo.telephone}\n`
          : ''
      }${
        this.props.demo.material ? `Material: ${this.props.demo.material}\n` : ''
      }${
        this.props.demo.process
          ? `Proceso: ${this.props.demo.process.join(', ').trim()}\n`
          : ''
      }${
        this.props.demo.thickness ? `Espesor: ${this.props.demo.thickness}\n` : ''
      }`,
    });
  };

  addCc = () => {
    const cc = [...this.state.cc];
    if (cc.indexOf(this.state.currentcc) === -1) {
      cc.push(this.state.currentcc);
    }
    this.setState({
      cc,
      currentcc: ""
    });
  };

  deleteCc = email => {
    const cc = [...this.state.cc];

    const position = cc.indexOf(email);

    cc.splice(position, 1);

    this.setState({
      cc
    });
  };

  send = async () => {
    const { message } = this.state;
    let default_mails = [...this.state.receivers];
    default_mails = default_mails.join(";");

    let cc = [...this.state.cc];

    let mail_list = default_mails;

    if (cc.length !== 0) {
      cc = cc.join(";");

      mail_list = default_mails.concat(default_mails.length > 0 ? ";" : "", cc);
    }

    const mail_params = {
      mail_list,
      message,
      subject: "DEMO"
    };

    if (mail_list.length > 10) {
      const mail_sender = await feathersClient
        .service("mail_sender")
        .create(mail_params);
    }

    // await feathersClient.service('quotations').patch(this.props.quotation._id, {
    //     sended: 'Enviada'
    // })

    this.props.onMailSended && this.props.onMailSended();

    await this.setState({
      receivers: [],
      cc: [],
      currentcc: "",
      message: ""
    });

    this.props.onClose && this.props.onClose();
  };

  closeModal = async _ => {
    this.setState(
      {
        receivers: [],
        cc: [],
        currentcc: "",
        message: ""
      },
      _ => {
        this.props.onClose();
      }
    );
  };

  renderCCEmails = _ =>
    this.state.cc.map((v, i) => (
      <Label>
        {v}
        <Icon name="delete" onClick={_ => this.deleteCc(v)} />
      </Label>
    ));

  render() {
    return (
      <Modal open={this.props.open} size="large" onClose={this.closeModal}>
        <Modal.Header>Envio de Correo</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group widths="equal">
              <Form.Field>
                <label>Selecciona los destinatarios</label>
                <Form.Dropdown
                  placeholder="Emails"
                  fluid
                  multiple
                  value={this.state.receivers}
                  selection
                  options={this.state.posibleReceivers}
                  onChange={(e, d) => {
                    this.setState({
                      receivers: d.value
                    });
                  }}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <label>C.c.</label>
                <Form.Input
                  placeholder="C.c."
                  value={this.state.currentcc}
                  fluid
                  onChange={(e, d) => {
                    this.setState({
                      currentcc: d.value
                    });
                  }}
                />
              </Form.Field>
              <Form.Field>
                <label>Añadir</label>
                <Form.Button
                  color="green"
                  icon="add"
                  circular
                  onClick={this.addCc}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    flexWrap: "wrap"
                  }}
                >
                  <this.renderCCEmails />
                </div>
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <label>Mensaje</label>
                <Form.TextArea
                  value={this.state.message}
                  style={{
                    minHeight: 200
                  }}
                  onChange={(e, d) => {
                    this.setState({
                      message: d.value
                    });
                  }}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.closeModal} negative inverted>
            Cerrar
          </Button>
          <Button onClick={this.send} positive inverted>
            Enviar
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

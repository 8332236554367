import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Grid, Form, Segment, Divider, Header, Button, TextArea } from 'semantic-ui-react'
import 'react-day-picker/lib/style.css';
import 'moment/locale/es';
import { arrayMove } from 'react-sortable-hoc';
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import moment from 'moment'
import QuestionFormModal from './QuizesCreate/QuestionFormModal';
import AlertModal from '../../components/AlertModal'
import { QuestionsContainer } from './QuizesCreate/QuestionsContainer';

class QuizesCreate extends Component {
    state = {
        name: '',
        description: '',
        questions: [],
        startDate: '',
        finishDate: '',
        modalOpen: false,
        currentQuestion: null,
        currentQuestionIndex: null,
    }

    onQuestionAdd = (question) => {
        if (this.state.currentQuestionIndex !== null) {
            const questions = JSON.parse(JSON.stringify(this.state.questions))
            questions[this.state.currentQuestionIndex] = question

            this.setState({
                questions,
                modalOpen: false,
                currentQuestion: null,
                currentQuestionIndex: null,
            })
        } else {
            this.setState({
                questions: this.state.questions.concat([question]),
                modalOpen: false,
            })
        }
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
        this.setState({
            questions: arrayMove(this.state.questions, oldIndex, newIndex),
        });
    };

    onItemRemove = (index) => {
        const questions = [...this.state.questions]

        questions.splice(index, 1)

        this.setState({
            questions,
        })
    }

    onItemEdit = (index) => {
        this.setState({
            modalOpen: true,
            currentQuestion: this.state.questions[index],
            currentQuestionIndex: index,
        })
    }

    save = () => {
        if (this.props.quizes.isSaving) return;

        let {
            name, description, questions, startDate, finishDate, 
        } = this.state

        startDate = moment(startDate).hour(0).minute(0).second(0)
            .toDate()
            
        finishDate = moment(finishDate).hour(0).minute(0).second(0)
			.toDate()

        this.props.create({
            name, description, questions, startDate, finishDate,
        })
            .then(() => {
                this.setState({ alertModal: true })
                this.props.find(this.props.quizes.store ? this.props.quizes.store.page : 1)
            })
    }

    onAlertDismiss = () => {
        this.setState({
            alertModal: false, name: '', description: '', questions: [], startDate: '', finishDate: '', 
        })
    }

    render() {
        return (
            <div>
                <Grid>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <Header as="h2" icon="file alternate outline" content="Quizes > Nuevo" />      
                        </Grid.Column>
                        <Grid.Column>
                            <Button 
                                positive 
                                icon="plus" 
                                floated="right" 
                                content="Crear"
                                disabled={!this.state.name || !this.state.description || !this.state.startDate || !this.state.finishDate || this.state.questions.length === 0 || this.state.questions.length > 10}
                                onClick={this.save}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                <Divider />

                <Segment loading={this.props.quizes.isSaving}>
                    <Form onSubmit={this.save}>
                        <Form.Input required value={this.state.name} label="Nombre" type="text" placeholder="Nombre del exámen" onChange={(e, { value }) => this.setState({ name: value })} />
                        <Form.Input required value={this.state.description} label={`Descripción (${this.state.description.length}/150)`} control={TextArea} placeholder="Descripción del exámen" rows={2} maxLength="150" onChange={(e, { value }) => this.setState({ description: value })} />

                        <Form.Group widths="equal">
                            <Form.Field required error={this.state.error}>
                                <label>Fecha de inicio</label>
                                <DayPickerInput
                                    format="DD-MM-YYYY"
                                    value={this.state.startDate}
                                    formatDate={formatDate}
                                    parseDate={parseDate}
                                    placeholder="Fecha del evento"
                                    onDayChange={startDate => this.setState({ startDate, error: false })}
                                    dayPickerProps={{
                                        locale: 'es',
                                        localeUtils: MomentLocaleUtils,
                                        disabledDays: { before: new Date(), after: this.state.finishDate },
                                    }}
                                />
                            </Form.Field>

                            <Form.Field required error={this.state.error}>
                                <label>Fecha de fin</label>
                                <DayPickerInput
                                    disabled
                                    format="DD-MM-YYYY"
                                    value={this.state.finishDate}
                                    formatDate={formatDate}
                                    parseDate={parseDate}
                                    placeholder="Fecha del evento"
                                    onDayChange={finishDate => this.setState({ finishDate, error: false })}
                                    dayPickerProps={{
                                        locale: 'es',
                                        localeUtils: MomentLocaleUtils,
                                        disabledDays: { before: this.state.startDate || new Date() },
                                    }}
                                />
                            </Form.Field>
                        </Form.Group>
                        
                    </Form>
                </Segment>

                <Segment loading={this.props.quizes.isSaving}>
                    <Grid style={{ marginBottom: 5 }}>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <Header as="h3" icon="list" content="Preguntas" />      
                            </Grid.Column>
                            <Grid.Column>
                                <Button 
                                    basic 
                                    compact 
                                    floated="right" 
                                    type="button" 
                                    primary 
                                    icon="plus" 
                                    content="Nueva pregunta" 
                                    labelPosition="left" 
                                    onClick={() => this.setState({ modalOpen: true })}
                                    disabled={this.state.questions.length > 9}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                    {/* Lista de preguntas */}
                    <QuestionsContainer 
                        pressDelay={200} 
                        items={this.state.questions} 
                        onSortEnd={this.onSortEnd} 
                        onItemRemove={this.onItemRemove}
                        onItemEdit={this.onItemEdit}
                    />
                </Segment>

                {/* Modal de nueva/edicion pregunta */}
                <QuestionFormModal 
                    open={this.state.modalOpen} 
                    onClose={() => this.setState({ modalOpen: false, currentQuestion: null, currentQuestionIndex: null })}
                    onQuestionAdd={this.onQuestionAdd}
                    currentQuestion={this.state.currentQuestion}
                />

                <AlertModal 
                    open={this.state.alertModal} 
                    title="Éxito" 
                    message="El quiz fue creado satisfactoriamente" 
                    onClose={this.onAlertDismiss} 
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    quizes: state.services.quizes,
})

const mapStateToDispatch = (dispatch, { services }) => ({
    find: (query = {}) => dispatch(services.quizes.find(query)),
    create: data => dispatch(services.quizes.create(data)),
})

export default connect(mapStateToProps, mapStateToDispatch)(QuizesCreate)

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Grid,
	Header,
	Divider,
	Button,
	Segment,
	Form,
} from 'semantic-ui-react';
import feathersClient from '../../redux/FeathersRedux'
import { ROWS_PER_PAGE } from './CitiesData';
import AlertModal from '../../components/AlertModal';

class StatesEdit extends Component {
	state = {
		loading: false,
		state: {
			name: '',
		},
		alertModal: false,
	};

	componentDidMount() {
		this.fetchState(this.props.match.params.id);
	}
	
	fetchState = async (_id) => {
		const state = await feathersClient.service('states').get(_id);
		this.preloadStateInfo(state);
	};
	
	preloadStateInfo = (state) => {
		this.setState({
			state: {
				...this.state.state,
				name: state.name,
			},
		});
	};

	resetState = () => {
		this.setState({
			loading: false,
			alertModal: false,
		});
	};

	save = async () => {
		if (this.state.state.name === '') return;

		await this.setState({
			loading: true,
		});

		await this.props.patch(this.props.match.params.id, this.state.state);

		this.props.find(this.props.states.store ? this.props.states.store.page : 1);

		this.setState({
			alertModal: true,
		});
	};

	// #region statichtml
	renderHeader = () => {
		return (
			<Grid>
				<Grid.Row columns={1}>
					<Grid.Column>
						<Header
							as="h2"
							icon="plus square outline"
							content="Estados > Editar"
						/>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		);
	};

	render() {
		return (
			<div>
				<this.renderHeader />
				<Divider />
				<Segment>
					<Form>
						<Form.Group widths="equal">
							<Form.Field required>
								<Form.Input
									label="Nombre"
									value={this.state.state.name}
									onChange={(e, { value }) => {
										this.setState({
											state: {
												...this.state.state,
												name: value,
											},
										});
									}}
								/>
							</Form.Field>
						</Form.Group>
						<Divider />
						<Grid>
							<Grid.Row>
								<Grid.Column
									floated="right"
									computer={16}
									largeScreen={3}
									widescreen={3}
									tablet={16}
									mobile={16}
								>
									<Button
										loading={this.state.loading}
										disabled={this.state.loading}
										onClick={this.save}
										positive
										fluid
										icon="plus"
										content="Editar"
										labelPosition="left"
									/>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Form>
				</Segment>

				<AlertModal 
					open={this.state.alertModal}
					title="Éxito"
					message="Estado editado satisfactoriamente."
					onClose={this.resetState}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => ({
    states: state.services.states,
});

const mapDispatchToProps = (dispatch, { services }) => ({
    patch: (id, data) => dispatch(services.states.patch(id, data)),
    create: data => dispatch(services.states.create(data)),
	find: page =>
		dispatch(
			services.states.find({
				query: {
					$skip: (page - 1) * ROWS_PER_PAGE,
					$limit: ROWS_PER_PAGE,
					$sort: {
						createdAt: -1,
					},
				},
			}),
		),
});

export default connect(mapStateToProps, mapDispatchToProps)(StatesEdit);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import {
	Button,
	Header,
	Modal,
	Pagination,
	Segment,
	Grid,
	Divider,
	Table,
	Image,
	Form,
} from 'semantic-ui-react';
import { ROWS_PER_PAGE } from './DemoData';
import DayPicker from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import moment from 'moment';
import 'moment/locale/es';
import MomentLocaleUtils, {
	formatDate,
	parseDate,
} from 'react-day-picker/moment';
import MailModal from '../../components/MailModal';
import config from '../../config';
import DemoInfoModal from '../../components/DemoInfoModal';

class DemoContainer extends Component {
	state = {
		filter: {
			initDay: '',
			lastDay: '',
		},
		isVisibleMailModal: false,
		message: '',
		demo: {},
		moreInfoModalOpened: false,
	};

	componentDidMount() {
		if (!this.props.demos.store) {
			this.getdemos(1);
		}
	}

	toggleMoreInfoModal = () => {
		this.setState({
			moreInfoModalOpened: !this.state.moreInfoModalOpened,
		});
	};

	getdemos = page => {
		this.props.store({ page });

		setTimeout(() => {
			let query = {
				$skip: (this.props.demos.store.page - 1) * ROWS_PER_PAGE,
				$limit: ROWS_PER_PAGE,
				$sort: {
					createdAt: -1,
				},
				$populate: [
					{
						path: 'user',
					},
					{
						path: 'staff',
					},
					{
						path: 'state',
					},
				],
			};

			// vamos a checar con un if si el usuario ha seleccionado una fecha de inicio para filtrar o una fecha de fin

			if (this.state.filter.initDay !== '') {
				query['createdAt'] = {
					$gte: moment(this.state.filter.initDay)
						.hour(0)
						.minute(1)
						.second(1)
						.format('YYYY-MM-DD HH:mm:ss'),
				};
			}

			if (this.state.filter.lastDay !== '') {
				if (this.state.filter.initDay !== '') {
					query['createdAt'] = {
						...query.createdAt,
						$lte: moment(this.state.filter.lastDay)
							.hour(23)
							.minute(57)
							.second(59)
							.format('YYYY-MM-DD HH:mm:ss'),
					};
				} else {
					query['createdAt'] = {
						$lte: moment(this.state.filter.lastDay)
							.hour(23)
							.minute(57)
							.second(59)
							.format('YYYY-MM-DD HH:mm:ss'),
					};
				}
			}

			this.props.onFind({
				query,
			});
		}, 50);
	};

	_handleFilterButton = () => {
		this.getdemos(this.props.demos.store.page);
	};

	_handleCleanButton = async () => {
		await this.setState({
			filter: {
				...this.state.filter,
				initDay: '',
				lastDay: '',
			},
		});

		this.getdemos(this.props.demos.store.page);
	};

	openMailModal = () => {
		this.setState({
			isVisibleMailModal: true,
		});
	};

	closeMailModal = () => {
		this.setState({
			isVisibleMailModal: false,
		});
	};

	onMailSended = () => {
		// console.log("mail sended")
	};

	renderTableBody = () => {
		return this.props.demos.queryResult.data.map(demo => {
			return (
				<Table.Row key={demo._id}>
					<Table.Cell>
						<p>{demo.code}</p>
					</Table.Cell>
					<Table.Cell>
						{demo.user
							? `${demo.user.name} ${demo.user.lastName}`
							: ''}
					</Table.Cell>
					<Table.Cell>{demo.user ? demo.user.email : ''}</Table.Cell>
					<Table.Cell>
						{demo.user ? demo.user.enterprise : ''}
					</Table.Cell>
					<Table.Cell>
						{demo.user ? demo.user.zipCode : ''}
					</Table.Cell>
					<Table.Cell>
						{moment(demo.createdAt).format('DD-MMMM-YYYY')}
					</Table.Cell>
					<Table.Cell>{demo.status}</Table.Cell>
					<Table.Cell>
						{demo.staff
							? demo.staff.name + ' ' + demo.staff.lastName
							: '---'}
					</Table.Cell>
					<Table.Cell>
						{demo.state ? demo.state.name : '---'}
					</Table.Cell>
					<Table.Cell>
						<Button
							icon="mail"
							circular
							color={'red'}
							onClick={() => {
								this.setState(
									{
										demo,
									},
									() => {
										this.openMailModal();
									},
								);
							}}
						/>
					</Table.Cell>
					<Table.Cell>
						<Button
							icon={'eye'}
							circular
							color={'blue'}
							onClick={() => {
								this.setState(
									{
										demo,
									},
									() => {
										this.toggleMoreInfoModal();
									},
								);
							}}
						/>
					</Table.Cell>
				</Table.Row>
			);
		});
	};

	renderPagination = () => {
		if (!this.props.demos.store || this.props.demos.queryResult.total === 0)
			return null;

		return (
			<Pagination
				activePage={this.props.demos.store.page}
				totalPages={Math.ceil(
					this.props.demos.queryResult.total / ROWS_PER_PAGE,
				)}
				onPageChange={(e, { activePage }) => this.getdemos(activePage)}
			/>
		);
	};

	render() {
		return (
			<div>
				<Grid>
					<Grid.Row columns={2}>
						<Grid.Column>
							<Header as="h2" icon="calendar" content="Demos" />
						</Grid.Column>
						<Grid.Column>
							<Button
								icon="refresh"
								color="grey"
								floated="right"
								onClick={() =>
									this.getdemos(this.props.demos.store.page)
								}
							/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column>
							<Form>
								<Form.Group>
									<Form.Field>
										<label>Fecha Inicio</label>
										<DayPickerInput
											value={this.state.filter.initDay}
											format="YYYY-MM-DD"
											formatDate={formatDate}
											parseDate={parseDate}
											onDayChange={date => {
												this.setState({
													filter: {
														...this.state.filter,
														initDay: date,
													},
												});
											}}
										/>
									</Form.Field>
									<Form.Field>
										<label>Fecha Fin</label>

										<DayPickerInput
											value={this.state.filter.lastDay}
											format="YYYY-MM-DD"
											formatDate={formatDate}
											parseDate={parseDate}
											onDayChange={date => {
												this.setState({
													filter: {
														...this.state.filter,
														lastDay: date,
													},
												});
											}}
										/>
									</Form.Field>
									<Form.Field>
										<label>Filtrar</label>
										<Button
											circular
											icon="filter"
											onClick={this._handleFilterButton}
										/>
									</Form.Field>
									<Form.Field>
										<label>Limpiar</label>
										<Button
											circular
											icon="x"
											onClick={this._handleCleanButton}
										/>
									</Form.Field>
								</Form.Group>
							</Form>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<Divider />

				<Grid>
					<Grid.Row columns={1}>
						<Grid.Column>
							<Segment
								raised
								loading={this.props.demos.isLoading}
							>
								<Table striped>
									<Table.Header>
										<Table.Row>
											<Table.HeaderCell>
												Código
											</Table.HeaderCell>
											<Table.HeaderCell>
												Cliente
											</Table.HeaderCell>
											<Table.HeaderCell>
												Email
											</Table.HeaderCell>
											<Table.HeaderCell>
												Empresa
											</Table.HeaderCell>
											<Table.HeaderCell>
												Código Postal
											</Table.HeaderCell>
											<Table.HeaderCell>
												Fecha Alta
											</Table.HeaderCell>
											<Table.HeaderCell>
												Estatus
											</Table.HeaderCell>
											<Table.HeaderCell>
												Quien registro
											</Table.HeaderCell>
											<Table.HeaderCell>
												Estado
											</Table.HeaderCell>

											<Table.HeaderCell></Table.HeaderCell>
											<Table.HeaderCell></Table.HeaderCell>
										</Table.Row>
									</Table.Header>
									<Table.Body>
										{this.renderTableBody()}
									</Table.Body>
								</Table>
							</Segment>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row columns={1}>
						<Grid.Column textAlign="center">
							{this.renderPagination()}
						</Grid.Column>
					</Grid.Row>
				</Grid>

				{this.state.isVisibleMailModal === true && (
					<MailModal
						open={this.state.isVisibleMailModal}
						onClose={this.closeMailModal}
						onMailSended={this.onMailSended}
						demo={this.state.demo}
						message={this.state.message}
					/>
				)}

				<DemoInfoModal
					demo={this.state.demo}
					isOpen={this.state.moreInfoModalOpened}
					onClose={this.toggleMoreInfoModal}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	demos: state.services.demos,
	router: state.router,
	eventUsers: state.services.eventusers,
});

const mapDispatchToProps = (dispatch, { services }) => ({
	onFind: (query = {}) => dispatch(services.demos.find(query)),
	onPatch: (id, data) => dispatch(services.demos.patch(id, data)),
	store: data => dispatch(services.demos.store(data)),
	push: location => dispatch(push(location)),
	findEventUsers: (query = {}) => dispatch(services.eventusers.find(query)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DemoContainer);

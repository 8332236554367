import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Grid,
	Header,
	Divider,
	Button,
	Segment,
	Form,
	Dropdown,
	TextArea,
	Image,
	Container,
	Responsive,
	Label,
} from 'semantic-ui-react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import moment from 'moment';
import 'moment/locale/es';
import MomentLocaleUtils, {
	formatDate,
	parseDate,
} from 'react-day-picker/moment';
import { replace } from 'react-router-redux';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import AlertModal from '../../components/AlertModal';
import Config from '../../config';
import ConfirmModal from '../../components/ConfirmModal';
import { VISIBILITIES, ROWS_PER_PAGE } from './EventData';
import feathersClient from '../../redux/FeathersRedux';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';

const URL_REGEX = /^(http(s)?:\/\/(www\.)?)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

const format = 'h:mm a';

class EventEdit extends Component {
	state = {
		type: 'Fronius Friday',
		location: null,
		title: '',
		description: '',
		image: null,
		status: '',
		modalVisible: false,
		confirmModal: false,
		error: false,
		capacity: null,
		location_id: null,
		citiesOptions: [],
		date: '',
		endDate: '',
		visibility: 'public',
		time: '',
		url: '',
		invalidUrl: false,
	};

	componentDidMount() {
		const id = this.props.match.params.id;
		this.getCities();

		this.props.get(id).then((res) => {
			let state = {
				...this.state,
				...this.props.events.data,
			};

			state.date = moment(state.date).toDate();

			if (state.endDate) {
				state.endDate = moment(state.endDate).toDate();
			}

			// time
			const time = state.time.split(':');

			state.time = moment().hour(time[0]).minute(time[1]).second(0);

			if (state.isUTCTime) {
				state.time = moment
					.utc(
						moment()
							.hour(time[0])
							.minute(time[1])
							.second(0)
							.format('YYYY-MM-DD HH:mm:ss'),
					)
					.local();
			}

			state.image = state.image ? Config.apiUrl + state.image : null;

			this.setState(state);
		});
	}

	getCities = async () => {
		let cities = await feathersClient.service('cities').find({
			query: {
				$limit: '-1',
			},
		});

		const citiesOptions = cities.map((v) => {
			return {
				value: v.code,
				text: v.name,
				_id: v._id,
				key: v._id,
			};
		});

		this.setState({
			citiesOptions,
		});
	};

	remove = () => {
		this.props
			.patch(this.props.events.data._id, {
				active: false,
			})
			.then((res) => {
				this.props.find(
					this.props.events.store ? this.props.events.store.page : 1,
				);
				this.props.replace('/event');
			});
	};

	save = () => {
		if (!this.state.date) {
			this.setState({
				error: true,
			});
			return;
		}

		if (this.props.events.isSaving) return;

		let data = { ...this.state };
		data.date = moment(data.date).hour(0).minute(0).second(0).toDate();
		data.endDate = moment(data.endDate)
			.hour(0)
			.minute(0)
			.second(0)
			.toDate();
		data.time = data.time.clone().utc().format('HH:mm');
		data.isUTCTime = true;

		delete data.image;

		this.props.patch(this.props.events.data._id, data).then((res) => {
			this.setState({ modalVisible: true });
			this.props.find(
				this.props.events.store ? this.props.events.store.page : 1,
			);
			this.props.get(this.props.match.params.id);
		});
	};

	renderImage() {
		if (this.state.image) {
			return (
				<Container
					textAlign="center"
					content={
						<Image centered src={this.state.image} size="small" />
					}
				/>
			);
		}

		return <span>Sin imágen</span>;
	}

	changeStatus = (e) => {
		let status = e.target.checked ? 'Abierto' : 'Lleno';
		this.setState({ status });
		this.props.patch(this.props.events.data._id, {
			status,
		});
	};

	render() {
		return (
			<div>
				<Grid>
					<Grid.Row columns={1}>
						<Grid.Column>
							<Header
								as="h2"
								icon="calendar"
								content={`Eventos > ${
									this.props.events.data
										? this.props.events.data.title
										: ''
								}`}
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<Divider />

				<Segment>
					<Container
						fluid
						textAlign="right"
						style={{
							alignItems: 'center',
							justifyContent: 'flex-end',
							display: 'flex',
						}}
					>
						<span style={{ marginRight: 10, fontWeight: 'bold' }}>
							Estatus
						</span>
						<Toggle
							checked={
								this.state.status === 'Abierto' ? true : false
							}
							onChange={this.changeStatus}
						/>
						<Label
							style={{ marginLeft: 10 }}
							color={
								this.state.status === 'Abierto'
									? 'green'
									: 'red'
							}
							content={
								this.state.status === 'Abierto'
									? 'Abierto'
									: 'Lleno / Cerrado'
							}
						/>
					</Container>
					<Form onSubmit={this.save}>
						<Form.Group widths="equal">
							{/* <Form.Field required>
                                <label>Tipo de evento</label>
                                <Dropdown disabled value={this.state.type} placeholder='Tipo de evento' selection options={TYPES} onChange={(e, {value}) => this.setState({type: value})} />
                            </Form.Field>
                            */}
							<Form.Field
								required
								disabled
								label="Visibilidad del evento"
								value={this.state.visibility}
								control={Dropdown}
								selection
								options={VISIBILITIES}
								onChange={(e, { value }) =>
									this.setState({ visibility: value })
								}
							/>

							<Form.Field
								required
								label="Ubicación"
								disabled
								value={this.state.location}
								control={Dropdown}
								placeholder="Ubicación"
								selection
								options={this.state.citiesOptions}
								onChange={(e, data) => {
									const selectedElement = this.state.citiesOptions.find(
										(element) => {
											if (element.value === data.value)
												return element;
										},
									);
									this.setState({
										location: data.value,
										location_id: selectedElement._id,
									});
								}}
							/>
							<Form.Input
								label="Capacidad (Si se deja vacio, la capacidad sera ilimitada)"
								value={this.state.capacity}
								type="number"
								placeholder="Capacidad"
								onChange={(e, { value }) => {
									this.setState({
										capacity: value,
									});
								}}
							/>
						</Form.Group>
						<Form.Input
							required
							value={this.state.title}
							label="Título"
							type="text"
							placeholder="Título del evento"
							onChange={(e, { value }) =>
								this.setState({ title: value })
							}
						/>
						<Form.Input
							required
							value={this.state.description}
							label={`Descripción (${this.state.description.length}/500)`}
							control={TextArea}
							placeholder="Descripción de la noticia"
							rows={3}
							maxLength="500"
							onChange={(e, { value }) =>
								this.setState({ description: value })
							}
						/>
						<Form.Group widths="equal">
							<Form.Field required error={this.state.error}>
								<label>Hora de incio</label>
								<TimePicker
									showSecond={false}
									// defaultValue={now}
									className="xxx"
									onChange={(value) => {
										this.setState({
											time: value,
										});
									}}
									format={format}
									use12Hours
									inputReadOnly
									value={this.state.time}
								/>
							</Form.Field>
							<Form.Field required error={this.state.error}>
								<label>Fecha de incio</label>
								<DayPickerInput
									format="DD-MM-YYYY"
									value={this.state.date}
									formatDate={formatDate}
									parseDate={parseDate}
									placeholder="Fecha del evento"
									onDayChange={(date) => {
										this.setState({ date, error: false });
									}}
									dayPickerProps={{
										locale: 'es',
										localeUtils: MomentLocaleUtils,
										disabledDays: { before: new Date() },
									}}
								/>
							</Form.Field>
							<Form.Field
								required
								error={this.state.error}
								disabled={!this.state.date}
							>
								<label>Fecha de fin</label>
								<DayPickerInput
									format="DD-MM-YYYY"
									value={this.state.endDate}
									formatDate={formatDate}
									parseDate={parseDate}
									placeholder="Fecha de fin del evento"
									onDayChange={(endDate) =>
										this.setState({ endDate, error: false })
									}
									dayPickerProps={{
										locale: 'es',
										localeUtils: MomentLocaleUtils,
										disabledDays: {
											before: this.state.date,
										},
									}}
								/>
							</Form.Field>
						</Form.Group>

						<Form.Input
							value={this.state.url}
							label="Enlace Url"
							type="text"
							placeholder="Puede ser el link hacia un webinar"
							onChange={(e, { value }) =>
								this.setState({
									url: value,
									invalidUrl: value.length ? !URL_REGEX.test(value) : false,
								})
							}
							error={this.state.invalidUrl}
						/>

						<Form.Field required>
							<label>Imágen</label>
							{this.renderImage()}
						</Form.Field>
						<Divider />

						<Responsive minWidth={Responsive.onlyComputer.minWidth}>
							<Container fluid textAlign="right">
								<Button
									type="button"
									inverted
									icon="delete"
									color="red"
									content="ELIMINAR"
									style={{ marginRight: 20 }}
									onClick={() =>
										this.setState({ confirmModal: true })
									}
								/>
								<Button
									type="button"
									onClick={() => {
										this.save();
									}}
									positive
									icon="pencil"
									content="EDITAR"
									labelPosition="left"
								/>
							</Container>
						</Responsive>

						<Responsive maxWidth={Responsive.onlyTablet.maxWidth}>
							<Grid>
								<Grid.Row>
									<Grid.Column
										tablet={16}
										mobile={16}
										style={{ marginBottom: 10 }}
									>
										<Button
											fluid
											type="button"
											inverted
											icon="delete"
											color="red"
											content="ELIMINAR"
											onClick={() =>
												this.setState({
													confirmModal: true,
												})
											}
										/>
									</Grid.Column>
									<Grid.Column tablet={16} mobile={16}>
										<Button
											type="button"
											onClick={() => {
												this.save();
											}}
											positive
											fluid
											icon="pencil"
											content="EDITAR"
											labelPosition="left"
										/>
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Responsive>
					</Form>
				</Segment>

				<AlertModal
					open={this.state.modalVisible}
					title="Éxito"
					message="El evento fue editado satisfactoriamente"
					onClose={() => this.setState({ modalVisible: false })}
				/>

				<ConfirmModal
					open={this.state.confirmModal}
					title="¿Estás seguro?"
					message="El evento no podrá verse nunca más"
					onClose={() => this.setState({ confirmModal: false })}
					onAccepted={this.remove}
					onRejected={() => this.setState({ confirmModal: false })}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	events: state.services.events,
});

const mapDispatchToProps = (dispatch, { services }) => ({
	get: (id) => dispatch(services.events.get(id)),
	patch: (id, data) => dispatch(services.events.patch(id, data)),
	replace: (path) => dispatch(replace(path)),
	find: (page) =>
		dispatch(
			services.events.find({
				query: {
					$skip: (page - 1) * ROWS_PER_PAGE,
					$limit: ROWS_PER_PAGE,
					$sort: {
						createdAt: -1,
					},
					active: true,
					date: {
						$gte: new Date(),
					},
				},
			}),
		),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventEdit);

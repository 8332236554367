import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import { feathersAuthentication, feathersServices } from './FeathersRedux';
import { history } from './Middleware';

export default combineReducers({
    router: connectRouter(history),
    auth: feathersAuthentication.reducer,
    services: feathersServices,
});

import React, {Component} from 'react'
import { Button, Form, Grid, Image, Segment, Message } from 'semantic-ui-react'
import {connect} from 'react-redux'
import {feathersAuthentication} from '../redux/FeathersRedux'

class LoginContainer extends Component {

    constructor(props) {
        super(props)

        this.state = {
            username: '',
            password: '',
            errorMessage: ''
        }

        this.login = this.login.bind(this)

    }

    login() {
        this.props.authenticate(this.state.username, this.state.password)
            .catch(error => {
                if (this.props.auth.isError.message) {
                    if (this.props.auth.isError.message === 'Missing credentials' || this.props.auth.isError.message === 'Invalid login') {
                        this.setState({ errorMessage: 'Credenciales inválidas'});
                        return;
                    }
                    else if (this.props.auth.isError.message === 'Socket connection timed out') {
                        this.setState({ errorMessage: 'No se pudo conectar al servidor'});
                        return;
                    }
                }
            })
    }

    render() {
        return (
            <div style={{height: '100vh'}}>
                <Grid textAlign='center' style={{height: '100%', margin: 0}} verticalAlign='middle'>
                    <Grid.Column style={{ maxWidth: 450 }}>
                        <Image src={require('../resources/images/logo.png')} style={{marginBottom: 20}} />
                        <Message negative hidden={!!(!this.state.errorMessage.length)}>
                            <Message.Header>Error</Message.Header>
                            <p>{this.state.errorMessage}</p>
                        </Message>
                        <Form size='large' onSubmit={this.login}>
                            <Segment>
                                <Form.Input value={this.state.username} name="username" onChange={(event, {name, value}) => this.setState({ [name] : value})} fluid icon='user' iconPosition='left' placeholder='E-mail address'/>
                                <Form.Input name="password" onChange={(event, {name, value}) => this.setState({ [name] : value})} fluid icon='lock' iconPosition='left' placeholder='Password' type='password'/>
                                <Button loading={this.props.auth.isLoading} color="red" fluid size='large'>Login</Button>
                            </Segment>
                        </Form>

                    </Grid.Column>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        authenticate: (email, password) => dispatch(feathersAuthentication.authenticate({ strategy: 'local', email, password, app: 'web'}))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);

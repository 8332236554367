import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Grid,
	Header,
	Divider,
	Button,
	Segment,
	Form,
	Label,
	Icon,
	Dropdown,
	Message,
} from 'semantic-ui-react';
import feathersRestClient from '@feathersjs/rest-client';
import feathers from '@feathersjs/feathers';
import axios from 'axios';
import { ChromePicker } from 'react-color';
// import AlertModal from '../../components/AlertModal'
import { ROWS_PER_PAGE } from './CitiesData';
import Config from '../../config';
import AlertModal from '../../components/AlertModal';

class DistributorCreate extends Component {
	state = {
		loading: false,
		currentEmail: '',
		distributor: {
			name: '',
			emails: [],
			phone: '',
			sellerName: '',
			latitude: 0,
			longitud: 0,
			states: [],
			banks: [],
			buttonBackgroundColor: '#e2001a',
			buttonTextColor: '#ffffff',
		},
		states: [],
		banks: [],
		alertModalVisible: false,
		errors: [],
	};

	componentDidMount() {
		this.getStates();
		this.getBanks();
	}

	resetState = () => {
		this.setState({
			loading: false,
			currentEmail: '',
			distributor: {
				name: '',
				emails: [],
				phone: '',
				sellerName: '',
				latitude: 0,
				longitud: 0,
				state: '',
				buttonBackgroundColor: '#e2001a',
				buttonTextColor: '#ffffff',
			},
			alertModalVisible: false,
			errors: [],
		});
	};

	createDistributor = async () => {
		// eslint-disable-next-line prefer-const
		let errorsToReturn = [];
		if (this.state.distributor.name === '') {
			errorsToReturn.push('El nombre esta vacio');
		}
		if (this.state.distributor.sellerName === '') {
			errorsToReturn.push('El nombre del vendedor/técnico esta vacio');
		}
		if (this.state.distributor.phone === '') {
			errorsToReturn.push('El telefono esta vacio');
		}
		if (this.state.distributor.states.length === 0) {
			errorsToReturn.push('El estado esta vacio');
		}
		if (this.state.distributor.emails.length === 0) {
			errorsToReturn.push('Escribe al menos un email');
		}

		await this.setState({
			loading: errorsToReturn.length === 0,
			errors: errorsToReturn,
		});

		if (errorsToReturn.length > 0) {
			return;
		}

		// eslint-disable-next-line prefer-const
		let newDataToSend = new FormData();
		if (
			document.getElementById('logoFile') &&
			document.getElementById('logoFile').files.length > 0 &&
			document.getElementById('logoFile').files[0]
		) {
			newDataToSend.set(
				'logoFile',
				document.getElementById('logoFile').files[0],
			);
		}
		newDataToSend.set('name', this.state.distributor.name);
		this.state.distributor.emails.forEach((element, index) => {
			newDataToSend.append(`emails[${index}]`, element);
		});
		newDataToSend.set('phone', this.state.distributor.phone);
		newDataToSend.set('sellerName', this.state.distributor.sellerName);
		newDataToSend.set('latitude', this.state.distributor.latitude);
		newDataToSend.set('longitud', this.state.distributor.longitud);
		newDataToSend.set('longitud', this.state.distributor.longitud);
		this.state.distributor.states.forEach((state, index) => {
			newDataToSend.set(`states[${index}]`, state);
		});
		this.state.distributor.banks.forEach((bank, index) => {
			newDataToSend.set(`banks[${index}]`, bank);
		});
		newDataToSend.set(
			'buttonBackgroundColor',
			this.state.distributor.buttonBackgroundColor,
		);
		newDataToSend.set(
			'buttonTextColor',
			this.state.distributor.buttonTextColor,
		);
		const token = localStorage.getItem('fronius_jwt');
		const app = feathers();
		// Connect to the same as the browser URL (only in the browser)
		const restClient = feathersRestClient(Config.apiUrl);

		app.configure(restClient.axios(axios));

		app.service('distributors')
			.create(newDataToSend, {
				headers: {
					Authorization: token,
					'Content-Type': 'multipart/form-data',
				},
			})
			.then(() => {
				if (document.getElementById('logoFile')) {
					document.getElementById('logoFile').value = null;
				}

				this.setState({
					alertModalVisible: true,
				});
			})
			.catch((err) => {
				alert('Se produjo un error.');

				this.setState({
					loading: false,
				});
			});

		// await this.props.create({
		//     ...this.state.distributor,
		//     active: true,
		// })
		// this.props.find(this.props.distributors.store ? this.props.distributors.store.page : 1)
	};

	addEmail = () => {
		if (!this.state.currentEmail || this.state.currentEmail === '') return;
		const emails = [...this.state.distributor.emails];

		if (emails.indexOf(this.state.currentEmail) === -1) {
			emails.push(this.state.currentEmail);
		}

		this.setState({
			currentEmail: '',
			distributor: {
				...this.state.distributor,
				emails,
			},
		});
	};

	deleteEmail = (email) => {
		const emails = [...this.state.distributor.emails];

		const pos = emails.indexOf(email);

		emails.splice(pos, 1);

		this.setState({
			distributor: {
				...this.state.distributor,
				emails,
			},
		});
	};

	renderHeader = () => (
		<Grid>
			<Grid.Row columns={1}>
				<Grid.Column>
					<Header
						as="h2"
						icon="sitemap"
						content="Distribuidores > Nuevo"
					/>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	);

	// eslint-disable-next-line no-unused-vars
	renderEmails = () =>
		this.state.distributor.emails.map((v, i) => (
			<Label key={v}>
				{v}
				<Icon
					name="delete"
					// eslint-disable-next-line no-shadow, no-unused-vars
					onClick={(_) => this.deleteEmail(v)}
				/>
			</Label>
		));

	getStates = async () => {
		// eslint-disable-next-line prefer-const
		let statesToReturn = [];
		const returnedData = await this.props.findStates();

		statesToReturn = returnedData.value.map((g) => ({
			text: g.name,
			key: g._id,
			value: g._id,
		}));
		// eslint-disable-next-line react/no-unused-state
		this.setState({ states: statesToReturn });
	};

	getBanks = async () => {
		// eslint-disable-next-line prefer-const
		let banks = [];
		const returnedData = await this.props.findBanks();

		banks = returnedData.value.map((g) => ({
			text: g.name,
			key: g._id,
			value: g._id,
		}));
		// eslint-disable-next-line react/no-unused-state
		this.setState({ banks });
	};

	renderInputImage = () => {
		return (
			<Form.Group widths="equal">
				<Form.Field required>
					<input
						type="file"
						id="logoFile"
						onChange={(event) => {
							if (
								event.target.files &&
								event.target.files.length > 0
							) {
								this.setState({
									// eslint-disable-next-line react/no-unused-state
									logo: event.target.files[0],
								});
							}
						}}
					/>
				</Form.Field>
			</Form.Group>
		);
	};

	toggleBackgroundColorPicker = () => {
		this.setState({
			displayBackgroundColorPicker: !this.state
				.displayBackgroundColorPicker,
		});
	};

	toggleTextColorPicker = () => {
		this.setState({
			displayTextColorPicker: !this.state.displayTextColorPicker,
		});
	};

	handleTextColorPickerChange = (color) => {
		this.setState({
			distributor: {
				...this.state.distributor,
				buttonTextColor: color.hex,
			},
		});
	};

	handleBackgroundColorPickerChange = (color) => {
		this.setState({
			distributor: {
				...this.state.distributor,
				buttonBackgroundColor: color.hex,
			},
		});
	};

	render() {
		const popover = {
			position: 'absolute',
			zIndex: '2',
		};
		const cover = {
			position: 'fixed',
			top: '0px',
			right: '0px',
			bottom: '0px',
			left: '0px',
		};

		return (
			<div>
				<this.renderHeader />

				{this.state.errors.length > 0 && (
					<Message negative>
						<Message.Header>
							Hay varios errores de validación
						</Message.Header>
						{this.state.errors.map((error) => (
							<Message.Item>{error}</Message.Item>
						))}
					</Message>
				)}
				<Divider />
				<Segment>
					<Form>
						<Form.Group widths="equal">
							<Form.Field required>
								<Form.Input
									label="Nombre"
									value={this.state.distributor.name}
									onChange={(e, { value }) => {
										this.setState({
											distributor: {
												...this.state.distributor,
												name: value,
											},
										});
									}}
								/>
							</Form.Field>

							{/* <Form.Field required>
								<Form.Input
									label="Latitud"
									value={this.state.distributor.latitude}
									onChange={(e, { value }) => {
										this.setState({
											distributor: {
												...this.state.distributor,
												latitude: value,
											},
										});
									}}
								/>
							</Form.Field>

							<Form.Field required>
								<Form.Input
									label="Longitud"
									value={this.state.distributor.longitude}
									onChange={(e, { value }) => {
										this.setState({
											distributor: {
												...this.state.distributor,
												longitude: value,
											},
										});
									}}
								/>
							</Form.Field> */}
						</Form.Group>

						<Form.Group widths="equal">
							<Form.Field required>
								<Form.Input
									label="Nombre Vendedor/Técnico"
									value={this.state.distributor.sellerName}
									onChange={(e, { value }) => {
										this.setState({
											distributor: {
												...this.state.distributor,
												sellerName: value,
											},
										});
									}}
								/>
							</Form.Field>

							<Form.Field>
								<Form.Input
									label="Telefono"
									placeholder="Telefono"
									onChange={(e, { value }) => {
										this.setState({
											// currentPhone: value,
											distributor: {
												...this.state.distributor,
												phone: value,
											},
										});
									}}
									value={this.state.distributor.phone}
								/>
							</Form.Field>

							<Form.Field
								control={Dropdown}
								label="Estado"
								placeholder="Estado"
								search
								selection
								options={this.state.states}
								multiple
								onChange={(e, { value }) => {
									this.setState({
										distributor: {
											...this.state.distributor,
											states: value,
										},
									});
								}}
								value={this.state.distributor.states}
							/>
						</Form.Group>

						<Form.Group widths="equal">
							<Form.Field required>
								<Form.Input
									label="Color de fondo de botón de App"
									value={
										this.state.distributor
											.buttonBackgroundColor
									}
									readOnly
									onChange={(e, { value }) => {
										this.setState({
											distributor: {
												...this.state.distributor,
												buttonBackgroundColor: value,
											},
										});
									}}
									action={{
										color: 'red',
										// labelPosition: 'left',
										content: 'Elegir',
										onClick: this
											.toggleBackgroundColorPicker,
									}}
									actionPosition="left"
								/>
								{this.state.displayBackgroundColorPicker ? (
									<div style={popover}>
										<div
											style={cover}
											onClick={
												this.toggleBackgroundColorPicker
											}
										/>
										<ChromePicker
											color={
												this.state.distributor
													.buttonBackgroundColor
											}
											onChange={
												this
													.handleBackgroundColorPickerChange
											}
										/>
									</div>
								) : null}
							</Form.Field>

							<Form.Field required>
								<Form.Input
									label="Color de texto de botón de App"
									value={
										this.state.distributor.buttonTextColor
									}
									readOnly
									onChange={(e, { value }) => {
										this.setState({
											distributor: {
												...this.state.distributor,
												buttonTextColor: value,
											},
										});
									}}
									action={{
										color: 'red',
										// labelPosition: 'left',
										content: 'Elegir',
										onClick: this.toggleTextColorPicker,
									}}
									actionPosition="left"
								/>
								{this.state.displayTextColorPicker ? (
									<div style={popover}>
										<div
											style={cover}
											onClick={this.toggleTextColorPicker}
										/>
										<ChromePicker
											color={
												this.state.distributor
													.buttonTextColor
											}
											onChange={
												this.handleTextColorPickerChange
											}
										/>
									</div>
								) : null}
							</Form.Field>

							<Form.Field>
								<label>Vista previa</label>
								<Button
									style={{
										backgroundColor: this.state.distributor
											.buttonBackgroundColor,
										color: this.state.distributor
											.buttonTextColor,
									}}
								>
									Contacto
								</Button>
							</Form.Field>
						</Form.Group>

						<Form.Group>
							<Form.Field
								style={{
									display: 'flex',
								}}
							>
								<Form.Input
									label="Emails"
									placeholder="xxxx@cccc.com"
									onChange={(e, { value }) => {
										this.setState({
											currentEmail: value,
										});
									}}
									value={this.state.currentEmail}
								/>
								<Form.Button
									style={{
										marginLeft: 10,
									}}
									onClick={this.addEmail}
									label="Agregar"
									circular
									icon="add"
									color="blue"
								/>
							</Form.Field>
						</Form.Group>

						<Form.Group widths="equal">
							<Form.Field
								control={Dropdown}
								label="Bancos"
								placeholder="Bancos"
								search
								selection
								options={this.state.banks}
								multiple
								onChange={(e, { value }) => {
									this.setState({
										distributor: {
											...this.state.distributor,
											banks: value,
										},
									});
								}}
								value={this.state.distributor.banks}
							/>
						</Form.Group>

						<Form.Group>
							<Form.Field>
								<div
									style={{
										display: 'flex',
										flex: 1,
										flexWrap: 'wrap',
									}}
								>
									<this.renderEmails />
								</div>
							</Form.Field>
						</Form.Group>
						<Form.Group>
							<Form.Field
								style={{
									display: 'flex',
									flex: 1,
									flexWrap: 'wrap',
								}}
							>
								<div
									style={{
										width: '100%',
									}}
								>
									{this.renderInputImage()}
								</div>
							</Form.Field>
						</Form.Group>
						<Divider />
						<Grid>
							<Grid.Row>
								<Grid.Column
									floated="right"
									computer={16}
									largeScreen={3}
									widescreen={3}
									tablet={16}
									mobile={16}
								>
									<Button
										loading={this.state.loading}
										disabled={this.state.loading}
										onClick={this.createDistributor}
										positive
										fluid
										icon="plus"
										content="CREAR"
										labelPosition="left"
									/>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Form>
				</Segment>

				<AlertModal
					open={this.state.alertModalVisible}
					title="Éxito"
					message="El distribuidor fue registrado satisfactoriamente"
					onClose={this.resetState}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	distributors: state.services.distributors,
});

const mapDispatchToProps = (dispatch, { services }) => ({
	create: (data) => dispatch(services.distributors.create(data)),
	createState: (data) => dispatch(services.states.create(data)),
	find: (page) =>
		dispatch(
			services.distributors.find({
				query: {
					$skip: (page - 1) * ROWS_PER_PAGE,
					$limit: ROWS_PER_PAGE,
					$sort: {
						createdAt: -1,
					},
				},
			}),
		),
	findStates: () =>
		dispatch(
			services.states.find({
				query: {
					$limit: '-1',
				},
			}),
		),
	findBanks: () =>
		dispatch(
			services.banks.find({
				query: {
					$limit: '-1',
				},
			}),
		),
});

export default connect(mapStateToProps, mapDispatchToProps)(DistributorCreate);

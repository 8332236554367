import React, { Component } from 'react'
import { Button, Header, Pagination, Segment, Grid, Divider, Item, Label } from 'semantic-ui-react'
import { connect } from 'react-redux'
import moment from 'moment'
import 'moment/locale/es';
import NewsImage from '../../components/NewsImage'
import { push } from 'react-router-redux'
import Toggle from 'react-toggle'
import 'react-toggle/style.css'
import { ROWS_PER_PAGE } from './NewsData';

moment.locale('es')

class NewsContainer extends Component {
    componentDidMount() {
        if (!this.props.news.store) {
            this.getNews(1)
        }
    }

    getNews = (page) => {
        this.props.store({ page })

        setTimeout(() => {
            this.props.onFind({
                query: {
                    $skip: (this.props.news.store.page - 1) * ROWS_PER_PAGE,
                    $limit: ROWS_PER_PAGE,
                    $sort: {
                        createdAt: -1,
                    },
                },
            })
        }, 50)
    }

    renderPagination = () => {
        if (!this.props.news.store || this.props.news.queryResult.total === 0) return null

        return (
            <Pagination 
                activePage={this.props.news.store.page} 
                totalPages={Math.ceil(this.props.news.queryResult.total / ROWS_PER_PAGE)} 
                onPageChange={(e, { activePage }) => this.getNews(activePage)}
            />
        )
    }

    renderItems = () => {
        const items = this.props.news.queryResult.data.map(r => (
                <Item key={r._id}>
                    <NewsImage item={r} />
                    <Item.Content>
                        <Item.Header as="a" onClick={() => this.props.push(`/news/${r._id}`)}>
                            {r.title}
                            <Button icon="edit" size="small" compact style={{ marginLeft: '5px' }} />
                        </Item.Header>
                        <Item.Meta>
                            {r.type === 'video' && (
                                <Label color="red" size="tiny" content={r.type.toUpperCase()} />
                            )}
                            <Label color="blue" content={`Visible a partir del ${moment(r.date).format('DD-MMMM-YYYY')}`} />
                            <Label content={`Creada el ${moment(r.createdAt).format('DD-MMMM-YYYY [a las] HH:mm:ss')}`} />
                        </Item.Meta>
                        <Item.Description>
                            <p>{r.content}</p>
                        </Item.Description>
                        <Item.Extra>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ marginRight: 10 }}>Activa</span>
                                <Toggle defaultChecked={r.visible} icons={{ unchecked: null }} onChange={e => this.props.onPatch(r._id, { visible: e.target.checked })} />
                            </div>
                        </Item.Extra>
                    </Item.Content>
                </Item>
            ))

        return (
            <Item.Group divided>
                {items}
            </Item.Group>
        )
    }

    render() {
        return (
            <div>
                <Grid>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <Header as="h2" icon="newspaper" content="Noticias" />      
                        </Grid.Column>
                        <Grid.Column>
                            <Button icon="plus" color="red" floated="right" content="Nueva" onClick={() => this.props.push('/news/create')} />
                            <Button icon="refresh" color="grey" floated="right" onClick={() => this.getNews(this.props.news.store.page)} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                <Divider />

                <Grid>
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <Segment raised loading={this.props.news.isLoading}>
                                <this.renderItems />
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                        <Grid.Column textAlign="center">
                            <this.renderPagination />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    news: state.services.news,
    router: state.router,
});

const mapDispatchToProps = (dispatch, { services }) => ({
    onFind: (query = {}) => dispatch(services.news.find(query)),
    onPatch: (id, data) => dispatch(services.news.patch(id, data)),
    store: data => dispatch(services.news.store(data)),
    push: location => dispatch(push(location)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewsContainer)

import React from 'react'
import { Modal, Button } from 'semantic-ui-react'

class ConfirmModal extends React.PureComponent {
    render() {
        return (
            <Modal open={this.props.open} size="mini" onClose={this.props.onClose}>
                <Modal.Header>{this.props.title}</Modal.Header>
                <Modal.Content>
                    <p>{this.props.message}</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.props.onRejected} negative inverted>No</Button>
                    <Button onClick={this.props.onAccepted} positive inverted icon="checkmark" labelPosition="right" content="Si" />
                </Modal.Actions>
            </Modal>
        )
    }
}

export default ConfirmModal

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Grid,
	Header,
	Divider,
	Button,
	Segment,
	Form,
	Dropdown,
	TextArea,
	Image,
} from 'semantic-ui-react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import moment from 'moment';
import 'moment/locale/es';
import MomentLocaleUtils, {
	formatDate,
	parseDate,
} from 'react-day-picker/moment';
import AlertModal from '../../components/AlertModal';
import Dropzone from 'react-dropzone';
import DropzoneStyles from '../../components/Dropzone/DropzoneStyles';
import { VISIBILITIES, ROWS_PER_PAGE } from './EventData';
import feathersClient from '../../redux/FeathersRedux';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';

const URL_REGEX = /^(http(s)?:\/\/(www\.)?)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

const format = 'h:mm a';
const now = moment().hour(0).minute(0);

class EventCreate extends Component {
	state = {
		type: 'Fronius Friday',
		location: null,
		title: '',
		description: '',
		image: null,
		modalVisible: false,
		error: false,
		capacity: 100,
		location_id: null,
		citiesOptions: [],
		visibility: 'public',
		time: '',
		url: '',
		invalidUrl: false,
	};

	componentDidMount() {
		this.getCities();
	}

	getCities = async () => {
		let cities = await feathersClient.service('cities').find({
			query: {
				$limit: '-1',
			},
		});

		const citiesOptions = cities.map((v) => {
			return {
				value: v.code,
				text: v.name,
				_id: v._id,
				key: v._id,
			};
		});

		this.setState({
			citiesOptions,
		});
	};

	onImageDrop = (files, rejectedFiles) => {
		if (files.length > 0) {
			let file = files[0];
			let reader = new FileReader();
			reader.readAsDataURL(file);

			reader.onload = () => {
				// Make a fileInfo Object
				let image = {
					name: file.name,
					type: file.type,
					size: file.size,
					base64: reader.result,
					preview: file.preview,
				};

				this.setState({ image });
			};
		}
	};

	removeImage = (e, data) => {
		e.stopPropagation();
		this.setState({
			image: null,
		});
	};

	renderDropContent = () => {
		if (!this.state.image)
			return <p>Arrastra o haz clic para subir una imágen.</p>;

		return (
			<div style={{ position: 'relative' }}>
				<Image
					src={this.state.image.preview}
					style={{ height: 130, width: 'auto' }}
				/>
				<Button
					negative
					circular
					icon="close"
					onClick={this.removeImage}
					style={{ position: 'absolute', top: 0, right: 0 }}
				/>
			</div>
		);
	};

	save = () => {
		if (!this.state.date) {
			this.setState({
				error: true,
			});
			return;
		}

		if (this.props.events.isSaving) return;

		let data = { ...this.state };

		data.date = moment(data.date).hour(0).minute(0).second(0).toDate();
		data.endDate = moment(data.endDate)
			.hour(0)
			.minute(0)
			.second(0)
			.toDate();
		data.time = data.time.clone().utc().format('HH:mm');
		data.isUTCTime = true;

		if (data.image) {
			data.image = data.image.base64;
		}

		this.props.create(data).then((res) => {
			this.setState({ modalVisible: true });
			this.props.find(
				this.props.events.store ? this.props.events.store.page : 1,
			);
		});
	};

	render() {
		return (
			<div>
				<Grid>
					<Grid.Row columns={1}>
						<Grid.Column>
							<Header
								as="h2"
								icon="calendar"
								content="Eventos > Nuevo"
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<Divider />

				<Segment>
					<Form onSubmit={this.save}>
						<Form.Group widths="equal">
							<Form.Field
								required
								label="Visibilidad del evento"
								value={this.state.visibility}
								control={Dropdown}
								selection
								options={VISIBILITIES}
								onChange={(e, { value }) =>
									this.setState({ visibility: value })
								}
							/>
							<Form.Field
								required
								label="Ubicación"
								value={this.state.location}
								control={Dropdown}
								placeholder="Ubicación"
								selection
								options={this.state.citiesOptions}
								onChange={(e, data) => {
									const selectedElement = this.state.citiesOptions.find(
										(element) => {
											if (element.value === data.value)
												return element;
										},
									);
									this.setState({
										location: data.value,
										location_id: selectedElement._id,
									});
								}}
							/>
							<Form.Input
								label="Capacidad (Si se deja vacio, la capacidad sera ilimitada)"
								value={this.state.capacity}
								type="number"
								placeholder="Capacidad"
								onChange={(e, { value }) => {
									this.setState({
										capacity: value,
									});
								}}
							/>
						</Form.Group>
						<Form.Input
							required
							value={this.state.title}
							label="Título"
							type="text"
							placeholder="Título del evento"
							onChange={(e, { value }) =>
								this.setState({ title: value })
							}
						/>
						<Form.Input
							required
							value={this.state.description}
							label={`Descripción (${this.state.description.length}/500)`}
							control={TextArea}
							placeholder="Descripción de la noticia"
							rows={3}
							maxlength="500"
							onChange={(e, { value }) =>
								this.setState({ description: value })
							}
						/>

						<Form.Group widths="equal">
							<Form.Field required error={this.state.error}>
								<label>Hora de incio</label>
								<TimePicker
									showSecond={false}
									defaultValue={now}
									className="xxx"
									onChange={(value) => {
										this.setState({
											time: value,
										});
									}}
									format={format}
									use12Hours
									inputReadOnly
									value={this.state.time}
								/>
							</Form.Field>
							<Form.Field required error={this.state.error}>
								<label>Fecha de incio</label>
								<DayPickerInput
									format="DD-MM-YYYY"
									value={this.state.date}
									formatDate={formatDate}
									parseDate={parseDate}
									placeholder="Fecha del evento"
									onDayChange={(date) => {
										this.setState({ date, error: false });
									}}
									dayPickerProps={{
										locale: 'es',
										localeUtils: MomentLocaleUtils,
										disabledDays: { before: new Date() },
									}}
								/>
							</Form.Field>
							<Form.Field
								required
								error={this.state.error}
								disabled={!this.state.date}
							>
								<label>Fecha de fin</label>
								<DayPickerInput
									format="DD-MM-YYYY"
									value={this.state.endDate}
									formatDate={formatDate}
									parseDate={parseDate}
									placeholder="Fecha de fin del evento"
									onDayChange={(endDate) =>
										this.setState({ endDate, error: false })
									}
									dayPickerProps={{
										locale: 'es',
										localeUtils: MomentLocaleUtils,
										disabledDays: {
											before: this.state.date,
										},
									}}
								/>
							</Form.Field>
						</Form.Group>
						<Form.Input
							value={this.state.url}
							label="Enlace Url"
							type="text"
							placeholder="Puede ser el link hacia un webinar"
							onChange={(e, { value }) =>
								this.setState({
									url: value,
									invalidUrl: value.length ? !URL_REGEX.test(value) : false,
								})
							}
							error={this.state.invalidUrl}
						/>
						<Form.Field>
							<label>Imágen</label>
							<Dropzone
								multiple={false}
								accept="image/*"
								onDrop={this.onImageDrop}
								style={DropzoneStyles.dropZoneStyle}
								acceptStyle={DropzoneStyles.dropZoneAcceptStyle}
								rejectStyle={DropzoneStyles.dropZoneRejectStyle}
							>
								{this.renderDropContent()}
							</Dropzone>
						</Form.Field>
						<Divider />
						<Grid>
							<Grid.Row>
								<Grid.Column
									floated="right"
									computer={16}
									largeScreen={3}
									widescreen={3}
									tablet={16}
									mobile={16}
								>
									<Button
										type="submit"
										positive
										fluid
										icon="plus"
										content="CREAR"
										labelPosition="left"
										disabled={this.state.invalidUrl}
									/>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Form>
				</Segment>

				<AlertModal
					open={this.state.modalVisible}
					title="Éxito"
					message="El evento fue creado satisfactoriamente"
					onClose={() =>
						this.setState({
							modalVisible: false,
							title: '',
							description: '',
							type: null,
							location: null,
							date: '',
							image: null,
						})
					}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	events: state.services.events,
});

const mapDispatchToProps = (dispatch, { services }) => ({
	create: (data) => dispatch(services.events.create(data)),
	find: (page) =>
		dispatch(
			services.events.find({
				query: {
					$skip: (page - 1) * ROWS_PER_PAGE,
					$limit: ROWS_PER_PAGE,
					$sort: {
						createdAt: -1,
					},
					active: true,
					date: {
						$gte: new Date(),
					},
				},
			}),
		),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventCreate);

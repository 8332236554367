import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { Button, Header, Modal, Pagination, Segment, Grid, Divider, Table } from 'semantic-ui-react'
import moment from 'moment'
import { ROWS_PER_PAGE } from './QuizesData'
import QuizesDetailsModal from './QuizesDetailsModal';
import QuizResultsModal from './QuizResultsModal';
import feathersClient from '../../redux/FeathersRedux'

class QuizesContainer extends Component {
    state = {
        currentQuiz: null,
        isResultsModalOpened: false,
        isDetailsModalOpened: false,
        quizResults: null,
        quizTotalUsers: null,
    }

    componentDidMount() {
        if (!this.props.quizes.store) {
            this.getQuizes(1)
        }
    }

    getQuizes = (page) => {
        this.props.store({ page })

        setTimeout(() => {
            this.props.find({
                query: {
                    $skip: (this.props.quizes.store.page - 1) * ROWS_PER_PAGE,
                    $limit: ROWS_PER_PAGE,
                    $sort: {
                        createdAt: -1,
                    },
                },
            })
        }, 50)
    }

    renderTableBody = () => {

        if(this.props.quizes.queryResult === null) return <span></span> 
        return this.props.quizes.queryResult.data.map(quiz => 
            (<Table.Row key={quiz._id}>
                <Table.Cell>{quiz.name}</Table.Cell>
                <Table.Cell>{quiz.description.substring(0, 50) + "..."}</Table.Cell>
                <Table.Cell>{quiz.questions.length}</Table.Cell>
                <Table.Cell>{`Del ${moment(quiz.startDate).format('DD MMM YYYY')} al ${moment(quiz.finishDate).format('DD MMM YYYY')}`}</Table.Cell>
                <Table.Cell>{moment(quiz.createdAt).format('DD-MMMM-YYYY')}</Table.Cell>
                <Table.Cell>
                        <Button 


                            content="Ver detalles" 
                            size="mini"
                            onClick={async () => {

                                // tenemos que traer a los participantes, todos ellos, para que se muestren en una tabla

                                this.setState({
                                    currentQuiz: quiz,
                                    isDetailsModalOpened: true
                                })
                            }}


                        />
                        <Button 
                            content="Ver resultados" 
                            size="mini"
                            onClick={ async _ => {
                                const quizResults = await feathersClient.service('quizusers').find({
                                    query: {
                                        quiz: quiz._id,
                                        $sort: {
                                            grade: -1,
                                            duration: 1,
                                            createdAt: 1,
                                        },
                                        $populate: 'user',
                                        $limit: 10,
                                    },
                                    paginate: false,
                                })

                                const quizusersTotal = await feathersClient.service('quizusers').find({
                                    query: {
                                        quiz: quiz._id,
                                    },
                                })


                                this.setState({
                                    quizResults,
                                    quizTotalUsers: quizusersTotal.total
                                })

                              

                                this.setState({
                                    currentQuiz: quiz,
                                    isResultsModalOpened: true
                                })
                            }}
                        />
                </Table.Cell>
             </Table.Row>))
    } 

    renderPagination = () => {

        if(this.props.quizes === null) return <span></span>
        if(this.props.quizes.queryResult === null) return <span></span>


        if (!this.props.quizes.store || this.props.quizes.queryResult.total === 0) return null

        return (
            <Pagination 
                activePage={this.props.quizes.store.page} 
                totalPages={Math.ceil(this.props.quizes.queryResult.total / ROWS_PER_PAGE)} 
                onPageChange={(e, { activePage }) => this.getQuizes(activePage)}
            />
        )
    }

    render() {
        return (
            <div>
                <Grid>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <Header as="h2" icon="file alternate outline" content="Quizes" />      
                        </Grid.Column>
                        <Grid.Column>
                            <Button color="red" icon="plus" floated="right" content="Nuevo" onClick={() => this.props.push('/quiz/create')} />
                            <Button icon="refresh" color="grey" floated="right" onClick={() => this.getQuizes(this.props.quizes.store.page)} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                
                <Divider />

                <Grid>
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <Segment raised loading={this.props.quizes.isLoading}>
                                <Table striped>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Nombre</Table.HeaderCell>
                                            <Table.HeaderCell>Descripción</Table.HeaderCell>
                                            <Table.HeaderCell>Num. preguntas</Table.HeaderCell>
                                            <Table.HeaderCell>Visible</Table.HeaderCell>
                                            <Table.HeaderCell>Fecha de creación</Table.HeaderCell>
                                            <Table.HeaderCell></Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {this.renderTableBody()}
                                    </Table.Body>
                                </Table>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                        <Grid.Column textAlign="center">
                            {this.renderPagination()}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                <QuizesDetailsModal currentQuiz={this.state.currentQuiz} open={this.state.isDetailsModalOpened} onClose={() => this.setState({ isDetailsModalOpened: false, currentQuiz: null })} />
                <QuizResultsModal currentQuiz={this.state.currentQuiz}  totalofusers={this.state.quizTotalUsers} quizResults={this.state.quizResults} open={this.state.isResultsModalOpened} onClose={() => this.setState({ isResultsModalOpened: false, quizResults: null })} />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    quizes: state.services.quizes,
    router: state.router,
});

const mapDispatchToProps = (dispatch, { services }) => ({
    find: (query = {}) => dispatch(services.quizes.find(query)),
    patch: (id, data) => dispatch(services.quizes.patch(id, data)),
    store: data => dispatch(services.quizes.store(data)),
    push: location => dispatch(push(location)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuizesContainer)

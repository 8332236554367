import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { services } from '../redux/FeathersRedux'
// import NewsContainer from '../containers/NewsContainer'
import HomeContainer from '../containers/HomeContainer'
import { NewsCreate, NewsContainer, NewsEdit } from '../containers/News'
import { EventContainer, EventCreate, EventEdit } from '../containers/Event'
import { DemoContainer } from '../containers/Demo'
import { QuotationContainer } from '../containers/Quotation'
import { ProductContainer, ProductCreate, ProductEdit } from '../containers/Product'
import { CustomerContainer } from '../containers/Customer'
import { NotificationContainer } from '../containers/Notification'
import { IndicatorsContainer } from '../containers/Indicators'
import { QuizesContainer, QuizesCreate } from '../containers/Quizes'
import { CitiesContainer, CitiesCreate, CitiesEdit } from '../containers/Cities'

import { DistributorContainer, DistributorEdit, DistributorCreate } from '../containers/Distributor'
import { StatesContainer, StatesEdit, StatesCreate} from '../containers/States'
import { BanksContainer, BanksCreate, BanksEdit } from '../containers/Banks'

export const links =
    [
        {
            name: 'home',
            displayName: 'Inicio',
            path: '/',
            icon: '',
            exact: true,
            component: HomeContainer,
        },
        {
            name: 'news',
            displayName: 'Noticias',
            icon: 'newspaper',
            path: '/news',
            component: NewsContainer,
        },
        {
            name: 'events',
            displayName: 'Eventos',
            icon: 'calendar',
            path: '/event',
            component: EventContainer,
        },
        {
            name: 'demos',
            displayName: 'Demos',
            icon: 'calendar',
            path: '/demo',
            component: DemoContainer,
        },
        {
            name: 'products',
            displayName: 'Productos usados',
            icon: 'cubes',
            path: '/product',
            component: ProductContainer,
        },
        {
            name: 'quotations',
            displayName: 'Cotizaciones',
            icon: 'list',
            path: '/quotation',
            component: QuotationContainer,
        },
        {
            name: 'customers',
            displayName: 'Clientes',
            icon: 'users',
            path: '/customer',
            component: CustomerContainer,
        },
        {
            name: 'notification',
            displayName: 'Notificaciones',
            icon: 'bell',
            path: '/notification',
            component: NotificationContainer,
        },
        {
            name: 'quizes',
            displayName: 'Quizes',
            icon: 'file alternate outline',
            path: '/quiz',
            component: QuizesContainer,
        },
        {
            name: 'cities',
            displayName: 'Ciudades',
            icon: 'building',
            path: '/city',
            component: CitiesContainer,
        },
        {
            name: 'states',
            displayName: 'Estados',
            icon: 'plus square outline',
            path: '/states',
            component: StatesContainer,
        },
        {
            name: 'distributor',
            displayName: 'Distribuidores',
            icon: 'sitemap',
            path: '/distributor',
            component: DistributorContainer,
        },
        {
            name: 'banks',
            displayName: 'Bancos',
            icon: 'money',
            path: '/banks',
            component: BanksContainer,
        },
    ]

export const routes = (
    <div>
        <Switch>
            <Route path="/" exact render={props => <HomeContainer {...props} services={services} />} />
            <Route path="/news" exact render={props => <NewsContainer {...props} services={services} />} />
            <Route path="/news/create" render={props => <NewsCreate {...props} services={services} />} />
            <Route path="/news/:id" render={props => <NewsEdit {...props} services={services} />} />
            <Route path="/event" exact render={props => <EventContainer {...props} services={services} />} />
            <Route path="/demo" exact render={props => <DemoContainer {...props} services={services} />} />

            <Route path="/event/create" render={props => <EventCreate {...props} services={services} />} />
            <Route path="/event/:id" render={props => <EventEdit {...props} services={services} />} />
            <Route path="/quotation" exact render={props => <QuotationContainer {...props} services={services} />} />
            <Route path="/product" exact render={props => <ProductContainer {...props} services={services} />} />
            <Route path="/product/create" render={props => <ProductCreate {...props} services={services} />} />
            <Route path="/product/:id" render={props => <ProductEdit {...props} services={services} />} />
            <Route path="/customer" exact render={props => <CustomerContainer {...props} services={services} />} />
            <Route path="/notification" exact render={props => <NotificationContainer {...props} services={services} />} />
            <Route path="/quiz" exact render={props => <QuizesContainer {...props} services={services} />} />
            <Route path="/quiz/create" render={props => <QuizesCreate {...props} services={services} />} />

            <Route path="/city" exact render={props => <CitiesContainer {...props} services={services} />} />
            <Route path="/city/create" exact render={props => <CitiesCreate {...props} services={services} />} />
            <Route path="/city/:id" exact render={props => <CitiesEdit {...props} services={services} />} />

            <Route path="/distributor" exact render={props => <DistributorContainer {...props} services={services} />} />
            <Route path="/distributor/create" exact render={props => <DistributorCreate {...props} services={services} />} />
            <Route path="/distributor/:id" exact render={props => <DistributorEdit {...props} services={services} />} />

            <Route path="/states" exact render={props => <StatesContainer {...props} services={services} />} />
            <Route path="/states/create" exact render={props => <StatesCreate {...props} services={services} />} />
            <Route path="/states/:id" exact render={props => <StatesEdit {...props} services={services} />} />

            <Route path="/banks" exact render={props => <BanksContainer {...props} services={services} />} />
            <Route path="/banks/create" exact render={props => <BanksCreate {...props} services={services} />} />
            <Route path="/banks/:id" exact render={props => <BanksEdit {...props} services={services} />} />
        </Switch>
    </div>
)
